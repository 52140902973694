import { useEffect, useRef, useState } from "react";
import { STATE } from "../../constants";
import { ApiDelete, ApiGet, ApiPost, ApiPut } from "../../helper/API/ApiData";
import Select from "react-select";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { FilterMatchMode } from "primereact/api";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { TieredMenu } from "primereact/tieredmenu";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { ConfirmDialog } from "primereact/confirmdialog";
import { MultiSelect } from "primereact/multiselect";
import { Toast } from "primereact/toast";
import { toast } from "react-toastify";
import axios from "axios";
import { PERMISSION_TYPE, userPermission } from "../../helper/permission";
import moment from "moment";
import { TreeSelect } from "primereact/treeselect";
import { OverlayPanel } from "primereact/overlaypanel";
import 'jspdf-autotable';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';

function SmList() {


  const loginCodeListData = {
    id: "",
    bank_name: "",
    dsa_name: "",
    dsa_code: "",
    product: "",
    city: "",
    first_name: "",
    email: "",
    phone: "",
    margin: "",
    location: "",
    middle_name: "",
    last_name: "",
    codeListId: "",
    postal_code: "",
    // adjustment_ratio: "",
    // adjustment_ratio_name: "",
    postal_code_error: null,
    codeListId_error: null,
    bank_name_error: null,
    dsa_name_error: null,
    dsa_code_error: null,
    product_error: null,
    city_error: null,
    first_name_error: null,
    email_error: null,
    phone_error: null,
    margin_error: null,
    middle_name_error: null,
    last_name_error: null,
  };
  const [formData, setFormData] = useState<any>(loginCodeListData);
  const [codeData, setCodeData] = useState<any>([]);
  const [state, setState] = useState(STATE.DEFAULT_STATE);
  const [loader, setLoader] = useState(true);
  const [smList, setSmList] = useState([]);
  const [addOpen, setAddOpen] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  const [editData, setEditData] = useState<any>(loginCodeListData);
  const user = useSelector(
    (state: RootState) => state?.authUser?.userData?.user
  );
  const [permission, setPermission] = useState<any>({});
  const [bankNameOptions, setBankNameOptions] = useState(null);
  const [productOptions, setProductNameOptions] = useState(null);
  const [locationOptions, setLocationOptions] = useState(null);
  const [fullNameOptions, setFullNameOptions] = useState(null);
  const [emailOptions, setEmailOptions] = useState(null);
  const [searchValue, setSearchValue] = useState("");
  const [totalRecords, setTotalRecords] = useState(0);
  const [paginator, setPaginator] = useState(true);
  const [lazyState, setlazyState] = useState({
    first: 0,
    rows: 10,
    page: 0,
    pagination: paginator,
    search: "",
    filters: {
      bank_name: { value: null, matchMode: FilterMatchMode.IN },
      product_name: { value: null, matchMode: FilterMatchMode.IN },
      sm_name: { value: null, matchMode: FilterMatchMode.IN },
      sm_email: { value: null, matchMode: FilterMatchMode.IN },
      location: { value: null, matchMode: FilterMatchMode.IN },
    },
  });
  const op = useRef(null);
  const[exportType,setExportType]=useState('');
  const [selectedNodeKey, setSelectedNodeKey] = useState(null);
  let date = new Date().toDateString();
  const [newdate, setDate] = useState(moment(date).format("DD-MM-YY"));

  const onPage = (event) => {
    let filter = { ...lazyState };
    filter = event;
    filter["search"] = lazyState.search;
    setlazyState(filter);
  };

  const onFilter = (event) => {
    event["first"] = 0;
    event["page"] = 0;
    setlazyState(event);
  };

  const onGlobalFilterChange = (e) => {
    const value = e;
    let filter = { ...lazyState };

    filter["search"] = value;

    setlazyState(filter);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      onGlobalFilterChange(searchValue);
    }
  };

  const handleSearchClick = () => {
    onGlobalFilterChange(searchValue);
  };

  const handleInputChange = (event) => {
    setSearchValue(event.target.value);
  };

  const [deleteConfirmVisible, setDeleteConfirmVisible] =
    useState<boolean>(false);
  const toastRef = useRef<Toast>(null);

  const accept = () => {
    handleDelete(selectedRowData.current);
  };

  const reject = () => {
    setDeleteConfirmVisible(false);
  };

  const getSmList = () => {
    setLoader(true);
    let modifiedLazyState;
    if (paginator) {
      modifiedLazyState = {
        ...lazyState,
        limit: lazyState.rows,
        pagination: true,
      };
    } else {
      modifiedLazyState = {
        ...lazyState,
        limit: lazyState.rows,
        pagination: false,
      };
    }
    delete modifiedLazyState.rows;
    delete modifiedLazyState["multiSortMeta"];
    delete modifiedLazyState["sortField"];
    delete modifiedLazyState["sortOrder"];

    // Function to replace empty arrays in the "value" field with null
    const replaceEmptyValueArrays = (obj) => {
      for (const key in obj) {
        if (
          key === "value" &&
          Array.isArray(obj[key]) &&
          obj[key].length === 0
        ) {
          obj[key] = null;
        } else if (typeof obj[key] === "object") {
          replaceEmptyValueArrays(obj[key]);
        }
      }
      return obj;
    };

    // Call the function to replace empty arrays in the "value" field with null
    const finalModifiedLazyState = replaceEmptyValueArrays(modifiedLazyState);
    finalModifiedLazyState.page++;

    ApiPost(`sm/get-sm-list`, finalModifiedLazyState)
      .then((res: any) => {
        setTotalRecords(Number(res.data.count[0].count));
        let temp = res.data.result.map((item: any, index: number) => {
          return {
            SRNO: lazyState.first + index + 1,
            id: item._id,
            bank_name: item.bank_name,
            product_name: item.product,
            sm_email: item.email,
            Mobile: item.mobile,
            location: item.location,
            sm_name: item.fullname,
            FirstName: item.first_name,
            Location: item.location,
            LastName: item.last_name,
            MiddleName: item.middle_name,
            DsaName: item.dsa_name,
            codeListId: item.logincodelistid,
            postalCode: item.postal_code,
          };
        });
        setSmList(temp);
        const tempPermission = {
          [PERMISSION_TYPE.ADD_PAYOUT_GRID]: userPermission(
            user,
            PERMISSION_TYPE.ADD_PAYOUT_GRID
          ),
          [PERMISSION_TYPE.CONNECTOR]: userPermission(
            user,
            PERMISSION_TYPE.CONNECTOR
          ),
          [PERMISSION_TYPE.ADMIN]: userPermission(user, PERMISSION_TYPE.ADMIN),
          [PERMISSION_TYPE.STAFF_OPS]: userPermission(
            user,
            PERMISSION_TYPE.STAFF_OPS
          ),
        };
        setPermission(tempPermission);
        if (!paginator) 
          {
          if(exportType == 'pdf'){
          const data = res.data.result;
          const doc = new jsPDF("landscape", "mm", "a4");

          let width = doc.internal.pageSize.width;
          let height = doc.internal.pageSize.height;

          const img = new Image();
          img.src = "assets/logo.png";
          img.onload = function () {
            const canvas = document.createElement("canvas");
            const ctx = canvas.getContext("2d");
            canvas.width = img.width;
            canvas.height = img.height;
            ctx.globalAlpha = 0.2;

            ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
            const watermarkBase64 = canvas.toDataURL("image/png");

            const maxWidth = width * 0.5;
            const maxHeight = height * 0.5;
            let newWidth = img.width;
            let newHeight = img.height;

            if (newWidth > maxWidth || newHeight > maxHeight) {
              const widthRatio = maxWidth / newWidth;
              const heightRatio = maxHeight / newHeight;
              const scale = Math.min(widthRatio, heightRatio);

              newWidth = newWidth * scale;
              newHeight = newHeight * scale;
            }

            // Calculate the center position
            let xPos = (width - newWidth) / 2;
            let yPos = (height - newHeight) / 2;
            // doc.addImage(img, 'PNG', width / 2 - 100, height / 2 - 100, 200, 200, undefined, 'SLOW');

            const title = "SM list";
            doc.setFontSize(18);
            doc.setFont("helvetica");
            const textWidth = doc.getTextDimensions(title).w;
            doc.text(title, (width - textWidth) / 2, 10);
            autoTable(doc, {
              head: [columns.map((col) => col.label)],
              body: data.map((product, index) =>
                columns.map((col) => {
                                  
                  if (col.id === "SRNO") {
                    return index + 1;
                  } else {
                    return  product[col.id];
                  }
                })
              ),
              styles: {
                fontSize: 10,
                cellPadding: 3,
                textColor: [0, 0, 0],
              },
              headStyles: {
                fillColor: [52, 152, 219],
                textColor: [255, 255, 255],
              },
              margin: { top: 15 },
              theme: "grid",
              didDrawPage: function (data) {
                doc.addImage(
                  watermarkBase64,
                  "PNG",
                  xPos,
                  yPos,
                  newWidth,
                  newHeight
                );
              },
            });

            doc.save(`Smlist_${newdate}.pdf`);
          };
          setPaginator(true);
        }
        else{
          const data = res.data.result;
          const modifiedData = data.map((product, index) => {
            const row = columns.map(col => {

              let value = product[col.id];
              if (col.id === "mobile") {
                value =  `\t${value}`.toString();
              }
              if (col.id === "SRNO") {
                return index + 1; 
              } else {
                return value;
              }
            });
        
            return row;
          });
          const csvRows = [];
          csvRows.push(columns.map(col => col.label));
          modifiedData.forEach(row => {
            csvRows.push(
              row
            );
          });
          const csvContent = 'data:text/csv;charset=utf-8,' + csvRows.join('\n');
          const encodedUri = encodeURI(csvContent);
          const link = document.createElement('a');
          link.setAttribute('href', encodedUri);
          link.setAttribute('download', `Smlist_${newdate}.csv`);
          document.body.appendChild(link); // Required for FF
          link.click();
          document.body.removeChild(link);
        }
      
      }
        setLoader(false);
      })
      .catch((error) => {});
  };
  
  const columns = [
    { id: "SRNO", label: "SR.NO." },
    { id: "bank_name", label: "Bank Name" },
    { id: "product", label: "Product" },
    { id: "fullname", label: "SM Name" },
    { id: "email", label: "SM Email" },
    { id: "mobile", label: "SM Mobile" },
    { id: "location", label: "Location" },
  ];

  useEffect(() => {
    Promise.all([getSmList(), fetchDropdownOptions(), fetchCodeList()])
      .then(() => {
        setTimeout(() => {
          setLoader(false);
        }, 250);
      })
      .catch((error) => {
        setLoader(false);
      });
  }, [lazyState, paginator]);

  const handleProductChange = (selectedOption: any) => {
    setFormData({
      ...formData,
      codeListId: selectedOption ? selectedOption.value : "",
      codeListId_error: null,
    });
  };
  const locationChange = (selectedOption: any) => {
    getSmList();
  };
  const codeDataOptions = codeData.map((c: any) => ({
    ...c,
    label: c.bank_name + " - " + c.product,
    value: c._id,
  }));

  const handleEditClick = (row: any) => {
    const data = { ...loginCodeListData };
    data.id = row.id;
    data.phone = row.Mobile;
    data.bank_name = row.bankName;
    data.email = row.sm_email;
    data.first_name = row.FirstName;
    data.dsa_code = row.dsa_code;
    data.product = row.product;
    data.location = row.Location;
    data.middle_name = row.MiddleName;
    data.last_name = row.LastName;
    data.codeListId = row.codeListId;
    data.postal_code = row.postalCode;
    setEditOpen(true);
    setEditData(data);
    setFormData(data);
  };
  const handleNumericInput = (e) => {
    e.target.value = e.target.value.replace(/[^0-9]/g, "");
  };

  const handleNameInput = (e) => {
    e.target.value = e.target.value.replace(/[^A-Za-z\s]/g, "");
  };
  const handleSave = () => {
    let updatedForm = { ...formData };
    for (let key in updatedForm) {
      if (typeof updatedForm[key] == "string") {
        updatedForm[key] = updatedForm[key].trim();
      }
    }
    let namePattern = /^[A-Za-z\s]+$/;
    let mobilePattern = /^\+?[0-9]{10,}$/;
    let emailPattern = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,20})+$/;
    let postalCodePattern = /^\d{6}$/;

    if (!formData.first_name) {
      updatedForm = {
        ...updatedForm,
        first_name_error: "Please enter a first name",
      };
    }
    if (formData.first_name != "" && !namePattern.test(formData.first_name)) {
      updatedForm = {
        ...updatedForm,
        first_name_error: "Please enter a valid name",
      };
    }
    if (!formData.last_name) {
      updatedForm = {
        ...updatedForm,
        last_name_error: "Please enter a last name",
      };
    }
    if (formData.last_name != "" && !namePattern.test(formData.last_name)) {
      updatedForm = {
        ...updatedForm,
        last_name_error: "Please enter a valid name",
      };
    }

    if (!formData.postal_code) {
      updatedForm = {
        ...updatedForm,
        postal_code_error: "Please enter a postal code",
      };
    }

    if (
      formData.postal_code != "" &&
      !postalCodePattern.test(formData.postal_code)
    ) {
      updatedForm = {
        ...updatedForm,
        postal_code_error: "Please enter a valid postal code",
      };
    }

    if (!formData.phone) {
      updatedForm = {
        ...updatedForm,
        phone_error: "Please enter a mobile no.",
      };
    }
    if (formData.phone != "" && !mobilePattern.test(formData.phone)) {
      updatedForm = {
        ...updatedForm,
        phone_error: "Please enter a valid mobile no.",
      };
    }

    if (!formData.email) {
      updatedForm = { ...updatedForm, email_error: "Please enter an email" };
    }
    if (formData.email != "" && !emailPattern.test(formData.email)) {
      updatedForm = {
        ...updatedForm,
        email_error: "Please enter a valid email",
      };
    }

    if (!formData.codeListId) {
      updatedForm = {
        ...updatedForm,
        codeListId_error: "Please enter a postal code",
      };
    }
    setFormData(updatedForm);
    if (
      !updatedForm.first_name_error &&
      !updatedForm.last_name_error &&
      !updatedForm.postal_code_error &&
      !updatedForm.phone_error &&
      !updatedForm.email_error &&
      !updatedForm.codeListId_error
    ) {
      if (editOpen) {
       
        if(user.role == "staff_operations"){
          let payload = {
            logincodelistid: updatedForm.codeListId,
            first_name: updatedForm.first_name,
            middle_name: updatedForm.middle_name,
            last_name: updatedForm.last_name,
            location: updatedForm.location,
            postal_code: updatedForm.postal_code,
            mobile: updatedForm.phone,
            email: updatedForm.email,
            };
        const finalPayload={
          changes:payload,
          title: `Update SM list `,
           method:'Put',
          entity_id:updatedForm.id,
          url:'sm/edit-sm-list',
          entity_type:'smlist',
		      operation:'update'
        }  
        ApiPost("ApprovalRequest/approval-request", finalPayload).then(
          (res: any) => {
            getSmList();
            toast.success(res.message, {
              position: "top-right",
              theme: "colored",
            });
          }
        );
      }
      else{
        const payload = {
          logincodelistid: updatedForm.codeListId,
          first_name: updatedForm.first_name,
          middle_name: updatedForm.middle_name,
          last_name: updatedForm.last_name,
          location: updatedForm.location,
          postal_code: updatedForm.postal_code,
          mobile: updatedForm.phone,
          email: updatedForm.email,
        };
        ApiPut(`sm/edit-sm-list?id=${updatedForm.id}`, payload).then(
          (res: any) => {
            getSmList();
            toast.success("Updated successfully", {
              position: "top-right",
              theme: "colored",
            });
          }
        );}
      } else {        
        if(user.role == "staff_operations"){
          let payload = {
            logincodelistid: updatedForm.codeListId,
            first_name: updatedForm.first_name,
            middle_name: updatedForm.middle_name,
            last_name: updatedForm.last_name,
            location: updatedForm.location,
            postal_code: updatedForm.postal_code,
            mobile: updatedForm.phone,
            email: updatedForm.email,
          };
          const finalPayload={
            changes:payload,
            title: `Add SM list`,
            method:'Post',
            entity_id:null,
            url:'sm/add-sm-list',
            entity_type:'smlist',
		        operation:'add'
          }  
        ApiPost("ApprovalRequest/approval-request", finalPayload)
          .then((res: any) => {
            toast.success(res.message, {
              position: "top-right",
              theme: "colored",
            });
          })
          .catch((error) => {
            // toast.error(error.error, {
            //   position: "top-right",
            //   theme: "colored"
            // });
          });
        }
        else{
          let payload = {
            logincodelistid: updatedForm.codeListId,
            first_name: updatedForm.first_name,
            middle_name: updatedForm.middle_name,
            last_name: updatedForm.last_name,
            location: updatedForm.location,
            postal_code: updatedForm.postal_code,
            mobile: updatedForm.phone,
            email: updatedForm.email,
          };
          ApiPost(`sm/add-sm-list`, payload)
          .then((res: any) => {
            toast.success("Added successfully", {
              position: "top-right",
              theme: "colored",
            });
          })
          .catch((error) => {
            // toast.error(error.error, {
            //   position: "top-right",
            //   theme: "colored"
            // });
          });}
      }
      getSmList();
      setAddOpen(false);
      setEditOpen(false);
      setFormData(loginCodeListData);
    }
  };

  const handleDelete = (data: any) => {
    try {
      if(user.role == "staff_operations"){
      const finalPayload={
        changes:{
        dsa_name:data.DsaName,
        bank_name:data.bank_name,
        smname:data.sm_name,
        product_name : data.product_name,
        first_name:data.first_name,
        last_name:data.last_name,
        middle_name : data.middle_name,
        title:"Delete this SM list for"},
        title: `Delete SM `,
        method:'Delete',
        entity_id:data.id,
        url:'sm/delete-sm-list',
        entity_type:'smlist',
		    operation:'delete'
      }  
      ApiPost("ApprovalRequest/approval-request",finalPayload).then((res: any) => {
        toast.success(res.message, {
          position: "top-right",
          theme: "colored",
        });
      });
    } 
    else{
      ApiDelete(`sm/delete-sm-list?id=${data.id}`).then((res: any) => {
        toast.success("Deleted successfully", {
          position: "top-right",
          theme: "colored",
        });
      });
    }}
    catch (ex) {
      // toast.error("something went wrong", {
      //   position: "top-right",
      //   theme: "colored"
      // })
    } 
 
    getSmList();
  };

  const exportPdf = () => {
    setPaginator(false);
    getSmList();
    setExportType('pdf');
  };

  const exportCSV = () => {
    setPaginator(false);
    getSmList();
    setExportType('csv');
    
  };

  const renderHeader = () => {
    return (
      <div className="flex justify-end">
        <div>
          {/* <Button
            type="button"
            icon="pi pi-file-pdf"
            rounded
            onClick={exportPdf}
            data-pr-tooltip="PDF"
          /> */}
          <Button label="Export" icon="pi pi-upload" className="p-button" onClick={(e) => op.current.toggle(e)}>
        <OverlayPanel ref={op}>
                <option style={{ margin: '10px 0',cursor: 'default' }} value='bronze' onClick={exportPdf}>Pdf </option>
                    { (permission[PERMISSION_TYPE.ADMIN] || permission[PERMISSION_TYPE.STAFF] || permission[PERMISSION_TYPE.STAFF_OPS]) && (<option style={{ margin: '10px 0',cursor: 'default' }} value='silver' onClick={() => exportCSV()}>Csv</option>
                                        )}
                                        </OverlayPanel>
        </Button>
        </div>
        <div>
          <span>
            <InputText
              className="mr-2 ml-3"
              value={searchValue}
              onChange={handleInputChange}
              onKeyDown={handleKeyDown}
              placeholder="Keyword Search"
            />
            <Button icon="pi pi-search" onClick={handleSearchClick} />
          </span>
        </div>
      </div>
    );
  };

  const fetchDropdownOptions = () => {
    setLoader(true);
    ApiGet(`options/smlist`).then((res: any) => {
      const banks = convertToObjectArray(res.data.banks);
      const products = convertToObjectArray(res.data.products);
      const locations = convertToObjectArray(res.data.location);
      const fullNames = convertToObjectArray(res.data.fullname);
      const emails = convertToObjectArray(res.data.email);

      setBankNameOptions(banks);
      setProductNameOptions(products);
      setLocationOptions(locations);
      setFullNameOptions(fullNames);
      setEmailOptions(emails);
    });
  };

  const fetchCodeList = () => {
    ApiGet(`loginCodeList/option-codelist`).then((res: any) => {
      if (res) {
        setCodeData(res.data.result);
      }
    });
  };

  const convertToObjectArray = (obj) => {
    return Object.keys(obj).map((key) => ({
      label: obj[key],
      value: key,
    }));
  };

  const header = renderHeader();

  const menuRef = useRef(null);
  const selectedRowData = useRef(null);

  const handleMenuItemClick = (itemLabel) => {
    if (itemLabel === "Edit") {
      setEditOpen(true);
      setEditData(selectedRowData.current);
      handleEditClick(selectedRowData.current);
    } else if (itemLabel === "Delete") {
      setDeleteConfirmVisible(true);
    }
  };

  const handleEllipsisClick = (event, rowData) => {
    event.stopPropagation(); // Prevent event from bubbling up
    selectedRowData.current = rowData;
    if (menuRef.current) {
      menuRef.current.toggle(event);
    }
  };

  const actionBodyTemplate = (rowData) => {
    return (
      <div>
        <img
          src="../assets/menu-cirlce.svg"
          alt="menu-icon"
          style={{ cursor: "pointer" }}
          onClick={(e) => handleEllipsisClick(e, rowData)}
        />
        <TieredMenu model={generateMenuItems()} popup ref={menuRef} />
      </div>
    );
  };

  const generateMenuItems = () => {
    return [
      { label: "Edit", command: () => handleMenuItemClick("Edit") },
      { label: "Delete", command: () => handleMenuItemClick("Delete") },
    ];
  };

  const bankNameRowFilterTemplate = (options) => {
    return (
      <MultiSelect
        value={options.value}
        options={bankNameOptions}
        onChange={(e) => {
          options.filterCallback(e.value);
        }}
        optionLabel="label"
        placeholder="Select"
        className="p-column-filter"
        maxSelectedLabels={1}
        style={{ minWidth: "14rem" }}
        filter
        filterPlaceholder="Search"
      />
    );
  };

  const productRowFilterTemplate = (options) => {
    return (
      <MultiSelect
        value={options.value}
        options={productOptions}
        onChange={(e) => {
          options.filterCallback(e.value);
        }}
        optionLabel="label"
        placeholder="Select"
        className="p-column-filter"
        maxSelectedLabels={1}
        style={{ minWidth: "14rem" }}
        filter
        filterPlaceholder="Search"
      />
    );
  };

  // const locationRowFilterTemplate = (options) => {
  //   return (

  //   );
  // };

  const fullNameRowFilterTemplate = (options) => {
    return (
      <MultiSelect
        value={options.value}
        options={fullNameOptions}
        onChange={(e) => options.filterCallback(e.value)}
        optionLabel="label"
        placeholder="Select"
        className="p-column-filter"
        maxSelectedLabels={1}
        style={{ minWidth: "14rem" }}
        filter
        filterPlaceholder="Search"
      />
    );
  };

  const emailRowFilterTemplate = (options) => {
    return (
      <MultiSelect
        value={options.value}
        options={emailOptions}
        onChange={(e) => options.filterCallback(e.value)}
        optionLabel="label"
        placeholder="Select"
        className="p-column-filter"
        maxSelectedLabels={1}
        style={{ minWidth: "14rem" }}
        filter
        filterPlaceholder="Search"
      />
    );
  };

  const postget = async (e: any) => {
    const value = e.target.value;
    setFormData({
      ...formData,
      postal_code: e.target.value,
      postal_code_error: null,
    });
    if (value.length >= 6) {
      const options = {
        method: "GET",
        url: `https://india-pincode-with-latitude-and-longitude.p.rapidapi.com/api/v1/pincode/${value}`,
        headers: {
          "X-RapidAPI-Key":
            "b2572becadmshbaf647a375178edp179823jsneae8fa2a8334",
          "X-RapidAPI-Host":
            "india-pincode-with-latitude-and-longitude.p.rapidapi.com",
        },
      };
      await axios.request(options).then((res: any) => {
        setFormData({
          ...formData,
          postal_code: e.target.value,
          location:
            res?.data[0] && res?.data[0]?.district
              ? res?.data[0]?.district
              : "",
        });
      });
    }
  };
  const customStyles = (hasError) => ({
    control: (base, state) => ({
      ...base,
      borderColor: hasError ? "red" : base.borderColor,
      "&:hover": {
        borderColor: hasError ? "red" : base.borderColor,
      },
    }),
  });
  const invoiceData = {
    invoiceNumber: '175-LK',
    invoiceDate: 'Aug 29, 2024',
    hsnCode: '1234',
    partnerCode: 'DSA3273',
    reverseCharge: 'No',
    partnerAddress: 'Ahmedabad, Gujarat - 380015',
    billTo: {
      gstin: '24AABCV2469A1ZP',
      address: 'wasedrfgyhu, Ferm name, anb vgh, dxfcgvhbjnkml',
      panno:'AAFFO6331D',
      state:'20',
      mobile:"1234567890",
      web:"www.com.in"
    },
    items: [
      {
        commissionId: 'ACENTEUS BUSINESS TECHNOLOGIES PRIVATE LIMITED	',
        description: 'Daily Payout - New Term Loan',
        rate: '2.15%',
        amount: 32895.0,
      },
      {
        commissionId: 'ACENTEUS BUSINESS TECHNOLOGIES PRIVATE LIMITED	',
        description: 'Daily Payout - New Term Loan',
        rate: '2.15%',
        amount: 32895.0,
      },
      {
        commissionId: 'ACENTEUS BUSINESS TECHNOLOGIES PRIVATE LIMITED	',
        description: 'Daily Payout - New Term Loan',
        rate: '2.15%',
        amount: 32895.0,
      },
      {
        commissionId: 'ACENTEUS BUSINESS TECHNOLOGIES PRIVATE LIMITED	',
        description: 'Daily Payout - New Term Loan',
        rate: '2.15%',
        amount: 32895.0,
      },
      {
        commissionId: 'ACENTEUS BUSINESS TECHNOLOGIES PRIVATE LIMITED	',
        description: 'Daily Payout - New Term Loan',
        rate: '2.15%',
        amount: 32895.0,
      },
      {
        commissionId: 'ACENTEUS BUSINESS TECHNOLOGIES PRIVATE LIMITED	',
        description: 'Daily Payout - New Term Loan',
        rate: '2.15%',
        amount: 32895.0,
      },
      {
        commissionId: 'ACENTEUS BUSINESS LIMITED	',
        description: 'Daily Payout - New Term Loan',
        rate: '2.15%',
        amount: 32895.0,
      },
      {
        commissionId: 'ACENTEUS BUSINESS TECHNOLOGIES PRIVATE LIMITED	',
        description: 'Daily Payout - New Term Loan',
        rate: '2.15%',
        amount: 32895.0,
      }, {
        commissionId: 'ACENTEUS BUSINESS ',
        description: 'Daily Payout - New Term Loan',
        rate: '2.15%',
        amount: 32895.0,
      },
      {
        commissionId: 'ACENTEUS BUSINESS TECHNOLOGIES PRIVATE LIMITED	',
        description: 'Daily Payout - New Term Loan',
        rate: '2.15%',
        amount: 32895.0,
      },
      {
        commissionId: 'ACENTEUS BUSINESS TECHNOLOGIES PRIVATE LIMITED	',
        description: 'Daily Payout - New Term Loan',
        rate: '2.15%',
        amount: 32895.0,
      }, {
        commissionId: ' PRIVATE LIMITED	',
        description: 'Daily Payout - New Term Loan',
        rate: '2.15%',
        amount: 32895.0,
      },
      {
        commissionId: 'ACENTEUS BUSINESS TECHNOLOGIES PRIVATE LIMITED	',
        description: 'Daily Payout - New Term Loan',
        rate: '2.15%',
        amount: 32895.0,
      },
      {
        commissionId: 'ACENTEUS BUSINESS	',
        description: 'Daily Payout - New Term Loan',
        rate: '2.15%',
        amount: 32895.0,
      },
    ],
    total: 32895.0,
    sgst: 2960.55,
    cgst: 2960.55,
    tds: 1644.75,
    totalInvoiceValue: 37171.35,
    amountInWords: 'Thirty Seven Thousand One Hundred Seventy One And Thirty Five Paise Only Thirty Five Paise OnlyThirty Five Paise Only',
    bankDetails: {
      beneficiaryName: 'Partner Name',
      accountNumber: '1234567890',
      bankName: 'Example Bank',
      ifscCode: 'IFSC1234',
      accountType: 'Current',
    },
    additionalInstructions:
      'If you have any questions about this invoice, please contact support@example.com or call us at 123-456-7890.',
  };
  
  const generateInvoicePDF = (invoiceData) => {
    const doc = new jsPDF('p', 'mm', 'a4');
     let width=doc.internal.pageSize.width;
     let height=doc.internal.pageSize.height;


     doc.setFont('Times-Roman', 'bold');
    doc.setFontSize(20);    
    doc.setFillColor(245, 245, 245);     
    doc.roundedRect(10, 15, width-20, 20, 2, 2, 'F'); 
    
    doc.text('INVOICE', 105, 25, { align: 'center' });
    doc.setFont('Times-Roman', 'normal');
    doc.setFontSize(10);    
    doc.text('address asdfghn vdxcfgvhbjnkm xeghj', 105, 32, { align: 'center' });
    
    let yOffset = 45; 
    let xOffset = 12;
    doc.setFontSize(12);
    doc.setFont('Times-Roman', 'bold');
    doc.setTextColor(145, 145, 145); 
    doc.text('Invoice Details:',xOffset, yOffset);
  
    const invoiceDetails = [
      [ invoiceData.invoiceNumber,
       invoiceData.invoiceDate,
       invoiceData.hsnCode,
      invoiceData.partnerCode,
       invoiceData.reverseCharge],
    ];
  
    autoTable(doc, {
      startY: 47, 
      margin: { left: 11,right:11 }, 
      head: [['Invoice Number', 'Invoice Date','HSN Code','Partner Code','Reverse Charge']], 
      body: invoiceDetails,
      theme: 'plain',
      headStyles: {
        fillColor: [255, 255, 255], 
        textColor: [155, 155, 155], 
        fontStyle: 'normal',
        font:'Times-Roman'
      },
      bodyStyles: {
        fillColor: [255, 255, 255], 
        textColor: [0, 0, 0], 
        fontSize: 12,
        font:'Times-Roman',
        fontStyle: 'bold',
      },
      columnStyles: {
        0: { halign: 'left', cellWidth: 'auto' },
        1: { halign: 'left', cellWidth: 'auto' },
      },
      showHead: 'everyPage', 
      tableWidth: 'auto', 
    });
  
    doc.setDrawColor(230,230,230);
    doc.setLineWidth(0.7);
    doc.line(xOffset, 64, width-xOffset,64);

    const startXRight = 107;
    let startY = 80; 
    const verticalLineX = 105; 
    let length=startY + 50
    doc.setLineWidth(0.5);
    doc.line(verticalLineX, startY - 10, verticalLineX,length ); 
  
      const partnerDetails = [
      ['GSTIN', invoiceData.billTo.gstin],
      ['Pan No', invoiceData.billTo.panno],
     
    ];
    const partnerDetails1 = [   
      ['Mobile', invoiceData.billTo.mobile],
      ['State code', invoiceData.billTo.state],
    ];
    
    let startX1=xOffset
    partnerDetails.forEach(([label, value], index) => {
     
        doc.setFontSize(10);
        doc.setFont('Times-Roman', 'bold');
        doc.setTextColor(145, 145, 145);
        doc.text(label, startX1, startY); 
        startX1 +=50
    });
    let startX2=xOffset

    partnerDetails.forEach(([label, value], index) => {
     
        doc.setFontSize(10);
        doc.setTextColor(0);
        doc.setFont('Times-Roman', 'bold');
        doc.text(value, startX2, startY + 7); 
        startX2 +=50
             
    });
    startY +=20
    let startX21=xOffset
    partnerDetails1.forEach(([label, value], index) => {
        doc.setFontSize(10);
        doc.setFont('Times-Roman', 'bold');
        doc.setTextColor(145, 145, 145);
        doc.text(label, startX21, startY); 
        startX21 +=50
    });
    let startX22=xOffset

    partnerDetails1.forEach(([label, value], index) => {
     
        doc.setFontSize(10);
        doc.setTextColor(0);
        doc.setFont('Times-Roman', 'bold');
        doc.text(value, startX22, startY + 7); 
        startX22 +=50
             
    }); 

    startY = 80
    const partnerDetails2 = [
      ['GSTIN', '24AABCV2469A1ZP'],
      ['Website','https://orendafinserv.com'],
    ];
  
        doc.setFontSize(10);
        doc.setFont('Times-Roman', 'bold');
        doc.setTextColor(145, 145, 145);
        doc.text('Bill To', startXRight + 8 , startY);
        doc.setFontSize(10);
        doc.setFont('Times-Roman', 'bold');
        doc.setTextColor(0);
        doc.text('Orenda Finserv LLP, A-88, Topland Residency, Nr.Parijat Society , SadhuVaswani Road, Rajkot, Rajkot, Gujarat- 360005., Gujarat', startXRight + 8 , startY + 7,{ maxWidth: 80 });

        let startX31=startXRight + 8;
        startY = startY + 30 
        partnerDetails2.forEach(([label, value], index) => {
            doc.setFontSize(10);
            doc.setFont('Times-Roman', 'bold');
            doc.setTextColor(145, 145, 145);
            doc.text(label, startX31, startY); 
            startX31 +=40
        });

        let startX32=startXRight + 8
        startY +=7;
        partnerDetails2.forEach(([label, value], index) => {
         
            doc.setFontSize(10);
            doc.setTextColor(0);
            doc.setFont('Times-Roman', 'bold');
            doc.text(value, startX32, startY,{ maxWidth: 45 } ); 
            startX32 +=40
                     
        }); 
  
    yOffset = length + 7;
    doc.setDrawColor(230,230,230);
    doc.setLineWidth(0.7);
    doc.line(xOffset, length + 3, width-xOffset,length + 3);
    console.log('yOffset',yOffset)
    const tableColumnHeaders = ['Sr. No', 'Borrower Name', 'Loan Amount', 'Gross Payout', 'Adjustement Amount','Subvention','Net Payout'];
    const tableRows = invoiceData.items.map((item, index) => [
      index + 1,
      item.commissionId,
      '230000000',
      '23000',
      item.amount,
      item.amount,
      item.amount,
      item.amount
    ]);
    autoTable(doc, {
      startY: yOffset,
      margin:{ left: 11,right:11 },
      head: [tableColumnHeaders],
      body: tableRows,
      theme: 'striped',
      headStyles: {
        fillColor: [248,248,255], 
        textColor: [0, 0, 0], 
        fontStyle: 'bold',
        fontSize: 10,
        font:'Times-Roman',
        halign: 'center',
       
      },
      bodyStyles: {
        fontSize: 10,
        cellPadding: 4,
        font:'Times-Roman' ,
        textColor: [0, 0, 0] ,
        fillColor:[255,255,255] 
      },
      alternateRowStyles: {
        fillColor: [255, 255, 255], 
      },
      columnStyles: {
        0: { halign: 'center', cellWidth: 12 },
        1: { halign: 'center', cellWidth: 32 },
        2: { halign: 'center', cellWidth: 29 },
        3: { halign: 'center', cellWidth: 29 },
        4: { halign: 'center', cellWidth: 29 },
        5: { halign: 'center', cellWidth: 28 },
        6: { halign: 'center', cellWidth: 29 },
      },
      didDrawCell: function (data) {
        if (data.section === 'head') {
          const { column, row, cell } = data;
          const { x, y, width, height } = cell;
    
          if (column.index > 0) {
            const lineX = x;
            const lineYStart = y;
            const lineYEnd = y + height;
            doc.setDrawColor(230,230,230);
            doc.setLineWidth(0.5); 
            doc.line(lineX, lineYStart, lineX, lineYEnd); 
          }
        }
      },
    });
  
    
    let finalY = (doc as any).lastAutoTable.finalY || 90; 
    console.log('(doc as any).lastAutoTable.finalY',(doc as any).lastAutoTable.finalY + yOffset)
    if (finalY > 245) { 
      doc.addPage();
      finalY=10
    }
    doc.setDrawColor(230,230,230);
    doc.setLineWidth(0.7);
    doc.line(xOffset, finalY + 2, width-xOffset,finalY + 2)    
  
    yOffset = finalY + 10;
    doc.setFont('Times-Roman', 'bold');
    doc.setFontSize(10);
    doc.text('Total Rs. ', 160, yOffset);
    doc.text('100000000', 177, yOffset,{ maxWidth: 20 } );

    doc.setDrawColor(230,230,230);
    doc.setLineWidth(0.7);
    doc.line(xOffset, finalY + 16, width-xOffset,finalY + 16);

    yOffset =  finalY + 16 
    const summaryDetails = [
      ['Add SGST (9%)', ` ${invoiceData.total}`],
      ['Add CGST (9%)', ` ${invoiceData.total}`],
      ['Subtract TDS (5%)',` ${invoiceData.total}`],
    ];
  
    console.log("summaryDetails",summaryDetails.length)
    console.log("summaryDetails",yOffset)
    console.log("summaryDetails",yOffset + summaryDetails.length)

    if ((yOffset + summaryDetails.length) > 245) { 
      doc.addPage();
      yOffset=5;
    }
    summaryDetails.forEach(([key, value], index) => {
      doc.setFont('Times-Roman', 'bold');
      doc.setTextColor(145, 145, 145);
      doc.text(`${key}:`, 165, yOffset + 10 );
      doc.setFont('Times-Roman', 'normal');
      doc.setTextColor(0);
      yOffset +=7 
      doc.text(value, 165, yOffset + 10 );
      yOffset +=7 

    });
    
    console.log(summaryDetails.length)
    doc.setDrawColor(230,230,230);
    doc.setLineWidth(0.7);
    
    yOffset += summaryDetails.length 
    console.log("summaryDetails",summaryDetails.length)
    console.log("summaryDetails",yOffset)

    if (yOffset > 255) { 
      doc.addPage();
      yOffset=10;
    }
    

    doc.line(xOffset, yOffset  +=5, width-xOffset,yOffset);

    doc.setFont('Times-Roman', 'italic');
    doc.setFontSize(10);
        doc.setFont('Times-Roman', 'bold-italic');
        doc.setTextColor(145, 145, 145);
    doc.text(`In Words:`, xOffset, yOffset += 9,{ maxWidth: 120 });
    doc.setTextColor(0);
    doc.text(`${invoiceData.amountInWords}`, xOffset +20, yOffset,{ maxWidth: 120 });

    doc.text(`Total invoice value Rs.`, 210-40, yOffset ,);
    doc.text(`23000`, 210-30, yOffset += doc.getLineHeight()-5);
    console.log(doc.getLineHeight())
    yOffset =doc.getLineHeight()+yOffset-5
    doc.line(xOffset, yOffset, width-xOffset,yOffset);

    yOffset += 10;
    doc.setFont('Times-Roman', 'normal');
    doc.setFontSize(12);
    doc.text('Partner Bank Details:', xOffset, yOffset);
    const bankDetails = [
     [ `Beneficiary Name:`,` ${invoiceData.bankDetails.beneficiaryName}`],
     [ `Account Number:`,` ${invoiceData.bankDetails.accountNumber}`],
     [ `Bank Name:`,` ${invoiceData.bankDetails.bankName}`],
     
    ];

    const bankDetails2 = [
      [`IFSC Code:`,` ${invoiceData.bankDetails.ifscCode}`],
      [`Account Type:`,` ${invoiceData.bankDetails.accountType}`],
    ];
    yOffset += 10;

    let xstart1=xOffset
    bankDetails.forEach(([label, value], index) => {
            doc.setFontSize(10);
        doc.setFont('Times-Roman', 'bold');
        doc.setTextColor(145, 145, 145);
        doc.text(label, xstart1, yOffset); 
        xstart1 +=40
    });
    let xstart2=xOffset

    bankDetails.forEach(([label, value], index) => {
      
        doc.setFontSize(10);
        doc.setTextColor(0);
        doc.setFont('Times-Roman', 'bold');
        doc.text(value, xstart2, yOffset + 7); 
        xstart2 +=40
      
    });

    yOffset += 15;

    let xstart21=xOffset
    bankDetails2.forEach(([label, value], index) => {
      
        doc.setFontSize(10);
        doc.setFont('Times-Roman', 'bold');
        doc.setTextColor(145, 145, 145);
        doc.text(label, xstart21, yOffset); 
        xstart21 +=40
    });
    let xstart22=xOffset

    bankDetails2.forEach(([label, value], index) => {
      
        doc.setFontSize(10);
        doc.setTextColor(0);
        doc.setFont('Times-Roman', 'bold');
        doc.text(value, xstart22, yOffset + 7); 
        xstart22 +=40
      
    });

    yOffset += 20
    doc.setDrawColor(230,230,230);
    doc.setLineWidth(0.7);
    doc.line(xOffset, yOffset, width-xOffset,yOffset);
    doc.setDrawColor(230,230,230);
    doc.setLineWidth(0.7);

    doc.setFontSize(10);
    doc.setFont('Times-Roman', 'bold');
    doc.setTextColor(145, 145, 145);
    doc.text('Additional Instructions:', xOffset, yOffset +=7);
    doc.setTextColor(0);
    doc.text('This is a computer generated invoice and does not require signature.', xOffset, yOffset +=6);

    doc.setFontSize(10);
    doc.setFont('Times-Roman', 'bold');
    doc.setTextColor(145, 145, 145);
    doc.text('If you have any questions about this invoice, please contact:', xOffset, yOffset += 6);
    doc.setTextColor(0);
    doc.text('“ Orenda Finserv LLP / Mobile: 9033375852 / Email ID: harsh.bhojani94@gmail.com ”', xOffset, yOffset += 6);

    yOffset+=5
    doc.line(xOffset, yOffset  , width-xOffset,yOffset);

        doc.save('Invoice.pdf');
  };
  
  return (
    <div className="overflow-x-hidden">
      {/* <Sidebar />
    <Header /> */}
      {loader ? (
        <>
          {/* START::OVERLAY */}
          <div className="fixed inset-0 w-full h-screen bg-white z-[9999] flex items-center justify-center">
            <img src="assets/logo.svg" />
          </div>
          {/* END::OVERLAY */}
        </>
      ) : (
        <>
          {/* START::MAIN CONTENT */}
          {/* <main className="lg:pl-[280px] pt-[92px]"> */}
          <div className="p-4 sm:p-9 space-y-5">
            {/* START::DASHBOARD HEADER */}
            <div className="flex flex-wrap gap-5 items-center justify-between">
              <div className="md:flex w-full lg:w-[unset] items-center lg:justify-between md:space-x-9">
                <h1 className="text-[22px] text-light-gray font-semibold">
                  SM List
                </h1>
                <button onClick={() => generateInvoicePDF(invoiceData)}>Download Invoice</button>

                {(permission[PERMISSION_TYPE.ADMIN] ||
                  permission[PERMISSION_TYPE.STAFF_OPS]) && (
                  <ul className="inline-flex items-center p-[2px] text-white bg-primary rounded-[18px] whitespace-nowrap overflow-x-auto">
                    <li>
                      <button
                        className={`py-2 px-7 text-xs font-medium`}
                        onClick={(e) => {
                          e.preventDefault();
                          setAddOpen(true);
                        }}
                      >
                        SM List Data
                      </button>
                    </li>
                  </ul>
                )}
              </div>
              <ul className="flex space-x-[18px] relative z-[9999] w-100">
              <li className="mt-1 "><h1 className="text-[22px] text-light-gray font-semibold">Location :</h1></li>     <li> 
                            <Select
                  value={locationOptions?.find(
                    (opt: any) => opt.value === selectedNodeKey
                  )}
                  onChange={(e:any) => {
                    setSelectedNodeKey(e.value);
                   setlazyState({
                      ...lazyState,
                      filters: {
                        ...lazyState.filters,
                        ["location"]: {
                          value: [e.value],
                          matchMode: FilterMatchMode.IN,
                        }, 
                      },
                      first: 0,
                      page: 0,
                    });
                 }}
                  options={locationOptions}
                  // filter
                  className="w-64 border border-gray rounded-md shadow-sm bg-white text-gray-900 placeholder-gray-500 focus:outline-none focus:border-blue-500 focus:ring-1 focus:ring-blue-500 sm:text-sm"
                  placeholder="Select Location"
                ></Select> 
                            </li>


                        </ul>
                          
            </div>
           
            {/* END::DASHBOARD HEADER */}
            <DataTable
              value={smList}
              lazy
              dataKey="id"
              showGridlines
              paginator={paginator}
              first={lazyState.first}
              filters={lazyState.filters}
              onFilter={onFilter}
              rows={lazyState.rows}
              rowsPerPageOptions={[10, 20, 30, 40, 50]}
              totalRecords={totalRecords}
              onPage={onPage}
              loading={loader}
              header={header}
              emptyMessage="No records found."
              size={"small"}
              scrollable
              scrollHeight="600px"
              className="custom-datatable"
            >
              <Column field="SRNO" header="SR.NO." />
              <Column
                field="bank_name"
                header="Bank Name"
                showFilterMatchModes={false}
                filterMenuStyle={{ minWidth: "14rem" }}
                filter
                filterElement={bankNameRowFilterTemplate}
              />
              <Column
                field="product_name"
                header="Product"
                showFilterMatchModes={false}
                filterMenuStyle={{ minWidth: "14rem" }}
                filter
                filterElement={productRowFilterTemplate}
              />
              <Column
                field="sm_name"
                header="SM Name"
                showFilterMatchModes={false}
                filterMenuStyle={{ minWidth: "14rem" }}
                filter
                filterElement={fullNameRowFilterTemplate}
              />
              <Column
                field="sm_email"
                header="SM Email"
                showFilterMatchModes={false}
                filterMenuStyle={{ minWidth: "14rem" }}
                filter
                filterElement={emailRowFilterTemplate}
              />
              <Column
                field="Mobile"
                header="SM Mobile"
                showFilterMatchModes={false}
                filterMenuStyle={{ minWidth: "14rem" }}
                // filter filterElement={emailRowFilterTemplate}
              />
              {/* Mobile */}
              <Column field="location" header="Location" />
              {permission[PERMISSION_TYPE.ADMIN] ||
              permission[PERMISSION_TYPE.STAFF_OPS] ? (
                <Column
                  field="Action"
                  header="Action"
                  body={actionBodyTemplate}
                />
              ) : null}
            </DataTable>
          </div>

          <div
            className={`tw-modal ${
              addOpen || editOpen ? "flex" : "hidden"
            } !items-start`}
          >
            <div className="tw-modal-content">
              <div className="tw-modal-title">
                <div>Add SM Data</div>
                <button
                  onClick={() => {
                    setAddOpen(false);
                    setEditOpen(false);
                    setFormData(loginCodeListData);
                  }}
                >
                  <img src="assets/close-outline.svg" alt="" />
                </button>
              </div>

              <div className="tw-modal-body">
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-[22px]">
                  <div className="space-y-1">
                    <label
                      htmlFor="first_name"
                      className={`input-label ${
                        formData.first_name_error ? "text-red-500" : ""
                      }`}
                    >
                      First Name<span style={{color:'red'}}>*</span>
                    </label>
                    <div
                      id="first_name"
                      className={`input-wrap ${
                        formData.first_name_error ? "border-red-500" : ""
                      }`}
                    >
                      <img src="assets/user.svg" alt="" />
                      <input
                        type="text"
                        name="first_name"
                        value={formData.first_name}
                        onInput={handleNameInput}
                        onChange={(e: any) => {
                          setFormData({
                            ...formData,
                            first_name: e.target.value,
                            first_name_error: null,
                          });
                        }}
                        className="w-full text-sm"
                        placeholder="Enter First Name"
                      />
                    </div>
                    <p className="text-red-500 text-sm">
                      {formData.first_name_error}
                    </p>
                  </div>
                  <div className="space-y-1">
                    <label
                      htmlFor="last_name"
                      className={`input-label ${
                        formData.last_name_error ? "text-red-500" : ""
                      }`}
                    >
                      Last Name<span style={{color:'red'}}>*</span>
                    </label>
                    <div
                      id="last_name"
                      className={`input-wrap ${
                        formData.last_name_error ? "border-red-500" : ""
                      }`}
                    >
                      <img src="assets/email.svg" alt="" />
                      <input
                        type="text"
                        name="last_name"
                        value={formData.last_name}
                        onInput={handleNameInput}
                        onChange={(e: any) => {
                          setFormData({
                            ...formData,
                            last_name: e.target.value,
                            last_name_error: null,
                          });
                        }}
                        className="w-full text-sm"
                        placeholder="Enter Last Name"
                      />
                    </div>
                    <p className="text-red-500 text-sm">
                      {formData.last_name_error}
                    </p>
                  </div>
                  <div className="space-y-1">
                    <label
                      htmlFor="middle_name"
                      className={`input-label ${
                        formData.middle_name ? "" : ""
                      }`}
                    >
                      Middle Name
                    </label>
                    <div id="middle_name" className={`input-wrap`}>
                      <img src="assets/firm.svg" alt="" />
                      <input
                        type="text"
                        name="middle_name"
                        value={formData.middle_name}
                        onInput={handleNameInput}
                        onChange={(e: any) => {
                          setFormData({
                            ...formData,
                            middle_name: e.target.value,
                          });
                        }}
                        className="w-full text-sm"
                        placeholder="Enter Middle Name"
                      />
                    </div>
                  </div>
                  <div className="space-y-1">
                    <label
                      htmlFor="location"
                      className={`input-label ${formData.location ? "" : ""}`}
                    >
                      Location
                    </label>
                    <div id="location" className={`input-wrap`}>
                      <img src="assets/location.svg" alt="" />
                      <input
                        disabled
                        type="text"
                        name="location"
                        value={formData.location}
                        onChange={(e: any) => {
                          setFormData({
                            ...formData,
                            location: e.target.value,
                          });
                        }}
                        className="w-full text-sm"
                        placeholder="EnterLocation"
                      />
                    </div>
                  </div>
                  <div className="space-y-1">
                    <label
                      htmlFor="postal_code"
                      className={`input-label ${
                        formData.postal_code_error ? "text-red-500" : ""
                      }`}
                    >
                      Postal<span style={{color:'red'}}>*</span>
                    </label>
                    <div
                      id="postal_code"
                      className={`input-wrap ${
                        formData.postal_code_error ? "border-red-500" : ""
                      }`}
                    >
                      <img src="assets/postal_code.svg" alt="" />
                      <input
                        type="text"
                        name="postal_code"
                        maxLength={6}
                        value={formData.postal_code}
                        onInput={handleNumericInput}
                        onChange={(e: any) => {
                          postget(e);
                        }}
                        className="w-full text-sm"
                        placeholder="Enter Postal Code"
                      />
                    </div>
                    <p className="text-red-500 text-sm">
                      {formData.postal_code_error}
                    </p>
                  </div>
                  <div className="space-y-1">
                    <label
                      htmlFor="phone"
                      className={`input-label ${
                        formData.phone_error ? "text-red-500" : ""
                      }`}
                    >
                      Mobile<span style={{color:'red'}}>*</span>
                    </label>
                    <div
                      id="phone"
                      className={`input-wrap ${
                        formData.phone_error ? "border-red-500" : ""
                      }`}
                    >
                      <img src="assets/phone.svg" alt="" />
                      <input
                        type="text"
                        name="phone"
                        value={formData.phone}
                        maxLength={13}
                        onChange={(e: any) => {
                          setFormData({
                            ...formData,
                            phone: e.target.value,
                            phone_error: null,
                          });
                        }}
                        className="w-full text-sm"
                        placeholder="Enter Mobile No."
                      />
                    </div>
                    <p className="text-red-500 text-sm">
                      {formData.phone_error}
                    </p>
                  </div>
                  <div className="space-y-1">
                    <label
                      htmlFor="email"
                      className={`input-label ${
                        formData.email_error ? "text-red-500" : ""
                      }`}
                    >
                      Email<span style={{color:'red'}}>*</span>
                    </label>
                    <div
                      id="email"
                      className={`input-wrap ${
                        formData.email_error ? "border-red-500" : ""
                      }`}
                    >
                      <img src="assets/email.svg" alt="" />
                      <input
                        type="text"
                        name="email"
                        value={formData.email}
                        onChange={(e: any) => {
                          setFormData({
                            ...formData,
                            email: e.target.value,
                            email_error: null,
                          });
                        }}
                        className="w-full text-sm"
                        placeholder="johndue@xyz.com"
                      />
                    </div>
                    <p className="text-red-500 text-sm">
                      {formData.email_error}
                    </p>
                  </div>
                  <div className="space-y-1">
                    <label
                      htmlFor="codeListId"
                      className={`input-label ${
                        formData.codeListId_error ? "text-red-500" : ""
                      }`}
                    >
                      Code list<span style={{color:'red'}}>*</span>
                    </label>
                    <div id="codeListId">
                      <Select
                        options={codeDataOptions}
                        value={codeDataOptions.find(
                          (opt: any) => opt.value === formData.codeListId
                        )}
                        onChange={handleProductChange}
                        className="w-full text-sm py-2"
                        placeholder="Type to search..."
                        styles={customStyles(formData.codeListId_error)}
                      />
                    </div>

                    <p className="text-red-500 text-sm">
                      {formData.codeListId_error}
                    </p>
                  </div>
                </div>
              </div>

              <div className="tw-modal-footer">
                <button
                  className="tw-modal-footer-button bg-secondary text-white"
                  onClick={handleSave}
                >
                  Save
                </button>
                <button
                  className="tw-modal-footer-button bg-secondary bg-opacity-10 text-secondary"
                  onClick={() => {
                    setAddOpen(false);
                    setEditOpen(false);
                    setFormData(loginCodeListData);
                  }}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
          <Toast ref={toastRef} />
          <ConfirmDialog
            // group="declarative"
            visible={deleteConfirmVisible}
            onHide={() => setDeleteConfirmVisible(false)}
            message="Are you sure you want to delete?"
            header="Confirmation"
            icon="pi pi-exclamation-triangle"
            accept={accept}
            reject={reject}
            style={{ width: "50vw" }}
            breakpoints={{ "1100px": "75vw", "960px": "100vw" }}
          />
        </>
      )}
    </div>
  );
}

export default SmList;
