import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import React from "react";
import Select from 'react-select';
import { ApiGet, ApiPost } from "../../helper/API/ApiData";
import { STATE } from "../../constants";
import { toast } from "react-toastify";
import TableComponent from "../../components/Tables";
import { TableCell, TableRow } from "@mui/material";
import * as XLSX from 'xlsx';
import DatePicker from "react-datepicker";
import { format } from 'date-fns';
import 'react-datepicker/dist/react-datepicker.css';
import { DataTable } from "primereact/datatable";
import { FilterMatchMode } from "primereact/api";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { MultiSelect } from "primereact/multiselect";
import { Column } from "primereact/column";
import { TieredMenu } from "primereact/tieredmenu";
import { useLocation, useNavigate } from "react-router";
import { FileUpload, FileUploadHeaderTemplateOptions } from "primereact/fileupload";
import { PERMISSION_TYPE, userPermission } from "../../helper/permission";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";

const BankDisbursementData = () => {

    const fileInputRef = useRef(null);

    let bankDisbursementFormData = {
        logincodelistid: "",
        selectedMonth: "",
        selectedYear: "",
        selectedFile: null,
        logincodelistid_error: null,
        selected_month_error: null,
        selected_year_error: null,
        selected_File_error: null
    };

    const [loader, setLoader] = useState(false);
    const [addUploadFileModel, setUploadFileModel] = React.useState(false);
    const [formData, setFormData] = useState<any>(bankDisbursementFormData);
    const userData = useSelector((state: RootState) => state?.authUser?.userData?.user)
    const [bankDisbursementData, setBankDisbursementData] = useState<any>([]);
    const [state, setState] = useState(STATE.DEFAULT_STATE);
    const [searchValue, setSearchValue] = useState('');
    const [bankNameOptions , setBankNameOptions]=useState(null);
    const [productOptions, setProductOptions]=useState(null)
    const [codeData, setCodeData] = useState<any>([]);
    let navigate=useNavigate()
    const uploadImageRef = useRef<FileUpload>(null);
    const [uploadImageSize, setuploadImageSize] = useState(0);
    const [fileUpload1Files, setFileUploadFiles] = useState([]);
    const [permission, setPermission] = useState<any>({});

    // Generate an array of months
    const months = [
        { label: 'January', value: 1 },
        { label: 'February', value: 2 },
        { label: 'March', value: 3 },
        { label: 'April', value: 4 },
        { label: 'May', value: 5 },
        { label: 'June', value: 6 },
        { label: 'July', value: 7 },
        { label: 'August', value: 8 },
        { label: 'September', value: 9 },
        { label: 'October', value: 10 },
        { label: 'November', value: 11 },
        { label: 'December', value: 12 }
      ];
      const [dataModal,setDataModal]=useState(false);
      const [data,setData]=useState(null);

    const [totalRecords, setTotalRecords] = useState(0);
    const location = useLocation();
    const [bankName,setBankName]=useState([])
    const [product,setProduct]=useState([])
    const [month,setMonth]=useState([])
    const [year,setYear]=useState([])

    const PAGE_KEY = "bankDisbursementFilters";

    const defaultLazyState = {
        first: 0,
        rows: 10,
        page: 0,
        search: "",
        filters: {
            bank_name: { value: null, matchMode: FilterMatchMode.IN  },
            product: { value: null, matchMode: FilterMatchMode.IN  },
            month: { value: null, matchMode:FilterMatchMode.IN  },
            year: { value: null, matchMode: FilterMatchMode.IN  },
        },
    };

    const [lazyState, setlazyState] = useState(defaultLazyState);
    useEffect(() => {
        // Check if the filters are present in the query params
        const queryParams = new URLSearchParams(location.search);
        const filtersFromUrl = queryParams.get("filters");
            if (filtersFromUrl && location.state ) {
                const parsedFilters = JSON.parse(filtersFromUrl);
                setlazyState(parsedFilters);

            } else {
                // Clear filters for unrelated navigations
                setlazyState({...lazyState});
            }
         // Delay in milliseconds (300ms in this example)
    
        // Cleanup the timeout to avoid memory leaks
    }, [location.state]);

      useEffect(() => {
        const tempPermission = {
          [PERMISSION_TYPE.ADMIN]: userPermission(userData, PERMISSION_TYPE.ADMIN),
          [PERMISSION_TYPE.USER]: userPermission(userData, PERMISSION_TYPE.USER),
          [PERMISSION_TYPE.STAFF]: userPermission(userData, PERMISSION_TYPE.STAFF),
          [PERMISSION_TYPE.STAFF_OPS]: userPermission(userData, PERMISSION_TYPE.STAFF_OPS),
          [PERMISSION_TYPE.BOOKING_PARTNER]: userPermission(userData, PERMISSION_TYPE.BOOKING_PARTNER),
        };
        setPermission(tempPermission);
      }, [userData]);
    const monthMap: Record<string, string> = {};
    months.forEach((month) => {
        monthMap[month.value] = month.label;
    });

    // Generate an array of years from 2020 to 2030
    const years = Array.from({ length: 11 }, (_, index) => {
        const yearValue = 2020 + index;
        return { value: yearValue, label: yearValue.toString() };
    });

    const handleMonthChange = (selectedOption: any) => {
        setFormData({
            ...formData,
            selectedMonth: selectedOption ? selectedOption.value : '',
            selected_month_error: null,
        });
    };

    const handleYearChange = (selectedOption: any) => {
        setFormData({
            ...formData,
            selectedYear: selectedOption ? selectedOption.value : '',
            selected_year_error: null,
        });
    };

    const handleProductChange = (selectedOption: any) => {
        setFormData({
            ...formData,
            logincodelistid: selectedOption ? selectedOption.value : '',
            logincodelistid_error: null,
        });
    };

    // const codeDataOptions = codeData?.map((c: any) => ({
    //     ...c,
    //     label: c.bank_name + ' - ' + c.product,
    //     value: c._id,
    // }));
    const convertToObjectArray = (obj) => {
        return Object.keys(obj).map((key) => ({
          label: obj[key],
          value: key,
        }));
      };

      const Datacolumns = [
        { label: 'Bank Name', value: 'bank_name' },
        { label: 'Borrower Name', value: 'borrower_name' },
        { label: 'DSA Name', value: 'user' },
        { label: 'Loan Amount', value: 'loan_amount' },
        { label: 'Month', value: 'month' },
        { label: 'Product', value: 'product' },
        { label: 'Year', value: 'year' },
      ];
    const getBankDisbursementData =  () => {
    setLoader(true);
    const modifiedLazyState = { ...lazyState, limit: lazyState.rows };
    delete modifiedLazyState.rows;
    delete modifiedLazyState['multiSortMeta'];
    delete modifiedLazyState['sortField'];
    delete modifiedLazyState['sortOrder'];

    const replaceEmptyValueArrays = (obj) => {
        for (const key in obj) {
          if (key === 'value' && Array.isArray(obj[key]) && obj[key].length === 0) {
            obj[key] = null;
          } else if (typeof obj[key] === 'object') {
            replaceEmptyValueArrays(obj[key]);
          }
        }
        return obj;
      };
      const finalModifiedLazyState = replaceEmptyValueArrays(modifiedLazyState);
      finalModifiedLazyState.page++;
         ApiPost(`bankdisbursement/get_bankdisbursement_master`,finalModifiedLazyState)
            .then((res: any) => {
                setTotalRecords(Number(res.data.count[0].count));
                let temp = res?.data?.result.map((item: any, index: number) => {
                    return {
                        id: item._id,
                        SRNO: (lazyState.first) + index + 1,
                        bank_name: item.bank_name,
                        product: item.product,
                        month: getMonthString(item.month),
                        year: item.year
                    }
                });
                setBankDisbursementData(temp);
                setLoader(false);
            });
    }

    const onFilter = (event) => {
        event['first'] = 0;
        event['page'] = 0;
        setlazyState(event);
    };
    const onPage = (event) => {
        let filter = { ...lazyState };
        filter = event;
        filter['search'] = lazyState.search;
        setlazyState(filter);
      };
      const renderHeader = () => {
        return (
          <div className="flex justify-end">
            <span>
              <InputText
                className="mr-2"
                value={searchValue}
                onChange={handleInputChange}
                onKeyDown={handleKeyDown}
                placeholder="Keyword Search"
              />
              <Button icon="pi pi-search" onClick={handleSearchClick} />
            </span>
          </div>
        );
      };

      const onGlobalFilterChange = (e) => {
        const value = e;
        let filter = { ...lazyState };
    
        filter['search'] = value;
    
        setlazyState(filter);
      };
    
      const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
          onGlobalFilterChange(searchValue);
        }
      };
    
      const handleSearchClick = () => {
        onGlobalFilterChange(searchValue);
      };
    
      const handleInputChange = (event) => {
        setSearchValue(event.target.value);
      };

      const header = renderHeader();
     const bankNameRowFilterTemplate = (options) => {
        const onApplyFilter = () => {
            options.filterApplyCallback( bankName);
          };
      
          const onClearFilter = () => {
           
            options.filterApplyCallback( null);
            const queryParams = new URLSearchParams(location.search);
            queryParams.delete("filters");
            
            const newqueryParams = new URLSearchParams(location.search);
            newqueryParams.set("filters", JSON.stringify(lazyState));
            navigate({
              pathname: location.pathname,
              search: newqueryParams.toString(),
            }, { replace: true });
         
            // window.location.assign('/bankdisbursement');

          };
           return (
             <div style={{ minWidth: "14rem" }}>
               <div className="mb-2">
                 <MultiSelect
                   optionLabel="label"
                   optionValue="value"
                   value={bankName}
                   options={bankNameOptions}
                   onChange={(e) =>{ 
                    setBankName(e.value)
                     }}
                   placeholder="Select Bank "
                   className="p-column-filter"
                   filter
                   maxSelectedLabels={1}
            style={{ minWidth: "14rem" }}
            filterPlaceholder="Search"
                 />
               </div>
               
               <div className="p-column-filter-buttonbar mt-5" style={{ padding: "0" }}>
                 <Button
                   label="Clear"
                   onClick={onClearFilter}
                   className="p-button p-component p-button-outlined p-button-sm"
                 />
                 <Button
                   label="Apply"
                   onClick={onApplyFilter}
                   className="p-button p-component p-button-sm"
                //    disabled={!isBothSelected}
                 />
               </div>
             </div>
           );;
      };
      const productRowFilterTemplate = (options) => {
        const onApplyFilter = () => {
            options.filterApplyCallback( product);
          };
      
          const onClearFilter = () => {
           
            options.filterApplyCallback( null);
            const queryParams = new URLSearchParams(location.search);
            queryParams.delete("filters");
            
            const newqueryParams = new URLSearchParams(location.search);
            newqueryParams.set("filters", JSON.stringify(lazyState));
            navigate({
              pathname: location.pathname,
              search: newqueryParams.toString(),
            }, { replace: true });
         
            // window.location.assign('/bankdisbursement');

          };
           return (
             <div style={{ minWidth: "14rem" }}>
               <div className="mb-2">
                 <MultiSelect
                   optionLabel="label"
                   optionValue="value"
                   value={product}
                   options={productOptions}
                   onChange={(e) =>{ 
                    setProduct(e.value)
                     }}
                   placeholder="Select Product "
                   className="p-column-filter"
                   filter
                   maxSelectedLabels={1}
            style={{ minWidth: "14rem" }}
            filterPlaceholder="Search"
                 />
               </div>
               
               <div className="p-column-filter-buttonbar mt-5" style={{ padding: "0" }}>
                 <Button
                   label="Clear"
                   onClick={onClearFilter}
                   className="p-button p-component p-button-outlined p-button-sm"
                 />
                 <Button
                   label="Apply"
                   onClick={onApplyFilter}
                   className="p-button p-component p-button-sm"
                //    disabled={!isBothSelected}
                 />
               </div>
             </div>
           );;
      };
      const monthRowFilterTemplate = (options) => {
        const onApplyFilter = () => {
            options.filterApplyCallback( month);
          };
      
          const onClearFilter = () => {
           
            options.filterApplyCallback({ month: null});
            window.location.assign('/bankdisbursement');

          };
           return (
             <div style={{ minWidth: "14rem" }}>
               <div className="mb-2">
                 <MultiSelect
                   optionLabel="label"
                   optionValue="value"
                   value={month}
                   options={months}
                   onChange={(e) =>{ 
                    setMonth(e.value)
                     }}
                   placeholder="Select Month "
                   className="p-column-filter"
                   filter
                   maxSelectedLabels={1}
            style={{ minWidth: "14rem" }}
            filterPlaceholder="Search"
                 />
               </div>
               
               <div className="p-column-filter-buttonbar mt-5" style={{ padding: "0" }}>
                 <Button
                   label="Clear"
                   onClick={onClearFilter}
                   className="p-button p-component p-button-outlined p-button-sm"
                 />
                 <Button
                   label="Apply"
                   onClick={onApplyFilter}
                   className="p-button p-component p-button-sm"
                //    disabled={!isBothSelected}
                 />
               </div>
             </div>
           );;
      };
      const yearRowFilterTemplate = (options) => {
        const onApplyFilter = () => {
            options.filterApplyCallback( year);
          };
      
          const onClearFilter = () => {
           
            options.filterApplyCallback({ year: null});
            window.location.assign('/bankdisbursement');

          };
           return (
             <div style={{ minWidth: "14rem" }}>
               <div className="mb-2">
                 <MultiSelect
                   optionLabel="label"
                   optionValue="value"
                   value={year}
                   options={years}
                   onChange={(e) =>{ 
                    setYear(e.value)
                     }}
                   placeholder="Select Year "
                   className="p-column-filter"
                   filter
                   maxSelectedLabels={1}
            style={{ minWidth: "14rem" }}
            filterPlaceholder="Search"
                 />
               </div>
               
               <div className="p-column-filter-buttonbar mt-5" style={{ padding: "0",}}>
                 <Button
                   label="Clear"
                   onClick={onClearFilter}
                   className="p-button p-component p-button-outlined p-button-sm"
                 />
                 <Button
                   label="Apply"
                   onClick={onApplyFilter}
                   className="p-button p-component p-button-sm"
                //    disabled={!isBothSelected}
                 />
               </div>
             </div>
           );;
      };
     
      const actionBodyTemplate = (rowData) => {
        return (
          <div>
            <img
              src="../assets/menu-cirlce.svg"
              alt="menu-icon"
              style={{ cursor: "pointer" }}
              onClick={(e) => handleEllipsisClick(e, rowData)}
            />
            <TieredMenu model={generateMenuItems()} popup ref={menuRef} />
          </div>
        );
      };
      const handleEllipsisClick = (event, rowData) => {
        event.stopPropagation(); // Prevent event from bubbling up
        selectedRowData.current = rowData;
        if (menuRef.current) {
          menuRef.current.toggle(event);
        }
      };
      const menuRef = useRef(null);
      const selectedRowData = useRef(null);
      const generateMenuItems = () => {
        return [
          { label: "View", command: () => handleMenuItemClick("View") },
          
        ];
      };

      const handleMenuItemClick=(label)=>{
         if( label === "View")
            {
            //    sessionStorage.setItem(PAGE_KEY, JSON.stringify(lazyState));
            //     navigate("/bankdisbursementmodal/"+ selectedRowData.current.id, { state: { from: location.pathname } });
                const queryParams = new URLSearchParams();
                queryParams.set("filters", JSON.stringify(lazyState));
        
                // Redirect to the target page with the filters in the query params
                navigate(`/bankdisbursement/${selectedRowData.current.id}?${queryParams.toString()}`, { state: { from: location.pathname } });
          
            }
      }
    const getOptionsList =  () => {
        setLoader(true)
        ApiGet(`options/get-bank-disbursement-master-data`)
            .then((res: any) => {
            const bank_names=convertToObjectArray(res.data.banks)
            const products=convertToObjectArray(res.data.products)
            setBankNameOptions(bank_names)
            setProductOptions(products)

        });
    }

    const getCodeList = async () => {
        await ApiGet(`loginCodeList/option-codelist`)
            .then((res: any) => {
                setCodeData(Object.values(res.data.result));
            });
    }
    const codeDataOptions = codeData?.map((c: any) => ({
        ...c,
        label: c.bank_name + ' - ' + c.product,
        value: c._id,
    }));

    useEffect(() => {
        Promise.all([
                getBankDisbursementData(),
            getCodeList(),
            getOptionsList(),

        ]).then(() => {
           setLoader(false)
        }).catch((error) => {
            setLoader(false)
        })
    }, [lazyState]);
    

    const handleFileChange = (e) => {
        setFormData({
            ...formData,
            selectedFile: e.target.files[0] ? e.target.files[0] : null,
            selected_File_error: null,
        });
    };

    const handleResetFile = () => {
         uploadImageRef.current.clear();
    };

    const addBankDisbursementData = async () => {
        let error = { ...formData };
        if (formData.selectedMonth == "") {
            error = { ...error, selected_month_error: "Please choose a month" };
        }
        if (formData.selectedYear == "") {
            error = { ...error, selected_year_error: "Please choose a year" };
        }
        if (formData.logincodelistid == "") {
            error = { ...error, logincodelistid_error: "Please choose a code" };
        }
        if (formData.selectedFile == null) {
            error = { ...error, selected_File_error: "Please select a file" };
        }
        setFormData({
            ...error
        });
        if (error.selected_month_error == null && error.selected_year_error == null && error.logincodelistid_error == null && error.selected_File_error == null) {
            const file = formData.selectedFile;

            if (file) {
                try {
                    const excelData = await readExcelFile(file);

                    let fileData: any[] = [];

                    (excelData as any[]).forEach((element: any) => {
                        const data = {
                            'month': element.month.toString() ? element.month.toString() : '',
                            'year': element.year.toString() ? element.year.toString() : '',
                            'customer_name': element.customer_name.toString() ? element.customer_name.toString() : '',
                            'loan_amount': element.loan_amount ? element.loan_amount : 0,
                            'payout': element.payout ? element.payout : 0,
                            'subvention': element.subvention ? element.subvention : 0,
                            'bank_name':element.bank_name.toString()  ? element.bank_name.toString()  : "",
                            'product':element.product.toString()  ? element.product.toString()  : ""
                        };

                        fileData.push(data);
                    });

                    const request = {
                        "bank_name": codeDataOptions?.find((opt: any) => opt.value === formData.logincodelistid).bank_name,
                        "product": codeDataOptions?.find((opt: any) => opt.value === formData.logincodelistid).product,
                        "month": formData.selectedMonth.toString(),
                        "year": formData.selectedYear.toString(),
                        "data": fileData
                    };

                    await ApiPost(`bankdisbursement/upload`, request)
                        .then((res: any) => {
                            if (res?.success == true) {
                                toast.success("file uploaded Successfully.", {
                                    position: "top-right",
                                    theme: "colored"
                                })
                            }
                            getOptionsList();
                            resetFormData();
                            getBankDisbursementData();
                        }).catch((error: any) => {
                            setUploadFileModel(false);
                            // toast.error(error?.error, {
                            //     position: "top-right",
                            //     theme: "colored"
                            // })
                        })

                } catch (error) {
                    console.error('Error reading file:', error);
                }
            }
        }
    };

    const readExcelFile = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();

            reader.onload = (event) => {
                const data = event.target?.result;
                const workbook = XLSX.read(data, { type: 'array' });
                const sheetName = workbook.SheetNames[0];
                const excelData = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName]);
                resolve(excelData);
            };

            reader.onerror = (error) => {
                reject(error);
            };

            reader.readAsArrayBuffer(file);
        });
    };

    const resetFormData = () => {
        setFormData({
            ...formData,
            logincodelistid: '',
            selectedMonth: '',
            selectedYear: '',
            selectedFile: null,
            logincodelistid_error: '',
            selected_month_error: '',
            selected_year_error: '',
            selected_File_error: ''
        });
        handleResetFile();
        setUploadFileModel(false);
        // Add any other state variables you want to reset to their initial values
    };

    let columns = [
        { id: 'SRNO', label: 'SR.NO.' },
        { id: 'bank', label: 'Bank' },
        { id: 'product', label: 'Product' },
        { id: 'month', label: 'Month' },
        { id: 'year', label: 'Year' },
        { id: 'action', label: 'Action' },
    ];

    // Helper function to convert numeric month to string
    const getMonthString = (month: string) => {
        const months = [
            'January', 'February', 'March', 'April',
            'May', 'June', 'July', 'August',
            'September', 'October', 'November', 'December'
        ];
        return months[parseInt(month, 10) - 1]; // Subtract 1 because array is zero-based
    };

    const openModal = () => {
        resetFormData(); // Ensure reset is called before opening the modal
        setUploadFileModel(true);
    };

    // Download File Code Starts

    let downloadFileFormDataVars = {
        download_file_date: new Date(),
        download_file_selected_month: "",
        download_file_selected_year: "",
        download_file_date_error: null,
        download_file_selected_month_error: null,
        download_file_selected_year_error: null,
    };
    const [downloadFileFormData, setDownloadFileFormData] = useState<any>(downloadFileFormDataVars);
    const [downloadFileModal, setDownloadFileModal] = React.useState(false);
    const openDownloadFileModal = () => {
        setDownloadFileModal(true);
    }

    const downloadFileHandleMonthChange = (selectedOption: any) => {
        setDownloadFileFormData({
            ...downloadFileFormData,
            download_file_selected_month: selectedOption ? selectedOption.value : '',
            download_file_selected_month_error: null,
        });
    };

    const downloadFileHandleYearChange = (selectedOption: any) => {
        setDownloadFileFormData({
            ...downloadFileFormData,
            download_file_selected_year: selectedOption ? selectedOption.value : '',
            download_file_selected_year_error: null,
        });
    };

    const downloadFileHandleDateChange = (selectedOption: any) => {
        setDownloadFileFormData({
            ...downloadFileFormData,
            download_file_date: selectedOption ? selectedOption : '',
            download_file_date_error: null,
        });
    };

    const downloadFileResetFormData = () => {
        setDownloadFileFormData({
            ...downloadFileFormData,
            download_file_date: "",
            download_file_selected_month: "",
            download_file_selected_year: "",
            download_file_date_error: null,
            download_file_selected_month_error: null,
            download_file_selected_year_error: null,
        });
        setDownloadFileModal(false);
        // Add any other state variables you want to reset to their initial values
    };

    const downloadData = async () => {

        let error = { ...downloadFileFormData };
        if (downloadFileFormData.download_file_selected_month == "") {
            error = { ...error, download_file_selected_month_error: "Please choose a month" };
        }
        if (downloadFileFormData.download_file_selected_year == "") {
            error = { ...error, download_file_selected_year_error: "Please choose a year" };
        }
        if (downloadFileFormData.download_file_date == "") {
            error = { ...error, download_file_date_error: "Please choose a date" };
        }
        setDownloadFileFormData({
            ...error
        });
        if (error.download_file_selected_month_error == null && error.download_file_selected_year_error == null && error.download_file_date_error == null) {
            const request = {
                "payment_date": format(
                    downloadFileFormData.download_file_date,
                    'dd-MM-yyyy'
                ),
                "month": Number(downloadFileFormData.download_file_selected_month),
                "year": Number(downloadFileFormData.download_file_selected_year)
            };

            await ApiPost(`bankdisbursement/get_download_data`, request)
                .then((res: any) => {
                    if (res && res.data && res.data.finalresult ) {
                        // Create a workbook with the worksheet
                        if(res.data.finalresult.status){

                            setDataModal(true)
                            setData(res.data.finalresult.data)                    
                            setDownloadFileModal(false);
                            downloadFileResetFormData()

                            toast.error("Bank MIS will not get Downloaded Due to Dummy Dsa Disbursement", {
                                    position: "top-right",
                                    theme: "colored"
                                })
                        }
                        else{
                            const ws = XLSX.utils.json_to_sheet(res?.data?.finalresult);
                            for (let cell in ws) {
                            if (ws.hasOwnProperty(cell) && cell[0] !== '!' ) { 
                                // Skip special properties
                                    ws[cell].t = 's'; // Set cell type to string (text)
                                    ws[cell].z = '@'; 
                                }
                              
                            }                     
                        const wb = XLSX.utils.book_new();
                        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
                        // Save the workbook as an Excel file
                        XLSX.writeFile(wb, `OrendaDisbursement.xlsx`);
                        downloadFileResetFormData();
                        setDownloadFileModal(false);}
                    }

                }).catch((error)=>{
                    setDownloadFileModal(false);
                    downloadFileResetFormData();

                });
        }
    }

    const openwallet=()=>{
        navigate("/walletledgeradjustment");

    }
    // Test code ends

    // view data code starts
    let viewDataColumns = [
        { id: 'customer_name', label: 'Customer Name' },
        { id: 'lan_no', label: 'Lan No' },
        { id: 'loan_amount', label: 'Loan Amount' },
        { id: 'payout', label: 'Payout' },
        { id: 'subvention', label: 'Subvention' },
    ];
    const [viewDataState, setViewDataState] = useState(STATE.DEFAULT_STATE);
    const [viewDataModal, setViewDataModal] = React.useState(false);
    const [viewrowData, setRowData] = useState<any>();
    const [viewData, setViewData] = useState<any>([]);
    
    const itemTemplate = (file, props) => {
        return (
            <div className="flex align-items-center flex-wrap">
                <div className="flex align-items-center" style={{ width: '40%' }}>
                    <span>{file.name}</span>
                </div>
                <Button type="button" icon="pi pi-times" className="p-button-outlined p-button-rounded p-button-danger ml-auto" onClick={() => props.onRemove(file)} />
            </div>
        );
    };
    const headerTemplate = (totalSize) => (options: FileUploadHeaderTemplateOptions) => {
        const { className, chooseButton } = options;
    
        return (
            <div className={className} style={{ backgroundColor: 'transparent', display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
                <div style={{ display: 'flex', gap: '10px', alignItems: 'center', flex: '1' }}>
                    {chooseButton}
                </div>
            </div>
        );
    };
    // view data code ends
    const customStyles = (hasError) => ({
        control: (base, state) => ({
          ...base,
          borderColor: hasError ? 'red' : base.borderColor,
          '&:hover': {
            borderColor: hasError ? 'red' : base.borderColor,
          },
        }),
      });

    return (<div className="overflow-x-hidden"
    
    // onClick={

    //     () => 
      
    //     getBankDisbursementData('')
      
    //   }
    
    >
        {loader ?
            <>
                {/* START::OVERLAY */}
                <div className="fixed inset-0 w-full h-screen bg-white z-[9999] flex items-center justify-center">
                    <img src="assets/logo.svg" />
                </div>
                {/* END::OVERLAY */}
            </>
            :
            <>
                <div className="p-4 sm:p-9 space-y-5">
                    <div className="flex flex-wrap gap-5 items-center justify-between">
                        <div className="md:flex w-full lg:w-[unset] items-center lg:justify-between md:space-x-9 ">
                            <h1 className="text-[22px] text-light-gray font-semibold">Bank Disbursement Data</h1>
                        <>
                        { !permission[PERMISSION_TYPE.STAFF] && <><ul className="inline-flex items-center p-[2px] text-white bg-primary rounded-[18px] whitespace-nowrap overflow-x-auto">
                            <li>
                                <button className={`py-2 px-7 text-xs font-medium`} onClick={() => {
                                    openModal()
                                }}>Upload Bank MIS</button>
                            </li>
                        </ul>
                        <ul className="inline-flex items-center p-[2px] text-white bg-primary rounded-[18px] whitespace-nowrap overflow-x-auto">    
                            <li>
                                <button className={`py-2 px-7 text-xs font-medium`} onClick={() => {
                                    openDownloadFileModal()
                                }}>Download Orenda Disbursement</button>
                            </li>                        

                        </ul>
                        <ul className="inline-flex items-center p-[2px] text-white bg-primary rounded-[18px] whitespace-nowrap overflow-x-auto">    
                            <li>
                                <button className={`py-2 px-7 text-xs font-medium`} onClick={() => {
                                    openwallet()
                                }}>Wallet Adjustment</button>
                            </li>                        

                        </ul>
                        </>}
                        </>                        
                        </div>
                    </div>
                    {/* Start:: Add Bank Disbursement Modal */}
                    <div className={`tw-modal ${addUploadFileModel ? 'flex' : 'hidden'}`}>
                        <div className="tw-modal-content">
                            <div className="tw-modal-title">
                                <div>Upload Bank MIS</div>
                                <button onClick={() => setUploadFileModel(false)}>
                                    <img src="assets/close-outline.svg" alt="" />
                                </button>
                            </div>

                            <div className='tw-modal-body py-6'>
                                <div className='d-flex'>
                                    <div className="space-y-1">
                                        <label htmlFor="selectedMonth" className={`input-label ${formData.selected_month_error ? "text-red-500" : ""}`}>Month<span style={{color:'red'}}>*</span></label>
                                        <div id="selectedMonth" className={`${formData.selected_month_error ? "border-red-500" : ""}`}>
                                            <Select
                                                key={formData.selectedMonth}
                                                options={months}
                                                value={months.find((month) => month.value === formData.selectedMonth)}
                                                onChange={handleMonthChange}
                                                placeholder="Select Month"
                                                styles={customStyles(formData.selected_month_error)}

                                            />
                                        </div>
                                        <p className="text-red-500 text-sm">{formData.selected_month_error}</p>
                                    </div>
                                </div>
                            </div>

                            <div className='tw-modal-body py-6'>
                                <div className='d-flex'>
                                    <div className="space-y-1">
                                        <label htmlFor="selectedYear" className={`input-label ${formData.selected_year_error ? "text-red-500" : ""}`}>Year<span style={{color:'red'}}>*</span></label>
                                        <div id="selectedYear" className={`${formData.selected_year_error ? "border-red-500" : ""}`}>
                                            <Select
                                                key={formData.selectedYear}
                                                options={years}
                                                value={years.find((year) => year.value === formData.selectedYear)}
                                                onChange={handleYearChange}
                                                placeholder="Select Year"
                                                styles={customStyles(formData.selected_year_error)}

                                            />
                                        </div>
                                        <p className="text-red-500 text-sm">{formData.selected_year_error}</p>
                                    </div>
                                </div>
                            </div>

                            <div className='tw-modal-body py-6'>
                                <div className='d-flex'>
                                    <div className="space-y-1">
                                        <label htmlFor="logincodelistid" className={`input-label ${formData.logincodelistid_error ? "text-red-500" : ""}`}>Code list<span style={{color:'red'}}>*</span></label>
                                        <div id="logincodelistid" className={`${formData.logincodelistid_error ? "border-red-500" : ""}`}>
                                            <Select
                                                key={formData.logincodelistid}
                                                options={codeDataOptions}
                                                value={codeDataOptions?.find((opt: any) => opt.value === formData.logincodelistid)}
                                                onChange={handleProductChange}
                                                className="w-full text-sm py-2"
                                                placeholder="Type to search..."
                                                styles={customStyles(formData.logincodelistid_error)}
                                            />
                                        </div>
                                        <p className="text-red-500 text-sm">{formData.logincodelistid_error}</p>
                                    </div>
                                </div>
                            </div>

                            <div className='tw-modal-body py-6'>
                                <div className='d-flex'>
                                    <div className="space-y-1">
                                    <div>
              <h4 className="font-semibold text-light-gray text-lg">Choose File<span style={{color:'red'}}>*</span></h4>
              <FileUpload 
                    // mode="basic" 
                    // multiple
                    ref={uploadImageRef}
                    name="demo[]" 
                    accept=".xls, .xlsx, .csv"
                    maxFileSize={2000000}  
                    itemTemplate={itemTemplate}
                    emptyTemplate={<p className="m-0">Drag and drop files here to upload.</p>}
                    headerTemplate={headerTemplate(uploadImageSize)}
                    onSelect={(e) => {
                        setuploadImageSize(e.files.length);
                        setFileUploadFiles(e.files)
                        setFormData({
                            ...formData,
                            selectedFile: e.files[0] ? e.files[0] : null,
                            selected_File_error: null,
                        })
                      }
                  }
                    />
              </div>              
              
                                        {/* <ul className="flex space-x-[18px]">
                                            <li>
                                                <div className="flex justify-center item-center gap-x-2 w-full">
                                                    <label className='px-16 py-[18px] text-semibold text-sm bg-secondary text-white rounded-lg border  bg-light-gray border-light-gray'>
                                                        <span>
                                                            Choose File
                                                        </span>
                                                        <input type="file" accept=".xls, .xlsx, .csv" style={{ display: "none" }} ref={fileInputRef} onChange={handleFileChange} />
                                                    </label>

                                                    <label className="input-wrap min-w-[calc(100vw-944px)] disabled cursor-no-drop text-sm text-[#808080]">{formData?.selectedFile?.name?.toString()} </label>
                                                </div>
                                                <p className="text-red-500 text-sm">{formData.selected_File_error}</p>
                                            </li>
                                        </ul> */}
                                    </div>
                                </div>
                            </div>

                            <div className='tw-modal-footer'>
                                <button className='tw-modal-footer-button bg-secondary text-white'
                                    onClick={() => {
                                        addBankDisbursementData();
                                    }}>
                                    {"Upload"}
                                </button>
                                <button className='tw-modal-footer-button bg bg-secondary bg-opacity-10 text-secondary' onClick={() => {
                                    setUploadFileModel(false);
                                    resetFormData();
                                }}>Cancel</button>
                            </div>
                        </div>
                    </div>
                    {/* End:: Add Bank Disbursement Modal */}

                    <DataTable
                    value={bankDisbursementData}
                    lazy
                    dataKey="id"
                    showGridlines
                    paginator
                    first={lazyState.first}
                    filters={lazyState.filters}
                    onFilter={onFilter}
                    rows={lazyState.rows}
                    rowsPerPageOptions={[10, 20, 30, 40, 50]}
                    totalRecords={totalRecords}
                    onPage={onPage}
                    loading={loader}
                    size={'small'}
                    scrollable
                    scrollHeight="600px"
                    className="custom-datatable"
                    header={header}
                    emptyMessage="No records found."
                >
                    <Column
                        field="SRNO"
                        frozen
                        header="SR.NO."
                    />
                    <Column
                        field="bank_name"
                        header="Name"
                        showFilterMatchModes={false}
                        showApplyButton={false}
                        showClearButton={false}
                        filterMenuStyle={{ minWidth: "14rem" }}
                        filter
                        filterElement={bankNameRowFilterTemplate}

                    />
                    <Column
                field="product"
                header="Product"
                showFilterMatchModes={false}
                        showApplyButton={false}
                        showClearButton={false}
                        filterMenuStyle={{ minWidth: "14rem" }}
                        filter
                filterElement={productRowFilterTemplate}
              />
              <Column
                field='month'
                header="Month"
                showFilterMatchModes={false}
                        showApplyButton={false}
                        showClearButton={false}
                        filterMenuStyle={{ minWidth: "14rem" }}
                        filter
                filterElement={monthRowFilterTemplate}
              />
              <Column
                field="year"
                header="Year"
                showFilterMatchModes={false}
                showApplyButton={false}
                showClearButton={false}
                filterMenuStyle={{ minWidth: "14rem" }}
                filter
                filterElement={yearRowFilterTemplate}
              />
              <Column
                field="Action"
                header="Action"
                body={actionBodyTemplate}
              />
                    
                </DataTable>


                    {/* Start:: view Modal */}
                    {/* <div className={`tw-modal ${viewDataModal ? 'flex' : 'hidden'}`}>
                        <div className="tw-modal-content">
                            <div className="tw-modal-title">
                                <div>Bank Disbursement Detail</div>
                                <button onClick={() => setViewDataModal(false)}>
                                    <img src="assets/close-outline.svg" alt="" />
                                </button>
                            </div>

                            <div>
                                <div className="flex m-4">
                                    <div>
                                        <label className="font-bold">Bank: </label>
                                        <span>{viewrowData?.bank_name}</span>
                                    </div>
                                    <div>
                                        <label className="font-bold">Product: </label>
                                        <span>{viewrowData?.product}</span>
                                    </div>
                                    <div>
                                        <label className="font-bold">Year: </label>
                                        <span>{viewrowData?.year}</span>
                                    </div>
                                    <div>
                                        <label className="font-bold">Month: </label>
                                        <span>{getMonthString(viewrowData?.month)}</span>
                                    </div>
                                </div>
                            </div>
                            <div className="m-4">
                                <TableComponent
                                    rows={viewData}
                                    columns={viewDataColumns}
                                    state={viewDataState}
                                    renderColumn={(column: any) => {
                                        return (
                                            <TableCell
                                                align="center"
                                                className="!bg-gray-200"
                                                key={column.id}
                                            >
                                                {column.label}
                                            </TableCell>
                                        )
                                    }}
                                    renderRow={(row: any, index: number) => {
                                        return (
                                            <TableRow hover role="checkbox" tabIndex={-1} key={row._id}>
                                                <TableCell align="center" component="th" scope="row">
                                                    {row.customer_name}
                                                </TableCell>
                                                <TableCell align="center" component="th" scope="row">
                                                    {row.lan_no}
                                                </TableCell>
                                                <TableCell align="center" component="th" scope="row">
                                                    {row.loan_amount}
                                                </TableCell>
                                                <TableCell align="center" component="th" scope="row">
                                                    {row.payout}
                                                </TableCell>
                                                <TableCell align="center" component="th" scope="row">
                                                    {row.subvention}
                                                </TableCell>
                                            </TableRow>
                                        )
                                    }}
                                />
                            </div>
                        </div>
                    </div> */}
                    {/* End:: view Modal */}

                    {/* Start:: Add Download Modal */}
                    <div className={`tw-modal ${downloadFileModal ? 'flex' : 'hidden'}`}>
                        <div className="tw-modal-content">
                            <div className="tw-modal-title">
                                <div>Download Orenda Disbursement</div>
                                <button onClick={() => setDownloadFileModal(false)}>
                                    <img src="assets/close-outline.svg" alt="" />
                                </button>
                            </div>

                            <div className='tw-modal-body py-6'>
                                <div className='d-flex'>
                                    <div className="space-y-1">
                                        <label htmlFor="download_file_selected_month" className={`input-label ${downloadFileFormData.download_file_selected_month_error ? "text-red-500" : ""}`}>Month<span style={{color:'red'}}>*</span></label>
                                        <div id="download_file_selected_month" className={`${downloadFileFormData.download_file_selected_month_error ? "border-red-500" : ""}`}>
                                            <Select
                                                key={downloadFileFormData.download_file_selected_month}
                                                options={months}
                                                value={months.find((month) => month.value === downloadFileFormData.download_file_selected_month)}
                                                onChange={downloadFileHandleMonthChange}
                                                placeholder="Select Month"
                                                styles={customStyles(downloadFileFormData.download_file_selected_month_error)}
                                            />
                                        </div>
                                        <p className="text-red-500 text-sm">{downloadFileFormData.download_file_selected_month_error}</p>
                                    </div>
                                </div>
                            </div>

                            <div className='tw-modal-body py-6'>
                                <div className='d-flex'>
                                    <div className="space-y-1">
                                        <label htmlFor="download_file_selected_year" className={`input-label ${downloadFileFormData.download_file_selected_year_error ? "text-red-500" : ""}`}>Year<span style={{color:'red'}}>*</span></label>
                                        <div id="download_file_selected_year" className={`${downloadFileFormData.download_file_selected_year_error ? "border-red-500" : ""}`}>
                                            <Select
                                                key={downloadFileFormData.download_file_selected_year}
                                                options={years}
                                                value={years.find((year) => year.value === downloadFileFormData.download_file_selected_year)}
                                                onChange={downloadFileHandleYearChange}
                                                placeholder="Select Year"
                                                styles={customStyles(downloadFileFormData.download_file_selected_year_error)}
                                            />
                                        </div>
                                        <p className="text-red-500 text-sm">{downloadFileFormData.download_file_selected_year_error}</p>
                                    </div>
                                </div>
                            </div>

                            <div className='tw-modal-body py-6'>
                                <div className='d-flex'>
                                    <div className="space-y-1">
                                        <label htmlFor="download_file_date" className={`input-label ${downloadFileFormData.download_file_date_error ? "text-red-500" : ""}`}>Payment Date<span style={{color:'red'}}>*</span></label>
                                        <div id="download_file_date" className={`input-wrap ${downloadFileFormData.download_file_date_error ? "border-red-500" : ""}`}>
                                            <img src="assets/date.svg" alt="" />
                                            <DatePicker
                                                key={downloadFileFormData.download_file_date}
                                                selected={downloadFileFormData.download_file_date}
                                                onChange={(date) => downloadFileHandleDateChange(date)}
                                                dateFormat="dd-MM-yyyy"
                                                showFullMonthYearPicker
                                            />
                                        </div>
                                        <p className="text-red-500 text-sm">{downloadFileFormData.download_file_date_error}</p>
                                    </div>
                                </div>
                            </div>

                            <div className='tw-modal-footer'>
                                <button className='tw-modal-footer-button bg-secondary text-white'
                                    onClick={() => {
                                        downloadData();
                                    }}>
                                    {"Download"}
                                </button>
                                <button className='tw-modal-footer-button bg bg-secondary bg-opacity-10 text-secondary' onClick={() => {
                                    setDownloadFileModal(false);
                                    downloadFileResetFormData();
                                }}>Cancel</button>
                            </div>
                        </div>
                    </div>
                    {/* End:: Add Download Modal */}
                    <Dialog header="Dummy Data Values" visible={dataModal} style={{ width: '50vw' }} onHide={() => setDataModal(false)}>
  <div>
    <table style={{ width: "100%", borderCollapse: "collapse" }}>
      <thead>
        <tr>
          {/* Render headers from the columns array */}
          {Datacolumns.map((col) => (
            <th key={col.value} style={{ border: "1px solid black", padding: "8px" }}>
              {col.label}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {/* Render rows dynamically based on the columns array */}
        {data?.map((row, rowIndex) => (
          <tr key={rowIndex}>
            {Datacolumns.map((col) => (
              <td key={col.value} style={{ border: "1px solid black", padding: "8px" }}>
                {row[col.value]}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  </div>
</Dialog>

                </div>
            </>
        }
    </div>
    )
}

export default BankDisbursementData