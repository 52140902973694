import {
    Checkbox,
    TableCell,
    TableRow,
    Tooltip,
    TextField,
  } from "@mui/material";
  import React, { useRef, useState } from "react";
  import TableComponent from "../../components/Tables";
  import { useEffect } from "react";
  import DatePicker from "react-datepicker";
  import { ApiGet, ApiPost, ApiPut } from "../../helper/API/ApiData";
  import { STATE } from "../../constants";
  import axios from "axios";
  import _ from "lodash";
  import { isEmpty, numberFormat } from "../../helper/util";
  import { getSlabValue } from "../../helper/util";
  import STORAGEKEY from "../../config/APP/app.config";
  import { useDispatch, useSelector } from "react-redux";
  import { useNavigate } from "react-router";
  import { Logout_Auth } from "../../redux/reducers/authReducer";
  import { PERMISSION_TYPE, userPermission } from "../../helper/permission";
  import { RootState } from "../../redux/store";
  import { toast } from "react-toastify";
  import CsvDownload from "react-json-to-csv";
  import { FilterMatchMode } from "primereact/api";
  import { Column } from "primereact/column";
  import { DataTable } from "primereact/datatable";
  import { InputText } from "primereact/inputtext";
  import { Button } from "primereact/button";
  import { TieredMenu } from "primereact/tieredmenu";
  import { MultiSelect } from "primereact/multiselect";
  import { Dropdown } from "primereact/dropdown";
  import { Padding } from "@mui/icons-material";
  import { ConfirmDialog } from "primereact/confirmdialog";
  import { isDisabled } from "@testing-library/user-event/dist/utils";
  import jsPDF from "jspdf";
  import autoTable from "jspdf-autotable";
  import { RadioButton } from "primereact/radiobutton";
  import moment from 'moment'
  import Select from 'react-select';
  import { OverlayPanel } from "primereact/overlaypanel";
import { Dialog } from "primereact/dialog";
  
  const ReconcileCaseData = () => {
    const navigate = useNavigate();
        const [loader, setLoader] = useState(true);
    
    const [disbursementDetail, setDisbursementDetail] = useState([]);
    const [state, setState] = useState(STATE.DEFAULT_STATE);
   
    const [permission, setPermission] = useState<any>({});
    const userData = useSelector(
      (state: RootState) => state?.authUser?.userData?.user
    );
        const [selectedMonth, setSelectedMonth] = useState(null);
    const [selectedYear, setSelectedYear] = useState(null);
    const [searchValue, setSearchValue] = useState("");
    const [totalRecords, setTotalRecords] = useState(0);
    const [advanceRequests, setAdvanceRequests] = useState(null);
    const [advanceStatuses, setAdvanceStatuses] = useState(null);
    const [financers, setFinancers] = useState(null);
    const [dsaName, setDsaNames] = useState(null);
    const [paymentStatuses, setPaymentStatuses] = useState(null);
    const [products, setProducts] = useState(null);
    const [paginator, setPaginator] = useState(true);
    const [dataModal,setDataModal]=useState(false);
    const [salesM, setSalesM] = useState(null);
    const [data,setData]=useState(null);
    const [lazyState, setlazyState] = useState({
      first: 0,
      rows: 10,
      page: 0,
      pagination:'',
      search: "",
      filters: {
        Financer: { value: null, matchMode: FilterMatchMode.IN },
        DsaName: { value: null, matchMode: FilterMatchMode.IN },
        Product: { value: null, matchMode: FilterMatchMode.IN },
        dismonth: { value: null, matchMode: FilterMatchMode.IN },
        Paymentstatus: { value: null, matchMode: FilterMatchMode.IN },
        Advancerequest: { value: null, matchMode: FilterMatchMode.IN },
        Advancestatus: { value: null, matchMode: FilterMatchMode.IN },
        co_status: { value: 1, matchMode: FilterMatchMode.IN },
        sales_name: { value: null, matchMode: FilterMatchMode.IN },

      },
    });
    const reasonData={
      reason:'',
      reason_error:null
    }
    const [reasonInfo,setReason]=useState(reasonData);
    const [reasonPopup,setReasonPopUp]=useState(false);
   
    const op = useRef(null);
    const[exportType,setExportType]=useState('');
    let date=new Date().toDateString();
    const [newdate,setDate]=useState(moment(date).format('DD-MM-YY'))
    useEffect(() => {
      if (!userData.profile_completed && userData.role === "user") {
        toast.error("Please validate your KYC before adding disbursement data.", {
          position: "top-right",
          theme: "colored",
        });
        navigate(`/`);
      }
      const tempPermission = {
        [PERMISSION_TYPE.ADMIN]: userPermission(userData, PERMISSION_TYPE.ADMIN),
        [PERMISSION_TYPE.USER]: userPermission(userData, PERMISSION_TYPE.USER),
        [PERMISSION_TYPE.STAFF]: userPermission(userData, PERMISSION_TYPE.STAFF),
        [PERMISSION_TYPE.CONNECTOR]: userPermission(userData, PERMISSION_TYPE.CONNECTOR),
        [PERMISSION_TYPE.STAFF_OPS]: userPermission(userData, PERMISSION_TYPE.STAFF_OPS),
        [PERMISSION_TYPE.BOOKING_PARTNER]: userPermission(userData, PERMISSION_TYPE.BOOKING_PARTNER),
  
      };
      setPermission(tempPermission);
    }, [userData]);
  
    const getOptionsData = () => {
      setLoader(true);
      ApiGet(`options/disbursement`).then((res: any) => {
        const advanceRequests = convertToObjectArray(res.data.advance_request);
        const advanceStatuses = convertToObjectArray(res.data.advance_status);
        const financers = convertToObjectArray(res.data.financers);
        const paymentStatuses = convertToObjectArray(res.data.payment_status);
        const products = convertToObjectArray(res.data.products);
        const sales_name = convertToObjectArray(res.data.sales_name);

        setAdvanceRequests(advanceRequests);
        setAdvanceStatuses(advanceStatuses);
        setFinancers(financers);
        setPaymentStatuses(paymentStatuses);
        setProducts(products);
        setSalesM(sales_name)

      });
    };
  
    const convertToObjectArray = (obj) => {
      return Object.keys(obj).map((key) => ({
        label: obj[key]?.toString(),
        value: key,
      }));
    };
  
    const getUser = () => {
      ApiGet(`user/option-users`)
        .then((res: any) => {
          const users = convertToObjectArray(res.data.users);
          
          setDsaNames(users)
        })
        .catch((error) => {
        });
    };
  
    const advanceRequestFilterTemplate = (options) => {
      return (
        <MultiSelect
          value={options.value}
          options={advanceRequests}
          onChange={(e) => options.filterCallback(e.value)}
          optionLabel="label"
          placeholder="Select"
          className="p-column-filter"
          maxSelectedLabels={1}
          style={{ minWidth: "14rem" }}
          filter
          filterPlaceholder="Search"
        />
      );
    };
  
    const advanceStatusesFilterTemplate = (options) => {
      return (
        <MultiSelect
          value={options.value}
          options={advanceStatuses}
          onChange={(e) => options.filterCallback(e.value)}
          optionLabel="label"
          placeholder="Select"
          className="p-column-filter"
          maxSelectedLabels={1}
          style={{ minWidth: "14rem" }}
          filter
          filterPlaceholder="Search"
        />
      );
    };
  
    const financersFilterTemplate = (options) => {
      return (
        <MultiSelect
          value={options.value}
          options={financers}
          onChange={(e) => options.filterCallback(e.value)}
          optionLabel="label"
          placeholder="Select"
          className="p-column-filter"
          maxSelectedLabels={1}
          style={{ minWidth: "14rem" }}
          filter
          filterPlaceholder="Search"
        />
      );
    };
    const dsaNameFilterTemplate = (options) => {
      return (
        <MultiSelect
          value={options.value}
          options={dsaName}
          onChange={(e) => options.filterCallback(e.value)}
          optionLabel="label"
          placeholder="Select"
          className="p-column-filter"
          maxSelectedLabels={1}
          style={{ minWidth: "14rem" }}
          filter
          filterPlaceholder="Search"
        />
      );
    };
    const salesNameFilterTemplate = (options) => {
      return (
        <MultiSelect
          value={options.value}
          options={salesM}
          onChange={(e) => options.filterCallback(e.value)}
          optionLabel="label"
          placeholder="Select"
          className="p-column-filter"
          maxSelectedLabels={1}
          style={{ minWidth: "14rem" }}
          filter
          filterPlaceholder="Search"
        />
      );
    };
    const paymentStatusesFilterTemplate = (options) => {
      return (
        <MultiSelect
          value={options.value}
          options={paymentStatuses}
          onChange={(e) => options.filterCallback(e.value)}
          optionLabel="label"
          placeholder="Select"
          className="p-column-filter"
          maxSelectedLabels={1}
          style={{ minWidth: "14rem" }}
          filter
          filterPlaceholder="Search"
        />
      );
    };
  
    const productsFilterTemplate = (options) => {
      return (
        <MultiSelect
          value={options.value}
          options={products}
          onChange={(e) => options.filterCallback(e.value)}
          optionLabel="label"
          placeholder="Select"
          className="p-column-filter"
          maxSelectedLabels={1}
          style={{ minWidth: "14rem" }}
          filter
          filterPlaceholder="Search"
        />
      );
    };
    const months = [
      { label: "January", value: 1 },
      { label: "February", value: 2 },
      { label: "March", value: 3 },
      { label: "April", value: 4 },
      { label: "May", value: 5 },
      { label: "June", value: 6 },
      { label: "July", value: 7 },
      { label: "August", value: 8 },
      { label: "September", value: 9 },
      { label: "October", value: 10 },
      { label: "November", value: 11 },
      { label: "December", value: 12 },
    ];
    const monthMap: Record<string, string> = {};
      months.forEach((month) => {
          monthMap[month.value] = month.label;
      });
  
      // Generate an array of years from 2020 to 2030
      const years = Array.from({ length: 11 }, (_, index) => {
          const yearValue = 2020 + index;
          return { value: yearValue, label: yearValue.toString() };
      });
  
    const DismonthFilterTemplate = (options) => {
      
      const years=[];
      let currentYear;
      let currentMonth = new Date().getMonth();
                if(currentMonth <= 6){
                   currentYear = new Date().getFullYear();
                }
                else{
                   currentYear = new Date().getFullYear()+1;
                }  
                for (let i = currentYear; i >= 2018; i--) {
                  years.push({ label: i.toString(), value: i });
                }  
  
      const onApplyFilter = () => {
        options.filterApplyCallback({ month: selectedMonth, year: selectedYear });
      };
  
      const onClearFilter = () => {
        setSelectedMonth(null);
        setSelectedYear(null);
        options.filterApplyCallback({ month: null, year: null });
      };
  
      const isBothSelected = selectedMonth && selectedYear;
      return (
        <div style={{ minWidth: "14rem" }}>
          <div className="mb-2">
            <Dropdown
              optionLabel="label"
              optionValue="value"
              value={selectedMonth}
              options={months}
              onChange={(e) =>{ 
                
                setSelectedMonth(e.value)}}
              placeholder="Select Month"
              className="p-column-filter"
              filter
            />
          </div>
          <div className="mb-2">
            <Dropdown
              optionLabel="label"
              optionValue="value"
              value={selectedYear}
              options={years}
              onChange={(e) => setSelectedYear(e.value)}
              placeholder="Select Year"
              className="p-column-filter"
              filter
            />
          </div>
          <div className="p-column-filter-buttonbar" style={{ padding: "0" }}>
            <Button
              label="Clear"
              onClick={onClearFilter}
              className="p-button p-component p-button-outlined p-button-sm"
            />
            <Button
              label="Apply"
              onClick={onApplyFilter}
              className="p-button p-component p-button-sm"
              disabled={!isBothSelected}
            />
          </div>
        </div>
      );
    };
  
    const columns = [
      { id: "SRNO", label: "SR.NO." },
      permission[PERMISSION_TYPE.ADMIN] || permission[PERMISSION_TYPE.STAFF_OPS] 
      ? { id: 'sales_name', label: 'SM/BP Name' } : null,
        permission[PERMISSION_TYPE.ADMIN] || permission[PERMISSION_TYPE.STAFF_OPS] || permission[PERMISSION_TYPE.STAFF] || permission[PERMISSION_TYPE.BOOKING_PARTNER]
        ? { id: 'dsa_name', label: 'DSA Name' } : null,
      { id: "borrower_name", label: "Borrower Name" },
      { id: "borrower_contact_person_name", label: "Contact Person" },
      { id: "loan_amount", label: "Loan Amount" },
      { id: "bank_name", label: "Financer" },
      { id: "product", label: "Product" },
      { id: "disbursement_date", label: "Dis. Month" },
      { id: "connector_name", label: "Connector Name" },
      { id:`${permission[PERMISSION_TYPE.CONNECTOR] ? "connector_rate" : "rate"}`, label: "Grid Payout %" },
      permission[PERMISSION_TYPE.CONNECTOR] ?
       null :{ id: "connector_rate", label: "Connector Payout %" },
       permission[PERMISSION_TYPE.CONNECTOR] ?
       null :{ id: "adjusted_payout_per", label: "Adjusted Payout % " },
      permission[PERMISSION_TYPE.CONNECTOR] ?
       null :{ id: "adjustment_amount", label: "Adjustment Amount" },
       { id: "subvention_per", label: "Subvention %" },
       { id: "subvention", label: "Subvention" },
       permission[PERMISSION_TYPE.ADMIN] 
       ? { id: 'system_adjustment', label: 'System Adjustment' } : null, 
      { id: `${permission[PERMISSION_TYPE.CONNECTOR] ? "connector_gross_payout" :"gross_payout"}`, label: "Gross Payout" },
      { id:`${permission[PERMISSION_TYPE.CONNECTOR] ? "connector_tds_amount" :  "tds_amount"}`, label: "Tds Amount" },
      { id: `${permission[PERMISSION_TYPE.CONNECTOR] ? "connector_gst_amount" :"gst_amount"}`, label: "GST Amount" },
      { id:`${permission[PERMISSION_TYPE.CONNECTOR] ? "connector_net_amount_payable" :  "net_amount_payable"}`, label: "Net Payout" },
      { id: "status", label: "Payment Status" },
      { id: "advance_request", label: "Advance Request" },
      { id: "advance_status", label: "Advance Status" },
    ];
  
        const ConvertDate=(date)=>{
      let ndate=new Date(date);
      
      return ndate.toISOString() ;
    }
    const getData = () => {
      setLoader(true);
       let  modifiedLazyState ;
       if(paginator) {
        modifiedLazyState={  ...lazyState,
          limit: lazyState.rows,
          pagination: true,
        }}
        else{
          modifiedLazyState={  ...lazyState,
            limit: lazyState.rows,
            pagination: false,
          }
        }
      delete modifiedLazyState.rows;
      delete modifiedLazyState["multiSortMeta"];
      delete modifiedLazyState["sortField"];
      delete modifiedLazyState["sortOrder"];
  
      // Function to replace empty arrays in the "value" field with null
      const replaceEmptyValueArrays = (obj) => {
        for (const key in obj) {
          if (
            key === "value" &&
            Array.isArray(obj[key]) &&
            obj[key].length === 0
          ) {
            obj[key] = null;
          } else if (typeof obj[key] === "object") {
            replaceEmptyValueArrays(obj[key]);
          }
        }
        return obj;
      };
      // Call the function to replace empty arrays in the "value" field with null
      const finalModifiedLazyState = replaceEmptyValueArrays(modifiedLazyState);
      finalModifiedLazyState.page++;
      ApiPost(`disbursement/get-disbursement`, finalModifiedLazyState)
        .then((res: any) => {
          setTotalRecords(Number(res.data.count.count));
            let finalData = res.data.result.map((item: any, index: number) => {
              return {
                SRNO: (lazyState.first) + index + 1,
                id: item._id,
                DsaName: item.dsa_name ?? "-",
                BorrowerName: item.borrower_name ?? "-",
                Contact_Person_Name:
                  item.borrower_contact_person_name == ""
                    ? "-"
                    : item.borrower_contact_person_name,
                Loanamount: item.loan_amount ?? "-",
                Financer: item.bank_name ?? "-",
                Product: item?.product ?? "-",
                dismonth:ConvertDate(item?.disbursement_date) ?? "-",
                Connectorname: item?.connector_name ?? "-",
                gross_payout: item?.gross_payout ?? "0",
                Advancegross: item?.advance_gross,
                tds_amount: item?.tds_amount ?? "0",
                gst_amount: item?.gst_amount ?? "",
                Userpayout: item?.rate ?? "0",
                net_amount_payable: item?.net_amount_payable ?? "0",
                Advancerequest: item?.advance_request ?? "-",
                Advancestatus: item?.advance_status ?? "-",
                Paymentstatus: item?.status,
                city: item?.city,
                state: item?.state,
                borrower_pincode: item?.borrower_pincode,
                borrower_contact_person_number:
                  item?.borrower_contact_person_number,
                logincodelist_id: item?.logincodelist_id,
                connector_id: item?.connector_id,
                connector_rate: item?.connector_rate,
                is_connector: item?.is_connector,
                lan_no: item?.lan_no,
                adjustment_amount: item?.adjustment_amount,
                // disbursement_date:item?.disbursement_date,
                reason: item?.reason,
                connector_gross_payout:item.connector_gross_payout,
                connector_gst_amount:item.connector_gst_amount ?? '',
                connector_net_amount_payable:item.connector_net_amount_payable,
                connector_tds_amount:item.connector_tds_amount,
                dsa_id:item.dsa_id,
                co_status:item.co_status,
                subvention:item.subvention,
                adjusted_payout_per:item.adjusted_payout_per,
                subvention_per:item.subvention_per,
                advance_proof_path:item.advance_proof_path ? item.advance_proof_path : '',
                system_adjustment:item.system_adjustment,
                sales_name: `${item?.bp_name != " " ? item.bp_name+' - BP' : (item.sales_name != " " ?  item.sales_name+' - SM' : ' - ')}`,
                reconcile_data:item?.reconcile_data ? item?.reconcile_data : null
              };
            });
            setDisbursementDetail(finalData);
            setState({
              ...state,
              totalItems: res.data.count.count,
            });
            if(!paginator)
              {
                if(exportType == 'pdf'){
            const data=res.data.result;
            const doc = new jsPDF("landscape", "mm", "a2");
      
            let width = doc.internal.pageSize.width;
            let height = doc.internal.pageSize.height;
        
            const img = new Image();
            img.src = "assets/logo.png";
            img.onload = function () {
              const canvas = document.createElement("canvas");
              const ctx = canvas.getContext("2d");
              canvas.width = img.width;
              canvas.height = img.height;
              ctx.globalAlpha = 0.2;
  
             ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
              const watermarkBase64 = canvas.toDataURL("image/png");
  
              const maxWidth = width * 0.5;
              const maxHeight = height * 0.5; 
              let newWidth = img.width;
              let newHeight = img.height;
  
              if (newWidth > maxWidth || newHeight > maxHeight) {
                const widthRatio = maxWidth / newWidth;
                const heightRatio = maxHeight / newHeight;
                const scale = Math.min(widthRatio, heightRatio);
        
                newWidth = newWidth * scale;
                newHeight = newHeight * scale;
            }
        
            // Calculate the center position
            let xPos = (width - newWidth) / 2;
            let yPos = (height - newHeight) / 2;
              let column=columns.filter((col)=>{
                 if(col != null)
                  return col;
              })
              const title = "Disbursement list";
              doc.setFontSize(18);
              doc.setFont("helvetica");
              const textWidth = doc.getTextDimensions(title).w;
              doc.text(title, (width - textWidth) / 2, 10);
              // doc.addImage(img, 'PNG', width / 2 - 100, height / 2 - 100, 200, 200, undefined, 'SLOW');
              autoTable(doc, {
                head: [column.map((col) =>        
                       col.label
                    )],
                body: data.map((product, index) =>
                  column.map((col) => {                  
                    if ( col.id === "SRNO" ) {
                      return index + 1;
                    } else if(col != null) {
                      return product[col.id];
                    }
                  })
                ),
                styles: {
                  fontSize: 10,
                  cellPadding: 3,
                  textColor: [0, 0, 0],
                },
                headStyles: {
                  fillColor: [52, 152, 219],
                  textColor: [255, 255, 255],
                },
                margin: { top: 15 },
                theme: "grid",
                didDrawPage: function (data) {
                  doc.addImage(
                    watermarkBase64,
                    "PNG",
                    xPos, yPos, newWidth, newHeight
                  );
                },
              });
              doc.save(`ReconcileCase_${newdate}.pdf`);
              
            };
            setPaginator(true);
          }
          else{
            const data = res.data.result;
            const modifiedData = data.map((product, index) => {
              // Create a row with custom logic for "SRNO"
              const row = columns.map(col => {
                if (col.id === "SRNO") {
                  return index + 1; // Add serial number
                } else if(col != null) {
                  return product[col.id]; // Add product data
                }
              });
          
              return row;
            });
            const csvRows = [];
            csvRows.push(columns.map(col => col.label));
            modifiedData.forEach(row => {
              csvRows.push(
                row
              );
            });
            const csvContent = 'data:text/csv;charset=utf-8,' + csvRows.join('\n');
            const encodedUri = encodeURI(csvContent);
            const link = document.createElement('a');
            link.setAttribute('href', encodedUri);
            link.setAttribute('download', `Disbursement_${newdate}.csv`);
            document.body.appendChild(link); // Required for FF
            link.click();
            document.body.removeChild(link);
          }
        
        }
          setLoader(false);      
    })
        .catch((error) => {
          setDisbursementDetail([]);
        });
    };
    const dt = useRef(null);
  
    const onPage = (event) => {
      let filter = { ...lazyState };
      filter = event;
      filter["search"] = lazyState.search;
      setlazyState(filter);
    };
  
    const onFilter = (event) => {
      event["first"] = 0;
      event["page"] = 0;
      const dismonthValue = event.filters?.dismonth?.value;
  
      if (
        dismonthValue &&
        dismonthValue.month === null &&
        dismonthValue.year === null
      ) {
        event.filters.dismonth.value = null;
      }
  
      setlazyState(event);
    };
    const targetRef = useRef();
  
    const exportPdf = () => {
      setPaginator(false);
      getData();   
      setExportType('pdf');
  
    };
  
    const exportCSV = () => {
      setPaginator(false);
      getData();   
      setExportType('csv');
      
    };
    const handleKeyDown = (event) => {
      if (event.key === "Enter") {
        onGlobalFilterChange(searchValue);
      }
    };
  
    const handleSearchClick = () => {
      onGlobalFilterChange(searchValue);
    };
  
    const handleInputChange = (event) => {
      setSearchValue(event.target.value);
    };
  
    const onGlobalFilterChange = (e) => {
      const value = e;
      let filter = { ...lazyState };
  
      filter["search"] = value;
  
      setlazyState(filter);
    };
    const renderHeader = () => {
      return (
        <div className="flex justify-end justify-space-between">
          <div>
            {/* <Button
              type="button"
              icon="pi pi-file-pdf"
              rounded
              onClick={exportPdf}
              data-pr-tooltip="PDF"
            /> */}
            <Button label="Export" icon="pi pi-upload" className="p-button" onClick={(e) => op.current.toggle(e)}>
          <OverlayPanel ref={op}>
                  <option style={{ margin: '10px 0',cursor: 'default' }} value='bronze' onClick={exportPdf}>Pdf </option>
                      { (permission[PERMISSION_TYPE.ADMIN] || permission[PERMISSION_TYPE.STAFF] || permission[PERMISSION_TYPE.STAFF_OPS]) && (<option style={{ margin: '10px 0',cursor: 'default' }} value='silver' onClick={() => exportCSV()}>Csv</option>
                                          )}
                                          </OverlayPanel>
          </Button>
          </div>
          <div>
            <span>
              <InputText
                className="mr-2 ml-3"
                value={searchValue}
                onChange={handleInputChange}
                onKeyDown={handleKeyDown}
                placeholder="Keyword Search"
              />
              <Button icon="pi pi-search" onClick={handleSearchClick} />
            </span>
          </div>
        </div>
      );
    };
    const header = renderHeader();
    const menuRef = useRef(null);
    const selectedRowData = useRef(null);
   
    const handleMenuItemClick = (itemLabel) => {
      
      if (itemLabel === "View Data") {

        setDataModal(true)

        const oldData = selectedRowData.current.reconcile_data || {};
  const newData = selectedRowData.current || {};
  const keys = Object.keys(oldData);

  // Prepare data for table
  const tableData = keys.map((key) => {
    const oldValue = parseFloat(oldData[key]) || 0;
    const newValue = parseFloat(newData[key]) || 0;
    return {
      key,
      oldValue: oldValue.toFixed(2),
      newValue: newValue.toFixed(2),
      diff: (oldValue - newValue ).toFixed(2),
    };
  })
  setData(tableData)

         }   
        
         if (itemLabel === "Review case") {
          setReasonPopUp(true)
        }
        };
  
    const handleEllipsisClick = (event, rowData) => {
      event.stopPropagation(); // Prevent event from bubbling up
      selectedRowData.current = rowData;
      if (menuRef.current) {
        menuRef.current.toggle(event);
      }
    };
    const actionBodyTemplate = (rowData) => {
      return (
        <div>
          <img
            src="../assets/menu-cirlce.svg"
            alt="menu-icon"
            style={{ cursor: "pointer" }}
            onClick={(e) => handleEllipsisClick(e, rowData)}
          />
          <TieredMenu model={generateMenuItems()} popup ref={menuRef} />
        </div>
      );
    };
  
    const generateMenuItems = () => {
      const menuItems = [];
      // if (permission[PERMISSION_TYPE.ADMIN]) {
      menuItems.push({
        label: "View Data",
        command: () => handleMenuItemClick("View Data"),
      });
      menuItems.push({
        label: "Review case",
        command: () => handleMenuItemClick("Review case"),
      });
      // }
  
     
  
      return menuItems;
    };
    const accpetLose=()=>{
      let error={...reasonInfo}

     if(reasonInfo.reason === ""){
       error={...reasonInfo , reason_error:'Please provide Reason'}
     }
     setReason(error)
     const data={
       loss_approved: true,
       reason: reasonInfo.reason
     };
     if(error.reason_error === null ){
       ApiPut(`disbursement/review-reconcile-case?id=${selectedRowData.current.id}`,data)
       .then((res: any) => {
         setReasonPopUp(false)
         setReason(reasonData)
         getData()
         setLoader(false);
         toast.success(res.data, {
             position: "top-right",
             theme: "colored"
           })
       })
     }
     
   }
    useEffect(() => {
      Promise.all([
        getData(),
        getUser(),
        getOptionsData(),
        
      ])
        .then(() => {
          setTimeout(() => {
            setLoader(false);
          }, 250);
        })
        .catch((error) => {
          setLoader(false);
        });
    }, [lazyState,paginator])  
  

  

    return (
      <div className="overflow-x-hidden">
        {/* <Sidebar />
  <Header /> */}
        {loader ? (
          <>
            {/* START::OVERLAY */}
            <div className="fixed inset-0 w-full h-screen bg-white z-[9999] flex items-center justify-center">
              <img src="assets/logo.svg" />
            </div>
            {/* END::OVERLAY */}
          </>
        ) : (
          <>
            {/* <main className="lg:pl-[280px] pt-[92px]"> */}
            <div className="p-4 sm:p-9 space-y-5">
              {/* START::DASHBOARD HEADER */}
              <div className="flex flex-wrap gap-5 items-center justify-between">
                <div className="md:flex w-full lg:w-[unset] items-center lg:justify-between md:space-x-9 space-y-5 md:space-y-0">
                  <h1 className="text-[22px] text-light-gray font-semibold">
                    ReconcileCase Data
                  </h1>
               </div> 
              
            </div>
            <DataTable
                ref={dt}
                value={disbursementDetail}
                lazy
                dataKey="id"
                showGridlines
                paginator={paginator}
                first={lazyState.first}
                filters={lazyState.filters}
                onFilter={onFilter}
                rows={lazyState.rows}
                rowsPerPageOptions={[10, 20, 30, 40, 50]}
                totalRecords={totalRecords}
                onPage={onPage}
                loading={loader}
                size={"small"}
                scrollable
                scrollHeight="600px"
                className="custom-datatable"
                header={header}
                emptyMessage="No records found."
                
              >
                <Column field="SRNO" header="SR.NO." />
                {permission[PERMISSION_TYPE.ADMIN] || permission[PERMISSION_TYPE.STAFF_OPS]  ? (
                <Column
                  field="sales_name"
                  header="SM/BP Name"
                  showFilterMatchModes={false}
                  filterMenuStyle={{ minWidth: "14rem" }}
                  filter
                  filterElement={salesNameFilterTemplate}
                />
              ) : null}
                {permission[PERMISSION_TYPE.ADMIN] || permission[PERMISSION_TYPE.STAFF_OPS] ||
                permission[PERMISSION_TYPE.STAFF] || permission[PERMISSION_TYPE.BOOKING_PARTNER] ? (
                  <Column
                    field="DsaName"
                    header="DSA Name"
                    showFilterMatchModes={false}
                    filterMenuStyle={{ minWidth: "14rem" }}
                    filter
                    filterElement={dsaNameFilterTemplate}
                  />
                ) : null}
                <Column field="BorrowerName" header="Borrower Name" />
                <Column field="Contact_Person_Name" header="Contact Person" />
                <Column field="Loanamount" header="Loan Amount" />
                <Column
                  field="Financer"
                  header="Financer"
                  showFilterMatchModes={false}
                  filterMenuStyle={{ minWidth: "14rem" }}
                  filter
                  filterElement={financersFilterTemplate}
                />
                <Column
                  field="Product"
                  header="Product"
                  showFilterMatchModes={false}
                  filterMenuStyle={{ minWidth: "14rem" }}
                  filter
                  filterElement={productsFilterTemplate}
                />
                <Column
                  field="dismonth"
                  header="Disbursement Month"
                  showFilterMatchModes={false}
                  showApplyButton={false}
                  showClearButton={false}
                  filterMenuStyle={{ minWidth: "14rem" }}
                  filter
                  filterElement={DismonthFilterTemplate}
                />
                <Column field="Connectorname" header="Connector Name" />
                <Column field={permission[PERMISSION_TYPE.CONNECTOR] ? 'connector_rate': "Userpayout"} header="Grid Payout %" />
                {!permission[PERMISSION_TYPE.CONNECTOR] &&
                <Column field="connector_rate" header="Connector Payout %" />}
                {!permission[PERMISSION_TYPE.CONNECTOR] &&
                <Column field="adjusted_payout_per" header="Adjusted Payout % " /> 
                }
                {!permission[PERMISSION_TYPE.CONNECTOR] &&
                <Column field="adjustment_amount" header="Adjustment Amount" />
                }
                <Column field="subvention_per" header="Subvention %" />
                <Column field='subvention' header='Subvention'></Column>
                {permission[PERMISSION_TYPE.ADMIN] && <Column field='system_adjustment' header='System Adjustment'></Column>}
                <Column field={permission[PERMISSION_TYPE.CONNECTOR] ? 'connector_gross_payout': "gross_payout"} header="Gross Payout" />
                <Column  field={permission[PERMISSION_TYPE.CONNECTOR] ? 'connector_tds_amount': "tds_amount"} header="TDS Payout" />
                <Column  field={permission[PERMISSION_TYPE.CONNECTOR] ? 'connector_gst_amount': "gst_amount"} header="GST Payout" />
                <Column  field={permission[PERMISSION_TYPE.CONNECTOR] ? 'connector_net_amount_payable': "net_amount_payable"} header="Net Payout" />
                <Column
                  field="Paymentstatus"
                  header="Payment Status"
                  showFilterMatchModes={false}
                  filterMenuStyle={{ minWidth: "14rem" }}
                  filter
                  filterElement={paymentStatusesFilterTemplate}
                  body={(rowData) => {
                    let color;
                    switch (rowData.co_status) {
                      
                      case 1:
                        color = "red";
                        break;
                      default:
                        color = "black";
                    }
                    return <span style={{ color }}>{rowData.Paymentstatus}</span>;
                  }}
                />
                <Column
                  field="Advancerequest"
                  header="Advance Request"
                  showFilterMatchModes={false}
                  filterMenuStyle={{ minWidth: "14rem" }}
                  filter
                  filterElement={advanceRequestFilterTemplate}
                />
                <Column
                  field="Advancestatus"
                  header="Advance Status"
                  showFilterMatchModes={false}
                  filterMenuStyle={{ minWidth: "14rem" }}
                  filter
                  filterElement={advanceStatusesFilterTemplate}
                />
                <Column
                field="Action"
                header="Action"
                body={actionBodyTemplate}
              />
              </DataTable>  
              <Dialog header="Values" visible={dataModal} style={{ width: '50vw' }} onHide={() => {if (!dataModal) return; setDataModal(false); }}>
    <p className="m-0">
    <div>
                    <table style={{ width: "100%", borderCollapse: "collapse" }}>
        <thead>
          <tr>
            <th style={{ border: "1px solid black", padding: "8px" }}>Field</th>
            <th style={{ border: "1px solid black", padding: "8px" }}>Old Value</th>
            <th style={{ border: "1px solid black", padding: "8px" }}>New Value</th>
            <th style={{ border: "1px solid black", padding: "8px" }}>Difference</th>
          </tr>
        </thead>
        <tbody>
          {data?.map((row, index) => (
            <tr key={index}>
              <td style={{ border: "1px solid black", padding: "8px" }}>{row.key}</td>
              <td style={{ border: "1px solid black", padding: "8px" }}>{row.oldValue}</td>
              <td style={{ border: "1px solid black", padding: "8px" }}>{row.newValue}</td>
              <td style={{ border: "1px solid black", padding: "8px" }}>{row.diff}</td>
            </tr>
          ))}
        </tbody>
      </table>
                </div>
          </p>
</Dialog>
            </div>
            <div
            className={`tw-modal ${
              reasonPopup ? "flex" : "hidden"
            } items-start `}
          >
            <div className="tw-modal-content ">
              <div className="tw-modal-title">
                <div className="text-2xl font-bold">Set Reason For Acceptance of lose</div>
                <button onClick={() => {setReasonPopUp(false)
                  setReason(reasonData)}
                }>
                  <img src="assets/close-outline.svg" alt="Close" />
                </button>
              </div>
              <label
                htmlFor="dropdown"
                className="block mb-2 text-sm font-medium"
              >
                {/* Level: */}
              </label>
              <div className="tw-modal-body">
                <div className="space-y-1">
                <label htmlFor="reason" className={`input-label ${
                        reasonInfo.reason_error ? "text-red-500" : ""
                      }`}>
                        Enter Reason :
                      </label>
                      <div id="reason" className={`input-wrap ${
                        reasonInfo.reason_error ? "border-red-500" : ""
                      }`}>
                        <img src="assets/firm.svg" alt="" />
                        <input
                          type="text"
                          name="reason"
                          value={reasonInfo.reason}
                          onChange={(e: any) => {
                           setReason({...reasonInfo , reason:e.target.value, reason_error:null})
                          }}
                          className="w-full text-sm"
                          placeholder="Enter Reason"
                        />
                      </div>
                  <p
                    id="level-error"
                    className="text-red-500 text-sm"
                  >
                    {reasonInfo.reason_error}
                  </p>
                </div>
              </div>
              

              <div className="tw-modal-footer">
                <Button
                  icon="pi pi-check"
                  // raised
                  severity="info"
                  onClick={() => {
                    accpetLose()
                  }}
                >
                  <span style={{ marginLeft: "8px" }}> Save</span>
                </Button>
                <Button
                  icon="pi pi-times"
                  raised
                  severity="danger"
                  onClick={() => {
                   setReasonPopUp(false)
                   setReason(reasonData)

                  }}
                >
                  {" "}
                  <span style={{ marginLeft: "8px" }}> Cancel</span>
                </Button>
              </div>
            </div>
          </div>
          </>
        )}
      </div>
    );
  };
  
  export default ReconcileCaseData;
  