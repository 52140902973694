import { useState, useEffect, useRef } from "react";
import { STATE } from "../../constants";
import { ApiGet, ApiPut, ApiPost } from "../../helper/API/ApiData";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import { PERMISSION_TYPE, userPermission } from "../../helper/permission";
import { RootState } from "../../redux/store";
import { useSelector } from "react-redux";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { TieredMenu } from "primereact/tieredmenu";
import { FilterMatchMode } from "primereact/api";
import { MultiSelect } from "primereact/multiselect";
import { Button } from "primereact/button";
import { InputSwitch } from "primereact/inputswitch";
import { Dialog } from "primereact/dialog";
import axios from "axios";
import { FileUpload, FileUploadHeaderTemplateOptions, FileUploadSelectEvent } from "primereact/fileupload";
import { ProgressBar } from "primereact/progressbar";
import { Paginator } from "primereact/paginator";
import { InputNumber } from 'primereact/inputnumber';
import { RadioButton } from "primereact/radiobutton";

function UserDatabase() {
  //const [state, setState] = useState(STATE.DEFAULT_STATE);
  const [loader, setLoader] = useState(true);
  const [addOpen, setAddOpen] = useState(false);
  const [addBpOpen, setAddBpOpen] = useState(false);
  const [addOpenType, setAddOpenType] = useState("");
  const [staffData, setStaffData] = useState([]);
  const [bookingPartnerData, setBookingPartnerData] = useState([]);
  const [optiondata, setOptionData] = useState([]);
  const [updateManagerOpen, setUpdateManagerOpen] = useState(false);
  const [selectedData,setSelectedData]=useState({
    id_error: null,
    id: '',
    type:''
  })
  const user_data = useSelector(
    (state: RootState) => state?.authUser?.userData?.user
  );
  const [selected, setSelected] = useState<readonly string[]>([]);
  const [userId, setUserid] = useState("");
  const [editPayoutOpen, seteditPayoutOpen] = useState(false);
  const [permission, setPermission] = useState<any>({});
  const [searchValue, setSearchValue] = useState("");
  const [kycPopUp, setKycPopUp] = useState(false);
  const [kycDetails, setkycDetails] = useState({});
  const data={
    relationship_manager: "",
    id: '',
    relationship_manager_error: null,
  };
  const [formData, setFormData] = useState(data);
  const databp={
    booking_partner_error: null,
    id: null,
    booking_partner: "",
  }
  const [formBpData, setFormBpData] = useState(databp);

  const [editUserPopUp, setEditUserPopUp] = useState(false);
  const editData={
    first_name: "",
    middle_name: "",
    last_name: "",
    firm_name: "",
    mobile: "",
    pan_no: "",
    state: "",
    city: "",
    postal_code: "",
    gst_no: "",
    email: "",
    created_at: "",
    rate_logic: "",
    rate_share: null,
    first_name_error: null,
    last_name_error: null,
    firm_name_error: null,
    mobile_error: null,
    pan_no_error: null,
    state_error: null,
    city_error: null,
    postal_code_error: null,
    gst_no_error:null,
    account_number:'',
    ifsc_code:'',
    account_number_error:null,
    ifsc_code_error:null,
    rate_share_error:null,
    rate_logic_error:null
  }
  const [editUserForm, setEditUserForm] = useState(editData);
  const wdata={
    amount:'',
    reason:'',
    amount_error:null,
    reason_error:null,
    dsadisbursement_id:null,
    dsadisbursement_error:null
  }
  const [walletledgerForm, setWalletledgerForm] = useState(wdata);
  const [dsaDisbursementList,setDsaDisbursementList]=useState(null);
  const [addCase,setAddCase]=useState(false);
  const [showDialog, setShowDialog] = useState(false); 
  const [activeStatus, setActiveStatus] = useState(false);
  const [cityOptions, setCityOptions] = useState(null);
  const [stateOptions, setStateOptions] = useState(null);
  const [firmNameOptions, setFirmNameOptions] = useState(null);
  const [fullNameOptions, setFullNameOptions] = useState(null);
  const [relationshipManagersOptions, setRelationshipManagersOptions] =
    useState(null);
  const [selectedUser, setSelectedUser] = useState(null);

  const [totalRecords, setTotalRecords] = useState(0);
  const [loading, setLoading] = useState(false);
  const [userData, setUserData] = useState(null);
  const [selectedUsers, setSelectedUsers] = useState(null);
  const [lazyState, setlazyState] = useState({
    first: 0,
    rows: 10,
    page: 0,
    search: "",
    filters: {
      Name: { value: null, matchMode: FilterMatchMode.IN },
      FirmName: { value: null, matchMode: FilterMatchMode.IN },
      relationship_manager: { value: null, matchMode: FilterMatchMode.IN },
      City: { value: null, matchMode: FilterMatchMode.IN },
      State: { value: null, matchMode: FilterMatchMode.IN },
      Contact: { value: null, matchMode: FilterMatchMode.CONTAINS },
      Email: { value: null, matchMode: FilterMatchMode.CONTAINS },
      Role: { value: null, matchMode: FilterMatchMode.IN },
      KycStatus: { value: null, matchMode: FilterMatchMode.IN },
      total_balance:{value: null, matchMode: FilterMatchMode.IN }
    },
  });
  const [addWalletLedger, setAddWalletLedger] = useState(false);
  const [rateType,setRateType]=useState('custom')
  const [changeDoc,setChangeDoc]=useState(false)
  const [docName,setDocName]=useState('');
  const [fileUpload1Size, setFileUpload1Size] = useState(0);
  const fileUploadRef1 = useRef<FileUpload>(null);
  const [fileUpload1Files, setFileUpload1Files] = useState([]);
  const [kycForm,SetKycForm]=useState(null )
  useEffect(() => {
    Promise.all([loadUsersData(), getStaffData(), getOptionsData(),getBookingPartnerData(),getDSAOptionsData()])
      .then(() => {
        setTimeout(() => {
          setLoader(false);
        }, 250);
      })
      .catch((error) => {
        setLoader(false);
      });
  }, [lazyState]);

  const getDsaDirsementlist=(id)=>{
    setLoader(true)
    ApiGet(`walletLedger/get-dsadisbursement-list?id=${id}`)
        .then((res: any) => {
          let finalData = res.data.result.map((item: any, index: number) => {
            return {
              borrower_name: item?.borrower_name,
              bank_name: item?.bank_name,
              product: item?.product,
              year: item?.year,
              month: item?.month,
              user_id: item?.user_id,
              dsadisbursementid: item?.dsadisbursementid,
            };
          });
          setDsaDisbursementList(finalData);
          setLoader(false)
  
        })
        .catch((error) => {
          setDsaDisbursementList([]);
          setLoader(false)
     
        });
    }
  
  const getOptionsData = () => {
    setLoading(true);
    ApiGet(`options/userDataBase`).then((res: any) => {
      const cities = convertToObjectArray(res.data.city);
      const states = convertToObjectArray(res.data.state);
      const firmNames = convertToObjectArray(res.data.firm_name);
      const relationshipManagers = convertToObjectArray(
        res.data.relationship_manager
      );

      setCityOptions(cities);
      setStateOptions(states);
      setFirmNameOptions(firmNames);
      setRelationshipManagersOptions(relationshipManagers);
    }).catch((error)=>{
      //  console.log(error)
    });
  };

  const getDSAOptionsData = () => {
    setLoading(true);
    ApiGet(`user/option-users`).then((res: any) => {
      const fullNames = convertToObjectArray(res.data.users);
      setFullNameOptions(fullNames);
    }).catch((error)=>{
      //  console.log(error)
    });
  };


  const convertToObjectArray = (obj) => {
    return Object.keys(obj).map((key) => ({
      label: obj[key],
      value: key,
    }));
  };

  const loadUsersData = () => {
    const modifiedLazyState = { ...lazyState, limit: lazyState.rows };
    delete modifiedLazyState.rows;
    delete modifiedLazyState["multiSortMeta"];
    delete modifiedLazyState["sortField"];
    delete modifiedLazyState["sortOrder"];

    // Function to replace empty arrays in the "value" field with null
    const replaceEmptyValueArrays = (obj) => {
      for (const key in obj) {
        if (
          key === "value" &&
          Array.isArray(obj[key]) &&
          obj[key].length === 0
        ) {
          obj[key] = null;
        } else if (typeof obj[key] === "object") {
          replaceEmptyValueArrays(obj[key]);
        }
      }
      return obj;
    };

    // Call the function to replace empty arrays in the "value" field with null
    const finalModifiedLazyState = replaceEmptyValueArrays(modifiedLazyState);
    finalModifiedLazyState.page++;

    ApiPost(`user/get-all-users`, finalModifiedLazyState).then((res: any) => {
      setTotalRecords(Number(res.data.count[0].count));
      let finalData = res.data.result.map((item: any, index: number) => {
        return {
          Userid: item._id,
          SRNO: (lazyState.first) + index + 1 ,
          Name: item.full_name,
          FirmName: item.firm_name,
          FirstName: item?.first_name,
          MiddleName: item?.middle_name,
          LastName: item?.last_name,
          Email: item.email,
          City: item.city,
          Role: item.role,
          KycStatus: `${item.profile_completed ? "Completed" : "Pending"}`,
          State: item.state,
          Contact: item.mobile,
          relationship_manager_id: item?.relationship_manager_id ,
          booking_partner_id: item?.booking_partner_id,
          relationship_manager: `${item?.booking_partner != "  " ? item.booking_partner+' - BP' : (item.relationship_manager != "  " ?  item.relationship_manager+' - RM' : ' - ')}`,
          Rname: item?.rfname ?? "-",
          GSTNumber:item.gst_no ?? "" ,
          PANNumber: item?.pan_no,
          CreatedAt: item?.created_at,
          PostalCode: item?.postal_code,
          Status:item?.status,
            Level:item?.level??'-',
            Account_number:item.account_number,
            Ifsc:item.ifsc,
            kyc_id:item.kyc_id,
            rate_logic:item.rate_logic,
            rate_share:item.rate_logic == 'custom' ? null : (item.rate_share)*100,
            total_balance:item.total_balance == null ? '0.00' : item.total_balance
        };
      });
     setUserData(finalData);
      setLoading(false);
    }).catch((error)=>{
      //  console.log(error)
    });
  };

  const onPage = (event) => {
    let filter = { ...lazyState };
    filter = event;
    filter["search"] = lazyState.search;
    setlazyState(filter);
  };

  const onFilter = (event) => {
    event["first"] = 0;
    event["page"] = 0;
    setlazyState(event);
  };

  let navigate = useNavigate();
  useEffect(() => {
    const tempPermission = {
      [PERMISSION_TYPE.ADMIN]: userPermission(user_data, PERMISSION_TYPE.ADMIN),
      [PERMISSION_TYPE.USER]: userPermission(user_data, PERMISSION_TYPE.USER),
      [PERMISSION_TYPE.STAFF]: userPermission(user_data, PERMISSION_TYPE.STAFF),
      [PERMISSION_TYPE.STAFF_OPS]: userPermission(user_data, PERMISSION_TYPE.STAFF_OPS),
      [PERMISSION_TYPE.BOOKING_PARTNER]: userPermission(user_data, PERMISSION_TYPE.BOOKING_PARTNER),
    };
    setPermission(tempPermission);
  }, [user_data]);

  const getKycById = async (id: any) => {


    await ApiGet(`kyc/get-kycdetails-byid/${id}`).then((res: any) => {
      if (res.success) {
        let temp = res.data.kycDetails;
        setkycDetails(temp);
      } else {
        toast.error(res.error, {
          position: "top-right",
          theme: "colored",
        });
      }
    }).catch((error)=>{
      //  console.log(error)
    });
  };

  const getDowloadLink = async (path: any) => {
    if (path != undefined) {
      await ApiPost(`kyc/get-image-dowload-link`, {
        path,
      }).then((res: any) => {
        const linkElement = document.createElement("a");
        linkElement.href = res.data.url;
        linkElement.click();
      }).catch((error)=>{
        //  console.log(error)
      });
    } else {
      toast.error("Doc not uploaded", {
        position: "top-right",
        theme: "colored",
      });
    }
  };

  const getStaffData = () => {
    ApiGet(`staff/option-staff`)
      .then((res: any) => {
        let finalData = res.data.result.map((item: any, index: number) => {
          return {
            name: item?.first_name + " " + item?.last_name,
            email: item?.email,
            id: item?._id,
            department:item?.department
          };
        });
        setStaffData(finalData);
      })
      .catch((error) => {
        setStaffData([]);
      });
  };

  const getBookingPartnerData = () => {
    ApiGet(`staff/option-bookingPartner`)
      .then((res: any) => {
        let finalData = res.data.result.map((item: any, index: number) => {
          return {
            name: item?.full_name,
            email: item?.email,
            id: item?._id,
          };
        });
        setBookingPartnerData(finalData);
      })
      .catch((error) => {
        setBookingPartnerData([]);
      });
  };

  const addRelation = () => {
    let error = { ...formData };
    if (!formData.relationship_manager) {
      error = {
        ...error,
        relationship_manager_error: "Please select Relationship Manager",
      };
    }
    setFormData(error);
    if (!error.relationship_manager_error)
      ApiPut(`user/change-relationship?type=${addOpenType}`, {
        booking_partner:null,
        relationship_manager: formData.relationship_manager,
        data:
          selectedUsers && selectedUsers.length > 0
            ? selectedUsers.map((obj) => obj.Userid)
            : selectedUser?.split(","),
      })
        .then((res: any) => {
          setAddOpen(false);
          if (res.success) {
            toast.success(res.data.message, {
              position: "top-right",
              theme: "colored",
            });
          }
          loadUsersData();
          setSelectedUsers([]);
          setSelected([]);
          setFormData(data);
          // loadUsersData();
        })
        .catch((error) => {
          // setStaffData([]);
          // setAddOpen(false);
        });
        setAddOpen(false);
        setAddOpenType("")
        setSelectedUsers([]);
          setSelected([]);
          setFormData({
            relationship_manager: "",
            id: null,
            relationship_manager_error: "",
          });
          loadUsersData();
 
  };

  const addBookingPartner = () => {
    let error = { ...formBpData };
    if (!formBpData.booking_partner) {
      error = {
        ...error,
        booking_partner_error: "Please select Booking Partner",
      };
    }
    setFormBpData(error);
    if (!error.booking_partner_error)
      ApiPut(`user/change-relationship?type=${addOpenType}`, {
        relationship_manager:null,
        booking_partner:formBpData.booking_partner,
        data:
          selectedUsers && selectedUsers.length > 0
            ? selectedUsers.map((obj) => obj.Userid)
            : selectedUser?.split(","),
      })
        .then((res: any) => {
          setAddBpOpen(false);
          if (res.success) {
            toast.success(res.data.message, {
              position: "top-right",
              theme: "colored",
            });
          }
          loadUsersData()
          setAddOpenType("")
          setSelectedUsers([]);
          setSelectedUser(null);

          setSelected([]);
          setFormBpData(databp);
          // loadUsersData();
        })
        .catch((error) => {
          // setStaffData([]);
          // setAddOpen(false);
        });
        setAddBpOpen(false);
        setSelectedUsers([]);
        setSelectedUser(null);

          setSelected([]);
          setFormBpData(databp);
          loadUsersData();
 
  };
  const updateRole = () => {
    let error = { ...selectedData };
    if (selectedData.id == '') {
      error = {
        ...error,
        id_error: "Please select Booking Partner",
      };
    }
   
    setSelectedData(error);
    if (error.id_error == null)
      ApiPut(`user/change-relationship?type=change`, {
        booking_partner:selectedData.type == 'bp' ? selectedData.id : null,
        relationship_manager:selectedData.type == 'rm' ? selectedData.id : null,
        data:selectedUser
      })
        .then((res: any) => {
          setAddBpOpen(false);
          if (res.success) {
            toast.success(res.data.message, {
              position: "top-right",
              theme: "colored",
            });
          }
          loadUsersData()
          setUpdateManagerOpen(false)
                  setSelectedData({
                    id_error: null,
                    id: '',
                    type:''
                  });
                  setOptionData([])
          // loadUsersData();
        })
        .catch((error) => {
          loadUsersData()
          setUpdateManagerOpen(false)
                  setSelectedData({
                    id_error: null,
                    id: '',
                    type:''
                  });
                  setOptionData([])
          // setStaffData([]);
          // setAddOpen(false);
        });
        
 
  };

  const onGlobalFilterChange = (e) => {
    const value = e;
    let filter = { ...lazyState };

    filter["search"] = value;

    setlazyState(filter);
  };

  const renderHeader = () => {
    return (
      <div className="flex justify-end">
        <span>
          <InputText
            className="mr-2"
            value={searchValue}
            onChange={handleInputChange}
            onKeyDown={handleKeyDown}
            placeholder="Keyword Search"
          />
          <Button icon="pi pi-search" onClick={handleSearchClick} />
        </span>
      </div>
    );
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      onGlobalFilterChange(searchValue);
    }
  };

  const handleSearchClick = () => {
    onGlobalFilterChange(searchValue);
  };

  const handleInputChange = (event) => {
    setSearchValue(event.target.value);
  };

  const header = renderHeader();

  const menuRef = useRef(null);
  const selectedRowData = useRef(null);

  const handleMenuItemClick = (itemLabel) => {
    if (itemLabel === "Edit") {
      setEditUserPopUp(true);
      setSelectedUser(selectedRowData.current?.Userid);
      setEditUserForm({
        ...editUserForm,
        first_name: selectedRowData.current?.FirstName,
        middle_name: selectedRowData.current?.MiddleName,
        last_name: selectedRowData.current?.LastName,
        firm_name: selectedRowData.current?.FirmName,
        email: selectedRowData.current?.Email,
        created_at: selectedRowData.current?.CreatedAt,
        gst_no: selectedRowData.current?.GSTNumber,
        postal_code: selectedRowData.current?.PostalCode,
        mobile: selectedRowData.current?.Contact,
        pan_no: selectedRowData.current?.PANNumber,
        city: selectedRowData.current?.City,
        state: selectedRowData.current?.State,
        account_number: selectedRowData.current?.Account_number,
        ifsc_code: selectedRowData.current?.Ifsc,
        rate_logic: selectedRowData.current?.rate_logic,
        rate_share: selectedRowData.current?.rate_share,
      });
    }
    if (itemLabel === "Add Relationship Manager") {
      if (selectedRowData.current.booking_partner_id != null) {
        toast.error("Booking Partner already assigned!!");
      }else{
        if (selectedRowData.current.relationship_manager_id) {
          toast.error("Relationship manager already assigned!!");
        } else {
          setAddOpen(true);
          setSelectedUser(selectedRowData.current.Userid);
          setFormData({
            ...formData,
            id: selectedRowData.current.Userid,
            relationship_manager_error: "",
          });
        }
      }
      
    } else if (itemLabel === "Edit Payout" || itemLabel === "View Payout") {
      navigate(`/payoutgrid/${selectedRowData.current.Userid}?source=usedatabase&username=${selectedRowData.current.Name}&rate=${selectedRowData.current.rate_logic == 'sharing' ? true : false}`);
    } else if (itemLabel === "Download Kycs") {
      getKycById(selectedRowData.current.Userid);
      setSelectedUser(selectedRowData.current.kyc_id);
      setKycPopUp(true);
    }
    if (itemLabel === "Add Booking Partner") {
      if (selectedRowData.current.relationship_manager_id != null) {
        toast.error("Relationship manager already assigned!!");
      }else{
        if (selectedRowData.current.booking_partner_id) {
          toast.error("Booking Partner already assigned!!");
        } else {
          setAddBpOpen(true);
          setSelectedUser(selectedRowData.current.Userid);
          setFormBpData(databp);
        }
      
      }
        
     
    }
    if (itemLabel === "Change RM/BP") {
      if(selectedRowData.current.relationship_manager_id == null && selectedRowData.current.booking_partner_id == null)   {
        toast.error("RM or BP not assigned!!");

      }   
      else{
      if(selectedRowData.current.relationship_manager_id != null){
        setOptionData(bookingPartnerData)
        setUpdateManagerOpen(true)
        setSelectedUser(selectedRowData.current.Userid);
        setSelectedData({...selectedData,type:'bp'})
      }
      else if(selectedRowData.current.booking_partner_id != null){
        setOptionData(staffData)
        setUpdateManagerOpen(true)
        setSelectedUser(selectedRowData.current.Userid);
        setSelectedData({...selectedData,type:'rm'})

      }
      }  
     
    }

    if (itemLabel === "Add Wallet Ledger") {
      if(selectedRowData.current.Role == 'DSA' ){
        setAddWalletLedger(true);
        setSelectedUser(selectedRowData.current.Userid);
        getDsaDirsementlist(selectedRowData.current.Userid)      
      }else{
        toast.error("Not Allowed!!");

      }
      }
    if (itemLabel === "View Wallet Ledger") {
      navigate("/userdatabase/wallet/" + selectedRowData.current.Userid);
    }


  };

  const handleEllipsisClick = (event, rowData) => {
    event.stopPropagation(); // Prevent event from bubbling up
    selectedRowData.current = rowData;
    if (menuRef.current) {
      menuRef.current.toggle(event);
    }
  };

  const actionBodyTemplate = (rowData) => {
    return (
      <div>
        <img
          src="../assets/menu-cirlce.svg"
          alt="menu-icon"
          style={{ cursor: "pointer" }}
          onClick={(e) => handleEllipsisClick(e, rowData)}
        />
        <TieredMenu model={generateMenuItems()} popup ref={menuRef} />
      </div>
    );
  };
  const statusBodyTemplate = (rowData) => {
    return (
      <div>
        <InputSwitch checked={rowData.Status == 'Active' ? true : false} onChange={() => handleToggle(rowData)} />
      </div>
    );
  };
  const cancelToggle = () => {
    setShowDialog(false);
    setSelectedUser(null);
  };

  const handleToggle = (user) => {
    setActiveStatus(user.Status == 'Active' ? true : false);
    setSelectedUser(user.Userid);
    selectedRowData.current=user;
    setShowDialog(true);
  };
  const confirmToggle = async () => {
    let request;
    if (selectedUser) {
      if(selectedRowData.current.Status == 'Active'){
        request={
          "status":'inactive'
        }
      }
      else{
        request={
          "status":'active'
        }
      }
      
     ApiPut(`user/change-user-status?id=${selectedUser}`, request).then((res: any) => {
        if (res && res.success) {
          toast.success(res.data.message, {
            position: "top-right",
            theme: "colored"
          });
          let newUser= userData.map((userData)=>
            selectedRowData.current.Userid == userData.Userid ? {...userData, Status:activeStatus ? 'Inactive' : 'Active'} : userData
          );
          setUserData(newUser);
          setShowDialog(false);
          setSelectedUser(null);
        }
      }).catch((error)=>{
        //  console.log(error)
      });      
    }
  };

  const generateMenuItems = () => {
    // return [
    //   { label: "Edit", command: () => handleMenuItemClick("Edit") },
    //   {
    //     label: "Add Relationship Manager",
    //     command: () => handleMenuItemClick("Add Relationship Manager"),
    //   },
    //   {
    //     label: "Edit Payout",
    //     command: () => handleMenuItemClick("Edit Payout"),
    //   },
    //   {
    //     label: "Download Kycs",
    //     command: () => handleMenuItemClick("Download Kycs"),
    //   },
    // ];


    const menuItems = [];
    // if (permission[PERMISSION_TYPE.ADMIN]) {
      if (permission[PERMISSION_TYPE.ADMIN] || permission[PERMISSION_TYPE.STAFF_OPS] ){menuItems.push({
      label: "Edit",
      command: () => handleMenuItemClick("Edit"),
    });}
    if (permission[PERMISSION_TYPE.ADMIN] || permission[PERMISSION_TYPE.STAFF_OPS] ){menuItems.push({
      label: "Add Relationship Manager",
      command: () => handleMenuItemClick("Add Relationship Manager"),
    });
    menuItems.push({
      label: "Add Booking Partner",
      command: () => handleMenuItemClick("Add Booking Partner"),
    })
    menuItems.push({
      label: "Change RM/BP",
      command: () => handleMenuItemClick("Change RM/BP"),
    })
  }
  if (permission[PERMISSION_TYPE.ADMIN] ){
    menuItems.push({
    label: "Add Wallet Ledger",
    command: () => handleMenuItemClick("Add Wallet Ledger"),
  })
}
if (permission[PERMISSION_TYPE.ADMIN] || permission[PERMISSION_TYPE.STAFF_OPS] || permission[PERMISSION_TYPE.STAFF]  || permission[PERMISSION_TYPE.BOOKING_PARTNER]){
  menuItems.push({
  label: "View Wallet Ledger",
  command: () => handleMenuItemClick("View Wallet Ledger"),
})
}

    // }
    if (permission[PERMISSION_TYPE.ADMIN] || permission[PERMISSION_TYPE.STAFF_OPS] || permission[PERMISSION_TYPE.STAFF] || permission[PERMISSION_TYPE.BOOKING_PARTNER]) {
      menuItems.push({
        label: "Edit Payout",
        command: () => handleMenuItemClick("Edit Payout"),
      });
    }
    if ( permission[PERMISSION_TYPE.BOOKING_PARTNER] ) {
      menuItems.push({
        label: "View Payout",
        command: () => handleMenuItemClick("View Payout"),
      });
    }
    if (permission[PERMISSION_TYPE.ADMIN] || permission[PERMISSION_TYPE.STAFF_OPS] ) {
    menuItems.push({
      label: "Download Kycs",
      command: () => handleMenuItemClick("Download Kycs"),
    });}

    return menuItems;
  };

  const handlePostalCodeInput = (e) => {
    e.target.value = e.target.value.replace(/[^0-9]/g, "");
  };
  const handleNameInput = (e) => {
    e.target.value = e.target.value.replace(/[^A-Za-z\s]/g, "");
  };
  const handleNumericInput = (e) => {
    e.target.value = e.target.value.replace(/[^0-9]/g, "");
  };
  const postget = async (e: any) => {
    const value = e.target.value;
    setEditUserForm({
      ...editUserForm,
      [e.target.name]: e.target.value,
      postal_code_error: null,
      state_error: null,
      city_error: null,
    });
    if (value.length >= 6) {
      const options = {
        method: "GET",
        url: `https://india-pincode-with-latitude-and-longitude.p.rapidapi.com/api/v1/pincode/${value}`,
        headers: {
          "X-RapidAPI-Key":
            "b2572becadmshbaf647a375178edp179823jsneae8fa2a8334",
          "X-RapidAPI-Host":
            "india-pincode-with-latitude-and-longitude.p.rapidapi.com",
        },
      };
      await axios.request(options).then((res: any) => {
        setEditUserForm({
          ...editUserForm,
          state: res?.data[0].state,
          city: res.data[0].district,
          postal_code: value,
        });
      }).catch((error)=>{
        //  console.log(error)
      });
      // await axios.get(`https://api.postalpincode.in/pincode/${value}`)
      //     .then((res: any) => {
      //         setState(res?.data[0]?.PostOffice[0]?.State);
      //         setCity(res?.data[0]?.PostOffice[0]?.District);
      //     })
    }
  };
  
  const submitWalletLedger = () => {
    let updatedForm = { ...walletledgerForm };
    for (let key in updatedForm) {
      if (typeof updatedForm[key] == "string") {
        updatedForm[key] = updatedForm[key].trim();
      }
    }

    if (walletledgerForm.reason == "") {
      updatedForm = {
        ...updatedForm,
        reason_error: "Please enter a Reason",
      };
    }

    if (walletledgerForm.amount == "") {
      updatedForm = {
        ...updatedForm,
        amount_error: "Please enter an amount",
      };
    } 
    if (addCase == true && walletledgerForm.dsadisbursement_id == null) {
      updatedForm = {
        ...updatedForm,
        dsadisbursement_error: "Please Select a case",
      };
    }    
    setWalletledgerForm(updatedForm);
    if (
      updatedForm.reason_error == null &&
      updatedForm.amount_error == null && updatedForm.dsadisbursement_error == null
    ) {
      let payload={
        reason:updatedForm.reason,
        amount:updatedForm.amount,
        dsadisbursement_id:updatedForm.dsadisbursement_id,
        user_id:selectedUser,
      }
        ApiPost("walletLedger/add-wallet-ledger", payload)
        .then((res: any) => {
          setWalletledgerForm(wdata);
          setAddWalletLedger(false);
          setSelectedUser(null);
          setAddCase(false)

          toast.success('Wallet ledger Added Successfully', {
            position: "top-right",
            theme: "colored",
          });
          setLoader(false)

        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const updateUserData = () => {
    let updatedForm = { ...editUserForm };
    for (let key in updatedForm) {
      if (typeof updatedForm[key] == "string") {
        updatedForm[key] = updatedForm[key].trim();
      }
    }
    let namePattern = /^[A-Za-z\s]+$/;
    let mobilePattern = /^\+?[0-9]{10,}$/;
    let postalCodePattern = /^\d{6}$/;
    const ifsc_pattern = /^[A-Z]{4}0[A-Z0-9]{6}$/;
    const bank_account_pattern = /^[0-9]{8,17}$/;

    //GST validation
   
    //PAN validation
    let panVal = editUserForm.pan_no;
    let firstFivePattern = /^[A-Za-z]{5}/;
    let firstFive = panVal.substring(0, 5);
    let sixtoNinePattern = /^[0-9]{4}/;
    let sixtoNine = panVal.substring(5, 9);
    let lastValPattern = /^[A-Za-z]{1}/;
    let lastVal = panVal.substring(9, 10);

    if (!editUserForm.first_name) {
      updatedForm = { ...updatedForm, first_name_error: "Please enter a first name" };
    }
    if (editUserForm.first_name && !namePattern.test(editUserForm.first_name)) {
      updatedForm = { ...updatedForm, first_name_error: "Please enter a valid name" };
    }

    if (!editUserForm.last_name) {
      updatedForm = { ...updatedForm, last_name_error: "Please enter last name" };
    }
    if (editUserForm.last_name && !namePattern.test(editUserForm.last_name)) {
      updatedForm = { ...updatedForm, last_name_error: "Please enter a valid name" };
    }

    if (!editUserForm.firm_name) {
      updatedForm = { ...updatedForm, firm_name_error: "Please enter a firm name" };
    }
    if (editUserForm.firm_name && !namePattern.test(editUserForm.firm_name)) {
      updatedForm = { ...updatedForm, firm_name_error: "Please enter a valid first name" };
    }

    if (!editUserForm.mobile) {
      updatedForm = { ...updatedForm, mobile_error: "Please enter a mobile" };
    }
    if (editUserForm.mobile && !mobilePattern.test(editUserForm.mobile)) {
      updatedForm = { ...updatedForm, mobile_error: "Please enter a valid mobile" };
    }
    if (editUserForm.account_number == "") {
      updatedForm = {
        ...updatedForm,
        account_number_error: "Please Enter Bank Account Number",
      };
    }
    if (
      editUserForm.account_number != "" &&
      !bank_account_pattern.test(editUserForm.account_number)
    ) {
      updatedForm = {
        ...updatedForm,
        account_number_error: "Please Enter valid Bank Account Number",
      };
    }
    if (editUserForm.ifsc_code == "") {
      updatedForm = { ...updatedForm, ifsc_code_error: "Please Enter IFSC code" };
    }
    if (editUserForm.ifsc_code != "" && !ifsc_pattern.test(editUserForm.ifsc_code)) {
      updatedForm = { ...updatedForm, ifsc_code_error: "Please Enter valid IFSC code" };
    }
    if (!editUserForm.pan_no) {
      updatedForm = { ...updatedForm, pan_no_error: "Please enter a pan no" };
    } else if (panVal.length != 10) {
      updatedForm = {
        ...updatedForm,
        pan_no_error:
          "Length should be restricted to 10 digits and should not allow anything more or less",
      };
    } else if (!firstFivePattern.test(firstFive)) {
      updatedForm = {
        ...updatedForm,
        pan_no_error: "First Five characters of PAN No should be alphabets",
      };
    } else if (!sixtoNinePattern.test(sixtoNine)) {
      updatedForm = {
        ...updatedForm,
        pan_no_error: "Six to Nine characters of PAN No should be number",
      };
    } else if (!lastValPattern.test(lastVal)) {
      updatedForm = {
        ...updatedForm,
        pan_no_error: "Last characters of PAN No should be alphabets",
      };
    } else
      updatedForm = {
        ...updatedForm,
        pan_no_error: null,
      };

    // if (!editUserForm.state) {
    //   updatedForm = { ...updatedForm, state_error: "Please enter state" };
    // }

    // if (!editUserForm.city) {
    //   updatedForm = { ...updatedForm, city_error: "Please enter city" };
    // }

    if (!editUserForm.postal_code) {
      updatedForm = { ...updatedForm, postal_code_error: "Please enter a postal code" };
    }
    if (
      editUserForm.postal_code &&
      !postalCodePattern.test(editUserForm.postal_code)
    ) {
      updatedForm = {
        ...updatedForm,
        postal_code_error: "Please enter a valid postal code",
      };
    }

    let gstVal = editUserForm.gst_no;

    if (gstVal.length > 0 ) {
      if (gstVal.length == 15) {

      let statecode = gstVal.substring(0, 2);
      let patternstatecode = /^[0-9]{2}$/;
      let threetoseven = gstVal.substring(2, 7);
      let patternthreetoseven = /^[a-zA-Z]{5}$/;
      let seventoten = gstVal.substring(7, 11);
      let patternseventoten = /^[0-9]{4}$/;
      let Twelveth = gstVal.substring(11, 12);
      let patternTwelveth = /^[a-zA-Z]{1}$/;
      let Thirteen = gstVal.substring(12, 13);
      let patternThirteen = /^[1-9a-zA-Z]{1}$/;
      let fourteen = gstVal.substring(13, 14);
      let patternfourteen = /^[zZ]{1}$/;
      let fifteen = gstVal.substring(14, 15);
      let patternfifteen = /^[0-9a-zA-Z]{1}$/;
          
       if (!patternstatecode.test(statecode)) {
          updatedForm = {
              ...updatedForm, gst_no_error: 'First two characters of GST No should be numbers'
          };
      } else if (!patternthreetoseven.test(threetoseven)) {
          updatedForm = {
              ...updatedForm,
              gst_no_error: 'Third to seventh characters of GST No should be alphabets'
          };
      } else if (!patternseventoten.test(seventoten)) {
          updatedForm = {
              ...updatedForm,
              gst_no_error: 'Eighth to Eleventh characters of GST No should be numbers'
          };
      } else if (!patternTwelveth.test(Twelveth)) {
          updatedForm = {
              ...updatedForm,
              gst_no_error: 'Twelveth character of GST No should be alphabet'
          };
      } else if (!patternThirteen.test(Thirteen)) {
          updatedForm = {
              ...updatedForm,
              gst_no_error: 'Thirteen characters of GST No can be either alphabet or numeric'
          };
      } else if (!patternfourteen.test(fourteen)) {
          updatedForm = {
              ...updatedForm,
              gst_no_error: 'Fourteen characters of GST No should be Z'
          };
      } else if (!patternfifteen.test(fifteen)) {
          updatedForm = {
              ...updatedForm,
              gst_no_error: 'Fifteen characters of GST No can be either alphabet or numeric'
          };
      }
       else if (!gstVal.toUpperCase().includes(gstVal.toUpperCase())) {
          updatedForm = { ...updatedForm, gst_no_error: "Invalid GST No. Please check!" }
      }
    }
    else{
      updatedForm = {
        ...updatedForm,
        gst_no_error: 'Length should be restricted to 15 digits and should not allow anything more or less'
    };
    }
     
  }
  else{
    updatedForm = {
      ...updatedForm,
      gst_no_error: null
  }
  }
      if(updatedForm.rate_logic == '' ){
        updatedForm = { ...updatedForm, rate_logic_error: "Please select rate logic" };
      }

      if(updatedForm.rate_logic == 'sharing' && updatedForm.rate_share == null){
        updatedForm = { ...updatedForm, rate_share_error: "Please Enter sharing rate" };
      }
    setEditUserForm(updatedForm)
    if (
      !updatedForm.first_name_error &&
      !updatedForm.last_name_error &&
      !updatedForm.firm_name_error &&
      !updatedForm.city_error &&
      !updatedForm.state_error &&
      !updatedForm.mobile_error &&
      !updatedForm.pan_no_error &&
      !updatedForm.postal_code_error&&
      !updatedForm.gst_no_error &&
      !updatedForm.ifsc_code_error&&
      !updatedForm.account_number_error&&
      !updatedForm.rate_share_error &&
      !updatedForm.rate_logic_error

    ) {
      
      if(user_data.role == "staff_operations"){
        const payload = {
          first_name: updatedForm.first_name,
          middle_name: updatedForm.middle_name,
          last_name: updatedForm.last_name,
          firm_name: updatedForm.firm_name,
          mobile: updatedForm.mobile,
          pan_no: updatedForm.pan_no,
          state: updatedForm.state,
          city: updatedForm.city,
          postal_code: updatedForm.postal_code,
          gst_no: updatedForm.gst_no,
          account_number: updatedForm.account_number,
          ifsc: updatedForm.ifsc_code,
          email:updatedForm.email,
          created_at:updatedForm.created_at,
          rate_logic:updatedForm.rate_logic,
          rate_share:updatedForm.rate_logic == 'custom' ? null : (updatedForm.rate_share)/100,
        };
        const finalPayload={
          changes:payload,
          title: `Update UserDatabase `,
          // title: `Update UserDatabase for ${payload?.first_name} ${payload?.middle_name} ${payload?.last_name}`,
          method:'Put',
          entity_id:selectedUser,
          url:'user/edit-user-data',
          entity_type:'userdatabase',
		      operation:'update'
        } 
      ApiPost("ApprovalRequest/approval-request", finalPayload).then(
        (res: any) => {
          if (res ) {
            toast.success(res.message, {
              position: "top-right",
              theme: "colored",
            });
            setEditUserPopUp(false);
            setEditUserForm(editData)
            setSelectedUser(null);

            loadUsersData();
          }
        }
      ).catch((error)=>{
        //  console.log(error)
      });
    }
    else{
      const request = {
        first_name: updatedForm.first_name,
        middle_name: updatedForm.middle_name,
        last_name: updatedForm.last_name,
        firm_name: updatedForm.firm_name,
        mobile: updatedForm.mobile,
        pan_no: updatedForm.pan_no,
        state: updatedForm.state,
        city: updatedForm.city,
        postal_code: updatedForm.postal_code,
        gst_no: updatedForm.gst_no,
        account_number: updatedForm.account_number,
        ifsc: updatedForm.ifsc_code,
        rate_logic:updatedForm.rate_logic,
        rate_share:updatedForm.rate_logic == 'custom' ? null : (updatedForm.rate_share)/100,
      };
      ApiPut(`user/edit-user-data?id=${selectedUser}`, request).then(
        (res: any) => {
          if (res ) {
            toast.success("Data updated succesfully", {
              position: "top-right",
              theme: "colored",
            });
            setEditUserPopUp(false);
            setEditUserForm(editData)
            setSelectedUser(null);

            loadUsersData();
          }
        }
      ).catch((error)=>{
        //  console.log(error)
      });}
    }
  };

  const [roles] = useState([
    { label: "DSA", value: "DSA" },
    { label: "Connector", value: "Connector" },
  ]);

    const onSelect = (e: FileUploadSelectEvent, setTotalSize, setFiles, link) => {
    let totalSize = 0;
    let files = e.files;

    for (let i = 0; i < files.length; i++) {
      totalSize += files[i].size || 0;
    }

    setTotalSize(totalSize);
    setFiles(files);
  };
  const onClear = (setTotalSize) => {
    setTotalSize(0);
  };
  const onRemove = (e, totalSize, setTotalSize) => {
    setTotalSize(totalSize - (e.file.size || 0));
  };
  const headerTemplate =
    (totalSize) => (options: FileUploadHeaderTemplateOptions) => {
      const { className, chooseButton } = options;
      const value = totalSize / 10000;
      const formattedValue = totalSize ? `${totalSize / 1000} KB` : "0 B";

      return (
        <div
          className={className}
          style={{
            backgroundColor: "transparent",
            display: "flex",
            alignItems: "center",
            flexWrap: "wrap",
          }}
        >
          <div
            style={{
              display: "flex",
              gap: "10px",
              alignItems: "center",
              flex: "1",
            }}
          >
            {chooseButton}
          </div>
          <div className="progress-container">
            <span>{formattedValue} / 1 MB</span>
            <ProgressBar
              value={value}
              showValue={false}
              className="custom-progress-bar"
            ></ProgressBar>
          </div>
        </div>
      );
    };

  const itemTemplate = (file, props) => {
    return (
      <div className="flex align-items-center flex-wrap">
        <div className="flex align-items-center" style={{ width: "40%" }}>
          <span>{file.name}</span>
        </div>
        <Button
          type="button"
          raised
          severity="info"
          icon="pi pi-times"
          className="p-button-outlined p-button-rounded p-button-danger ml-auto"
          onClick={() => props.onRemove(file)}
        />
      </div>
    );
  };

  const changeDocument=async ()=>{
    if (docName === "pancard") {
      if (fileUploadRef1.current.getFiles().length != 0) {
        const form_data = new FormData();
        form_data.append("pan_card_image", kycForm.file);
        if (kycForm.file != null) {
          await ApiPut(`kyc/update?id=${selectedUser}`, form_data)
            .then(async (response: any) => {
              if (response) {
                toast.success(" Pan card updated Successfully", {
                  position: "top-right",
                  theme: "colored",
                });
              }
              setChangeDoc(false);
              setSelectedUser(null);

              // setFormData({ formData });
              fileUploadRef1.current.clear();
            })
            .catch((error: any) => console.log("Error", error));
        }
      } else {
        toast.error("Please Upload Document", {
          position: "top-right",
          theme: "colored",
        });
      }
    }
    if (docName === "bank") {
      if (fileUploadRef1.current.getFiles().length != 0) {
        const form_data = new FormData();
        form_data.append("cheque_image", kycForm.file);
        if (kycForm.file != null) {
          await ApiPut(`kyc/update?id=${selectedUser}`, form_data)
            .then(async (response: any) => {
              if (response) {
                toast.success(" Bank detail updated Successfully", {
                  position: "top-right",
                  theme: "colored",
                });
              }
              setChangeDoc(false);
              setSelectedUser(null);

              // setFormData({ formData });
              fileUploadRef1.current.clear();
            })
            .catch((error: any) => console.log("Error", error));
        }
      } else {
        toast.error("Please Upload Document", {
          position: "top-right",
          theme: "colored",
        });
      }
    }
    if (docName === "gst") {
      if (fileUploadRef1.current.getFiles().length != 0) {
        const form_data = new FormData();
        form_data.append("gst_image", kycForm.file);
        if (kycForm.file != null) {
          await ApiPut(`kyc/update?id=${selectedUser}`, form_data)
            .then(async (response: any) => {
              if (response) {
                toast.success(" Gst updated Successfully", {
                  position: "top-right",
                  theme: "colored",
                });
              }
              setChangeDoc(false);
              setSelectedUser(null);

              // setFormData({ formData });
              fileUploadRef1.current.clear();
            })
            .catch((error: any) => console.log("Error", error));
        }
      } else {
        toast.error("Please Upload Document", {
          position: "top-right",
          theme: "colored",
        });
      }
    }
  }
  const dsaNameRowFilterTemplate = (options) => {
    return (
      <MultiSelect
        value={options.value}
        options={fullNameOptions}
        onChange={(e) => options.filterCallback(e.value)}
        optionLabel="label"
        placeholder="Select"
        className="p-column-filter"
        maxSelectedLabels={1}
        style={{ minWidth: "14rem" }}
        filter
        filterPlaceholder="Search"
      />
    );
  };

  const firmNameRowFilterTemplate = (options) => {
    return (
      <MultiSelect
        value={options.value}
        options={firmNameOptions}
        onChange={(e) => options.filterCallback(e.value)}
        optionLabel="label"
        placeholder="Select"
        className="p-column-filter"
        maxSelectedLabels={1}
        style={{ minWidth: "14rem" }}
        filter
        filterPlaceholder="Search"
      />
    );
  };

  const relationshipManagerRowFilterTemplate = (options) => {
    return (
      <MultiSelect
        value={options.value}
        options={relationshipManagersOptions}
        onChange={(e) => options.filterCallback(e.value)}
        optionLabel="label"
        placeholder="Select"
        className="p-column-filter"
        maxSelectedLabels={1}
        style={{ minWidth: "14rem" }}
        filter
        filterPlaceholder="Search"
      />
    );
  };

  const cityRowFilterTemplate = (options) => {
    return (
      <MultiSelect
        value={options.value}
        options={cityOptions}
        onChange={(e) => options.filterCallback(e.value)}
        optionLabel="label"
        placeholder="Select"
        className="p-column-filter"
        maxSelectedLabels={1}
        style={{ minWidth: "14rem" }}
        filter
        filterPlaceholder="Search"
      />
    );
  };

  const stateRowFilterTemplate = (options) => {
    return (
      <MultiSelect
        value={options.value}
        options={stateOptions}
        onChange={(e) => options.filterCallback(e.value)}
        optionLabel="label"
        placeholder="Select"
        className="p-column-filter"
        maxSelectedLabels={1}
        style={{ minWidth: "14rem" }}
        filter
        filterPlaceholder="Search"
      />
    );
  };

  const roleRowFilterTemplate = (options) => {
    return (
      <MultiSelect
        value={options.value}
        options={roles}
        onChange={(e) => options.filterCallback(e.value)}
        optionLabel="label"
        placeholder="Select"
        className="p-column-filter"
        maxSelectedLabels={1}
        style={{ minWidth: "14rem" }}
        filter
        filterPlaceholder="Search"
      />
    );
  };

  const [kycStatus] = useState([
    { label: "Pending", value: "Pending" },
    { label: "Completed", value: "Completed" },
  ]);
  const [balanceStatus] = useState([
    { label: "Has Balanace", value: true },
    { label: "No Balance", value: false },
  ]);

  const kycRowFilterTemplate = (options) => {
    return (
      <MultiSelect
        value={options.value}
        options={kycStatus}
        onChange={(e) => options.filterCallback(e.value)}
        optionLabel="label"
        placeholder="Select"
        className="p-column-filter"
        maxSelectedLabels={1}
        style={{ minWidth: "14rem" }}
        filter
        filterPlaceholder="Search"
      />
    );
  };

  const balanceStatusFilterTemplate = (options) => {
    return (
      <MultiSelect
        value={options.value}
        options={balanceStatus}
        onChange={(e) => options.filterCallback(e.value)}
        optionLabel="label"
        placeholder="Select"
        className="p-column-filter"
        maxSelectedLabels={1}
        style={{ minWidth: "14rem" }}
        filter
        filterPlaceholder="Search"
      />
    );
  };

  const customStyles = (hasError) => ({
    control: (base, state) => ({
      ...base,
      borderColor: hasError ? 'red' : base.borderColor,
      '&:hover': {
        borderColor: hasError ? 'red' : base.borderColor,
      },
    }),
  });
  return (
    <div className="overflow-x-hidden p-6">
      {loader ? (
        <>
          {/* START::OVERLAY */}
          <div className="fixed inset-0 w-full h-screen bg-white z-[9999] flex items-center justify-center">
            <img src="assets/logo.svg" />
          </div>
          {/* END::OVERLAY */}
        </>
      ) : (
        <>
          {/* START::DASHBOARD HEADER */}
          <div className="flex flex-wrap gap-5 items-center justify-between mb-5">
            <div className="md:flex w-full lg:w-[unset] items-center lg:justify-between md:space-x-9 space-y-5 md:space-y-0">
              <h1 className="text-[22px] text-light-gray font-semibold">
                User Database
              </h1>
            </div>

            {/* START::EXPORT */}
            <ul className="flex space-x-[18px]">
              {selectedUsers?.length > 0 &&
                permission[PERMISSION_TYPE.ADMIN] && (
                  <><li>
                    <button
                      onClick={() => {
                        setAddOpenType("multi");
                        setAddOpen(true);
                      } }
                      className="flex items-center space-x-[6px] py-2 px-6 rounded-[18px] border border-light-gray text-xs font-semibold"
                    >
                      <i className="pi pi-user mr-1"></i>
                      <span>Change Relationship</span>
                    </button>
                  </li>
                  <li>
                    <button
                      onClick={() => {
                        setAddOpenType("multi");
                        setAddBpOpen(true);
                      }}
                      className="flex items-center space-x-[6px] py-2 px-6 rounded-[18px] border border-light-gray text-xs font-semibold"
                    >
                      <i className="pi pi-user mr-1"></i>
                      <span>Change Booking Partner</span>
                    </button>
                  </li>
                  </>
                )}
            </ul>
            
            {/* END::EXPORT */}
          </div>
          {/* END::DASHBOARD HEADER */}
          <DataTable
            value={userData}
            lazy
            // dataKey="SRNO"
            showGridlines
            paginator
            first={lazyState.first}
            filters={lazyState.filters}
            onFilter={onFilter}
            rows={lazyState.rows}
            rowsPerPageOptions={[10, 20, 30, 40, 50]}
            totalRecords={totalRecords}
            onPage={onPage}
            loading={loading}
            size={"small"}
            scrollable
            scrollHeight="600px"
            className="custom-datatable"
            header={header}
            frozenRow
            selection={selectedUsers}
            onSelectionChange={(e) => setSelectedUsers(e.value)}
            emptyMessage="No records found."
            selectionMode={"checkbox"}
          >
            {permission[PERMISSION_TYPE.ADMIN] &&<Column selectionMode="multiple" frozen />}
            <Column field="SRNO" frozen header="SR.NO." />
            <Column
              field="Name"
              header="DSA Name"
              frozen
              showFilterMatchModes={false}
              filterMenuStyle={{ minWidth: "14rem" }}
              filter
              filterElement={dsaNameRowFilterTemplate}
            ></Column>
            <Column
              field="FirmName"
              header="Firm Name"
              showFilterMatchModes={false}
              filterMenuStyle={{ minWidth: "14rem" }}
              filter
              filterElement={firmNameRowFilterTemplate}
            />
             <Column
              field="relationship_manager"
              header="Relationship Manager / Booking Partner"
              showFilterMatchModes={false}
              filterMenuStyle={{ minWidth: "14rem" }}
              filter
              filterElement={relationshipManagerRowFilterTemplate}
            />
            <Column
              field="City"
              header="City"
              showFilterMatchModes={false}
              filterMenuStyle={{ minWidth: "14rem" }}
              filter
              filterElement={cityRowFilterTemplate}
            />
            <Column
              field="State"
              header="State"
              showFilterMatchModes={false}
              filterMenuStyle={{ minWidth: "14rem" }}
              filter
              filterElement={stateRowFilterTemplate}
            />
            <Column
              field="Role"
              header="Role"
              showFilterMatchModes={false}
              filterMenuStyle={{ minWidth: "14rem" }}
              filter
              filterElement={roleRowFilterTemplate}
            />
            <Column
              field="Email"
              header="Email"
            />  
            <Column
            field="Contact"
            header="Mobile"
          />  
          <Column
            field="total_balance"
            header="Total Balance"
            showFilterMatchModes={false}
            filterMenuStyle={{ minWidth: "14rem" }}
            filter
            filterElement={balanceStatusFilterTemplate}
          
          />         
            <Column
              field="KycStatus"
              header="Kyc Status"
              showFilterMatchModes={false}
              filterMenuStyle={{ minWidth: "14rem" }}
              filter
              filterElement={kycRowFilterTemplate}
            />
            {permission[PERMISSION_TYPE.ADMIN] &&<Column
              field="Status"
              header="Status"
              body={statusBodyTemplate}/>}
              <Column
              field="Level"
              header="Level"
            />
            <Column
              field="rate_logic"
              header="Rate Logic"
            />
            {(permission[PERMISSION_TYPE.ADMIN] || permission[PERMISSION_TYPE.STAFF_OPS] || permission[PERMISSION_TYPE.STAFF] || permission[PERMISSION_TYPE.BOOKING_PARTNER]) ? (
              <Column
                field="Action"
                header="Action"
                body={actionBodyTemplate}
              />
            ) : null}
          </DataTable>

          <Dialog
        header={`${activeStatus ? 'Dectivate' : 'Activate'  } user `}
        visible={showDialog}
        onHide={cancelToggle}
        footer={
          <div>
            <Button label="No" icon="pi pi-times" onClick={cancelToggle} className="p-button-text" />
            <Button label="Yes" icon="pi pi-check" onClick={confirmToggle} autoFocus />
          </div>
        }
      >{ activeStatus && (<div>
            <p>  Are you sure you want to disable the user. </p>
            <p>  Note:- Case resgistration and login will be disabled</p>
      </div>)}
      { !activeStatus && (<div>
            <p>  Are you sure you want to enable the user. </p>
            <p>  Note:- Case resgistration and login will be enabled</p>
      </div>)}

       
      </Dialog>

          <div className={`tw-modal ${editPayoutOpen ? 'flex' : 'hidden'} !items-start`}>
            <div className="tw-modal-content">
              <div className="tw-modal-title">
                <div>Edit Data</div>
              </div>
              <div className="tw-modal-body">
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-[22px]">
                  <div className="space-y-1">
                    <label htmlFor="first_name">Normal Payout<span style={{color:'red'}}>*</span></label>
                    <div id="first_name">
                      <input
                        type="text"
                        name="first_name"
                        className="w-full text-sm"
                        placeholder="Normal"
                      />
                    </div>
                  </div>
                  <div className="space-y-1">
                    <label htmlFor="last_name">Capping<span style={{color:'red'}}>*</span> </label>
                    <div id="last_name">
                      <input
                        type="text"
                        name="last_name"
                        className="w-full text-sm"
                        placeholder="Capping"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="tw-modal-footer">
                <button className="tw-modal-footer-button bg-secondary text-white">
                  Save
                </button>
                <button
                  className="tw-modal-footer-button bg-secondary bg-opacity-10 text-secondary"
                  onClick={() => seteditPayoutOpen(false)}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>

          <div
            className={`tw-modal ${addOpen ? "flex" : "hidden"} !items-start`}
          >
            <div className="tw-modal-content">
              <div className="tw-modal-title">
                <div>Add RelationShip</div>
                <button onClick={() =>{ setAddOpen(false)
                                    setFormData(data);
                                    setAddOpenType("");

                }}>
                  <img src="assets/close-outline.svg" alt="" />
                </button>
              </div>

              <div className="tw-modal-body">
                <div className="space-y-1">
                  <label
                    htmlFor="relationship_manager"
                    className={`input-label ${
                      formData.relationship_manager_error ? "text-red-500" : ""
                    }`}
                  >
                    Relationship Manager<span style={{color:'red'}}>*</span>
                  </label>
                  <div
                    id="relationship_manager"
                    className={`input-wrap ${
                      formData.relationship_manager_error
                        ? "border-red-500"
                        : ""
                    }`}
                  >
                    <img src="assets/bank.svg" alt="" />
                    <select
                      name="relationship_manager"
                      value={formData.relationship_manager}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          relationship_manager: e.target.value,
                        })
                      }
                      className="w-full text-sm text-[#808080]"
                    >
                      <option value="" selected>
                        Select Staff<span style={{color:'red'}}>*</span>
                      </option>
                      {staffData.map((staff: any, index: number) => (
                        <option key={index} value={staff.id}>
                          {staff.name + " - " + staff.email + " - " + staff.department}
                        </option>
                      ))}
                    </select>
                  </div>
                  <p className="text-red-500 text-sm">
                    {formData.relationship_manager_error}
                  </p>
                </div>
              </div>

              <div className="tw-modal-footer">
                <button
                  className="tw-modal-footer-button bg-secondary text-white"
                  onClick={addRelation}
                >
                  Save
                </button>
                <button
                  className="tw-modal-footer-button bg-secondary bg-opacity-10 text-secondary"
                  onClick={() => {
                    setAddOpenType("");
                    setAddOpen(false);
                    setFormData(data)
                  }}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>

          <div
            className={`tw-modal ${addBpOpen ? "flex" : "hidden"} !items-start`}
          >
            <div className="tw-modal-content">
              <div className="tw-modal-title">
                <div>Add BookingPartner</div>
                <button onClick={() => {setAddBpOpen(false)
                  setFormBpData(databp);
                  setAddOpenType("");

                }}>
                  <img src="assets/close-outline.svg" alt="" />
                </button>
              </div>

              <div className="tw-modal-body">
                <div className="space-y-1">
                  <label
                    htmlFor="relationship_manager"
                    className={`input-label ${
                      formBpData.booking_partner_error ? "text-red-500" : ""
                    }`}
                  >
                    Booking Partner<span style={{color:'red'}}>*</span>
                  </label>
                  <div
                    id="relationship_manager"
                    className={`input-wrap ${
                      formBpData.booking_partner_error
                        ? "border-red-500"
                        : ""
                    }`}
                  >
                    <img src="assets/bank.svg" alt="" />
                    <select
                      name="relationship_manager"
                      value={formBpData.booking_partner}
                      onChange={(e) =>
                        setFormBpData({
                          ...formBpData,
                          booking_partner: e.target.value,
                          booking_partner_error: null,
                        })
                      }
                      className="w-full text-sm text-[#808080]"
                    >
                      <option value="" selected>
                        Select Booking Partner<span style={{color:'red'}}>*</span>
                      </option>
                      {bookingPartnerData.map((staff: any, index: number) => (
                        <option key={index} value={staff.id}>
                          {staff.name + " - " + staff.email }
                        </option>
                      ))}
                    </select>
                  </div>
                  <p className="text-red-500 text-sm">
                    {formBpData.booking_partner_error}
                  </p>
                </div>
              </div>

              <div className="tw-modal-footer">
                <button
                  className="tw-modal-footer-button bg-secondary text-white"
                  onClick={addBookingPartner}
                >
                  Save
                </button>
                <button
                  className="tw-modal-footer-button bg-secondary bg-opacity-10 text-secondary"
                  onClick={() => {setAddBpOpen(false)
                    setFormBpData(databp);
                    setAddOpenType("");

                  }}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>

          <div
            className={`tw-modal ${updateManagerOpen ? "flex" : "hidden"} !items-start`}
          >
            <div className="tw-modal-content">
              <div className="tw-modal-title">
                <div>Update BookingPartner/Relationship Manager</div>
                <button onClick={() => {
                  setUpdateManagerOpen(false)
                  setSelectedData({
                    id_error: null,
                    id: '',
                    type:''
                  });
                  setOptionData([])

                }}>
                  <img src="assets/close-outline.svg" alt="" />
                </button>
              </div>

              <div className="tw-modal-body">
                <div className="space-y-1">
                  <label
                    htmlFor="relationship_manager"
                    className={`input-label ${
                      selectedData?.id_error ? "text-red-500" : ""
                    }`}
                  >
                    Update RM/BP<span style={{color:'red'}}>*</span>
                  </label>
                  <div
                    id="relationship_manager"
                    className={`input-wrap ${
                      selectedData?.id_error
                        ? "border-red-500"
                        : ""
                    }`}
                  >
                    <img src="assets/bank.svg" alt="" />
                    <select
                      name="relationship_manager"
                      value={selectedData?.id}
                      onChange={(e) =>
                        setSelectedData( {...selectedData,
                          id:e.target.value,
                          id_error:null})
                      }
                      className="w-full text-sm text-[#808080]"
                    >
                      <option value="" selected>
                        Please Select <span style={{color:'red'}}>*</span>
                      </option>
                      {optiondata.map((staff: any, index: number) => (
                        <option key={index} value={staff.id}>
                          {staff.name + " - " + staff.email }
                        </option>
                      ))}
                    </select>
                  </div>
                  <p className="text-red-500 text-sm">
                    {selectedData?.id_error}
                  </p>
                </div>
              </div>

              <div className="tw-modal-footer">
                <button
                  className="tw-modal-footer-button bg-secondary text-white"
                  onClick={updateRole}
                >
                  Save
                </button>
                <button
                  className="tw-modal-footer-button bg-secondary bg-opacity-10 text-secondary"
                  onClick={() => {
                    setUpdateManagerOpen(false)
                  setSelectedData({
                    id_error: null,
                    id: '',
                    type:''
                  });
                  setOptionData([])
                  }}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
          <div
            className={`tw-modal ${kycPopUp ? "flex" : "hidden"} !items-start`}
          >
            <div className="tw-modal-content">
              <div className="tw-modal-title">
                <div>KYC Admin</div>
                <button onClick={() => setKycPopUp(false)}>
                  <img src="assets/close-outline.svg" alt="" />
                </button>
              </div>

              <div className="tw-modal-body">
                <div className="space-y-1">
                  <div className="md:flex md:items-start md:space-x-[20px] space-y-4 md:space-y-0">
                    <div className="w-full space-y-1 flex justify-between">
                    <button
                      className="px-16 py-[18px] text-semibold text-sm bg-secondary text-white rounded-lg border border-secondary"
                      onClick={() => {
                        getDowloadLink(kycDetails["pan_card_image"]);
                      }}
                    >
                      Download PanCard
                    </button>
                    <button
                      className="px-16 mt-2 py-[18px] text-semibold text-sm bg-secondary text-white rounded-lg border border-secondary"
                      onClick={() => {
                        getDowloadLink(kycDetails["cheque_image"]);
                      }}
                    >
                      Download Cheque
                    </button>
                    <button
                      className="px-16 mt-2 py-[18px] text-semibold text-sm bg-secondary text-white rounded-lg border border-secondary"
                      onClick={() => {
                        getDowloadLink(kycDetails["gst_image"]);
                      }}
                    >
                      Download GST
                    </button>
                  </div>
                  </div>
                </div>
                <div className="space-y-1">
                  <div className="md:flex md:items-start md:space-x-[20px] space-y-4 md:space-y-0">
                    <div className="w-full space-y-1 flex justify-between">
                    <button
                      className="px-16 py-[18px] text-semibold text-sm bg-secondary text-white rounded-lg border border-secondary"
                      onClick={() => {
                        setChangeDoc(true)
                        setDocName('pancard')
                      }}
                    >
                      Change PanCard
                    </button>
                    <button
                      className="px-16 mt-2 py-[18px] text-semibold text-sm bg-secondary text-white rounded-lg border border-secondary"
                      onClick={() => {
                        setDocName('bank')
                        setChangeDoc(true)
                      }}
                    >
                      Change Cheque
                    </button>
                    <button
                      className="px-16 mt-2 py-[18px] text-semibold text-sm bg-secondary text-white rounded-lg border border-secondary"
                      onClick={() => {
                        setDocName('gst')
                        setChangeDoc(true)
                      }}
                    >
                      Change GST
                    </button>
                  </div>
                  </div>
                </div>
              </div>
              <div className="tw-modal-footer"></div>
            </div>
          </div>
          <div
            className={`tw-modal ${changeDoc ? "flex" : "hidden"}`}
          >
            <div className="tw-modal-content">
              <div className="tw-modal-title">
                Change {docName.toLocaleUpperCase()} Document 
                <button
                  onClick={() => {
                    setChangeDoc(false);
                    fileUploadRef1.current.clear();
                  }}
                >
                  <img src="assets/close-outline.svg" alt="" />
                </button>
              </div>
              <div className={`tw-modal-body `}>
                <div
                  
                >
                  <FileUpload
                    // mode="basic"
                    ref={fileUploadRef1}
                    name="demo[]"
                    accept=".jpg,.jpeg,.png,.gif,.pdf"
                    maxFileSize={1000000}
                    // onUpload={(e) => onUpload(e, setFileUpload1Size)}
                    onSelect={(e) => {
                      onSelect(
                        e,
                        setFileUpload1Size,
                        setFileUpload1Files,
                        "pan"
                      );
                      SetKycForm({
                        ...kycForm,
                        file: e.files[0],
                        file_error: null,
                      });
                    }}
                    onError={() => onClear(setFileUpload1Size)}
                    onClear={() => onClear(setFileUpload1Size)}
                    onRemove={(e) =>
                      onRemove(e, fileUpload1Size, setFileUpload1Size)
                    }
                    headerTemplate={headerTemplate(fileUpload1Size)}
                    itemTemplate={itemTemplate}
                    emptyTemplate={
                      <p className="m-0">
                        Drag and drop files to here to upload.
                      </p>
                    }
                  />
                </div>
                
              </div>
              <div className="tw-modal-footer">
                <Button
                  icon="pi pi-check"
                  raised
                  severity="info"
                  onClick={() => {
                    changeDocument();
                  }}
                >
                  <span style={{ marginLeft: "8px" }}> Save</span>
                </Button>
                <Button
                  icon="pi pi-times"
                  raised
                  severity="danger"
                  onClick={() => {
                    fileUploadRef1.current.clear();
                    setChangeDoc(false)
                  }}
                >
                  <span style={{ marginLeft: "8px" }}> Cancel</span>
                </Button>
              </div>
            </div>
          </div>
          <div
            className={`tw-modal ${
              editUserPopUp ? "flex" : "hidden"
            } !items-start`}
          >
            <div className="tw-modal-content">
              <div className="tw-modal-title">
                <div>Edit User</div>
                <button onClick={() => {setEditUserPopUp(false)
                    setEditUserForm(editData)
                    }}>
                  <img src="assets/close-outline.svg" alt="" />
                </button>
              </div>
              <div className="tw-modal-body">
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-[22px]">
                  <div className="space-y-1">
                    <label
                      htmlFor="firstName"
                      className={`input-label ${
                        editUserForm.first_name_error ? "border-red-500" : ""
                      }`}
                    >
                      First Name<span style={{color:'red'}}>*</span>
                    </label>
                    <div
                      id="firstName"
                      className={`input-wrap ${
                        editUserForm.first_name_error ? "border-red-500" : ""
                      }`}
                    >
                      <input
                        type="text"
                        name="first_name"
                        value={editUserForm.first_name}
                        onInput={handleNameInput}
                        onChange={(e: any) => {
                          setEditUserForm({
                            ...editUserForm,
                            first_name: e.target.value,
                            first_name_error: "",
                          });
                        }}
                        className="w-full text-sm"
                        placeholder="Enter First name"                       />
                    </div>
                    <p className="text-red-500 text-sm">
                      {editUserForm.first_name_error}
                    </p>
                  </div>

                  <div className="space-y-1">
                    <label
                      htmlFor="middleName"
                      className={`input-label ${
                        editUserForm.middle_name ? "" : ""
                      }`}
                    >
                      Middle Name
                    </label>
                    <div id="middleName" className={`input-wrap`}>
                      <input
                        type="text"
                        name="middle_name"
                        value={editUserForm.middle_name}
                        onInput={handleNameInput}
                        onChange={(e: any) => {
                          setEditUserForm({
                            ...editUserForm,
                            middle_name: e.target.value,
                          });
                        }}
                        className="w-full text-sm"
                        placeholder="Enter Middle name"                       />
                    </div>
                  </div>

                  <div className="space-y-1">
                    <label
                      htmlFor="lastName"
                      className={`input-label ${
                        editUserForm.last_name_error ? "border-red-500" : ""
                      }`}
                    >
                      Last Name<span style={{color:'red'}}>*</span>
                    </label>
                    <div
                      id="lastName"
                      className={`input-wrap ${
                        editUserForm.last_name_error ? "border-red-500" : ""
                      }`}
                    >
                      <input
                        type="text"
                        name="last_name"
                        value={editUserForm.last_name}
                        onInput={handleNameInput}
                        onChange={(e: any) => {
                          setEditUserForm({
                            ...editUserForm,
                            last_name: e.target.value,
                            last_name_error: "",
                          });
                        }}
                        className="w-full text-sm"
                        placeholder="Enter Last name"                      />
                    </div>
                    <p className="text-red-500 text-sm">
                      {editUserForm.last_name_error}
                    </p>
                  </div>

                  <div className="space-y-1">
                    <label
                      htmlFor="firm_name"
                      className={`input-label ${
                        editUserForm.firm_name_error ? "border-red-500" : ""
                      }`}
                    >
                      Firm Name<span style={{color:'red'}}>*</span>
                    </label>
                    <div
                      id="firm_name"
                      className={`input-wrap ${
                        editUserForm.firm_name_error ? "border-red-500" : ""
                      }`}
                    >
                      <input
                        type="text"
                        name="firm_name"
                        value={editUserForm.firm_name}
                        onInput={handleNameInput}
                        onChange={(e: any) => {
                          setEditUserForm({
                            ...editUserForm,
                            firm_name: e.target.value,
                            firm_name_error: "",
                          });
                        }}
                        className="w-full text-sm"
                        placeholder="Enter Firm name"                       />
                    </div>
                    <p className="text-red-500 text-sm">
                      {editUserForm.firm_name_error}
                    </p>
                  </div>

                  <div className="space-y-1">
                    <label
                      htmlFor="mobile"
                      className={`input-label ${
                        editUserForm.mobile_error ? "border-red-500" : ""
                      }`}
                    >
                      Mobile No.<span style={{color:'red'}}>*</span>
                    </label>
                    <div
                      id="mobile"
                      className={`input-wrap ${
                        editUserForm.mobile_error ? "border-red-500" : ""
                      }`}
                    >
                      <input
                        type="text"
                        name="mobile"
                        value={editUserForm.mobile}
                        maxLength={13}
                        minLength={10}
                        onChange={(e: any) => {
                          setEditUserForm({
                            ...editUserForm,
                            mobile: e.target.value,
                            mobile_error: "",
                          });
                        }}
                        className="w-full text-sm"
                        placeholder="Enter Mobile No."
                      />
                    </div>
                    <p className="text-red-500 text-sm">
                      {editUserForm.mobile_error}
                    </p>
                  </div>

                  <div className="space-y-1">
                    <label
                      htmlFor="pan_no"
                      className={`input-label ${
                        editUserForm.pan_no_error ? "border-red-500" : ""
                      }`}
                    >
                      Pan Number<span style={{color:'red'}}>*</span>
                    </label>
                    <div
                      id="pan_no"
                      className={`input-wrap ${
                        editUserForm.pan_no_error ? "border-red-500" : ""
                      }`}
                    >
                      <input
                        type="text"
                        name="pan_no"
                        value={editUserForm.pan_no}
                        maxLength={10}
                        onChange={(e: any) => {
                          setEditUserForm({
                            ...editUserForm,
                            pan_no: e.target.value,
                            pan_no_error: "",
                          });
                        }}
                        className="w-full text-sm"
                        placeholder="Enter Pan No."
                      />
                    </div>
                    <p className="text-red-500 text-sm">
                      {editUserForm.pan_no_error}
                    </p>
                  </div>

                  <div className="space-y-1">
                    <label htmlFor="state" className="input-label">
                      State
                    </label>
                    <div id="state" className="input-wrap">
                      <input
                        type="text"
                        name="state"
                        value={editUserForm.state}
                        onChange={(e: any) => {
                          setEditUserForm({
                            ...editUserForm,
                            state: e.target.value,
                          });
                        }}
                        className="w-full text-sm"
                        placeholder="Enter State"
                        disabled
                      />
                    </div>
                  </div>

                  <div className="space-y-1">
                    <label htmlFor="city" className="input-label">
                      City
                    </label>
                    <div id="city" className="input-wrap">
                      <input
                        type="text"
                        name="city"
                        value={editUserForm.city}
                        onChange={(e: any) => {
                          setEditUserForm({
                            ...editUserForm,
                            city: e.target.value,
                          });
                        }}
                        className="w-full text-sm"
                        placeholder="Enter City"
                        disabled
                      />
                    </div>
                  </div>

                  <div className="space-y-1">
                    <label
                      htmlFor="postal_code"
                      className={`input-label ${
                        editUserForm.postal_code_error ? "border-red-500" : ""
                      }`}
                    >
                      Postal Code<span style={{color:'red'}}>*</span>
                    </label>
                    <div
                      id="postal_code"
                      className={`input-wrap ${
                        editUserForm.postal_code_error ? "border-red-500" : ""
                      }`}
                    >
                      <input
                        type="text"
                        name="postal_code"
                        value={editUserForm.postal_code}
                        maxLength={6}
                        onInput={handlePostalCodeInput}
                        onChange={(e: any) => {
                          postget(e);
                        }}
                        className="w-full text-sm"
                        placeholder="Enter Postal Code"
                      />
                    </div>
                    <p className="text-red-500 text-sm">
                      {editUserForm.postal_code_error}
                    </p>
                  </div>

                  <div className="space-y-1">
                    <label
                      htmlFor="gst_no"
                      className={`input-label ${
                        editUserForm.gst_no_error ? "border-red-500" : ""
                      }`}                    >
                      GST No.
                    </label>
                    <div
                      id="gst_no"
                      className={`input-wrap ${
                        editUserForm.gst_no_error ? "border-red-500" : ""
                      }`}
                    >
                      <input
                        type="text"
                        name="gst_no"
                        value={editUserForm.gst_no}
                        maxLength={15}
                        onChange={(e: any) => {
                          setEditUserForm({
                            ...editUserForm,
                            gst_no: e.target.value,
                            gst_no_error:null
                          });
                        }}
                        className="w-full text-sm"
                        placeholder="Enter GST No."
                      />
                    </div>
                    <p className="text-red-500 text-sm">
                      {editUserForm.gst_no_error}
                    </p>
                  </div>
                  <div className="space-y-1">
                    <label
                      htmlFor="acct_no"
                      className={`input-label ${
                        editUserForm.account_number_error ? "border-red-500" : ""
                      }`}                    >
                      Account No.<span style={{color:'red'}}>*</span>
                    </label>
                    <div
                      id="acct_no"
                      className={`input-wrap ${
                        editUserForm.account_number_error ? "border-red-500" : ""
                      }`}
                    >
                      <input
                        type="text"
                        name="acctt_no"
                        value={editUserForm.account_number}
                        onChange={(e: any) => {
                          setEditUserForm({
                            ...editUserForm,
                            account_number: e.target.value,
                            account_number_error:null
                          });
                        }}
                        className="w-full text-sm"
                        placeholder="Enter Account No."
                      />
                    </div>
                    <p className="text-red-500 text-sm">
                      {editUserForm.account_number_error}
                    </p>
                  </div>
                  <div className="space-y-1">
                    <label
                      htmlFor="ifsc_code"
                      className={`input-label ${
                        editUserForm.ifsc_code_error ? "border-red-500" : ""
                      }`}                    >
                      IFSC Code<span style={{color:'red'}}>*</span>
                    </label>
                    <div
                      id="ifsc_code"
                      className={`input-wrap ${
                        editUserForm.ifsc_code_error ? "border-red-500" : ""
                      }`}
                    >
                      <input
                        type="text"
                        name="ifsc_code"
                        value={editUserForm.ifsc_code}
                        onChange={(e: any) => {
                          setEditUserForm({
                            ...editUserForm,
                            ifsc_code: e.target.value,
                            ifsc_code_error:null
                          });
                        }}
                        className="w-full text-sm"
                        placeholder="Enter IFSC code"
                      />
                    </div>
                    <p className="text-red-500 text-sm">
                      {editUserForm.ifsc_code_error}
                    </p>
                  </div>
                  <div className="space-y-1">
                  <label
                      htmlFor="rate_logic"
                      className={`input-label ${
                        editUserForm.rate_logic_error ? "border-red-500" : ""
                      }`}  >
                      Rate logic<span style={{color:'red'}}>*</span>
                    </label>
                  <select
                    id="dropdown"
                    name="level"
                    required
                    value={editUserForm.rate_logic}
                    className={`w-full text-sm text-[#808080]  px-5 py-3  mb-10  border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 `}
                    aria-describedby="level-error"
                    onChange={(e) => {setRateType(e.target.value)
                      setEditUserForm({
                        ...editUserForm,
                        rate_logic: e.target.value,
                        rate_logic_error:null
                      });}
                    }
                    style={editUserForm.rate_logic_error != null ? { border: '1px solid red' } : {}}
                    // className="w-full text-sm text-[#808080]"
                    disabled={permission[PERMISSION_TYPE.STAFF_OPS] ? true : false}
                  >
                    <option value=" ">Select any one logic...</option>
                    <option value="custom">Custom</option>
                    <option value="sharing">Sharing</option>
                  </select>
                  <p
                    id="level-error"
                    className="text-red-500 text-sm"
                   
                  >
                    {editUserForm.rate_logic_error}
                  </p>
                </div>
                {editUserForm.rate_logic == 'sharing' && <div className="space-y-1">
                    <label
                      htmlFor="gst_no"
                      className={`input-label ${
                        editUserForm.rate_share_error ? "border-red-500" : ""
                      }`}  >
                      Rate<span style={{color:'red'}}>*</span>
                    </label>
                    <div
                      id="gst_no"
                     
                    >
                      <InputNumber value={editUserForm.rate_share} 
                      onValueChange={(e) => {
                          setEditUserForm({
                            ...editUserForm,
                            rate_share: e.value,
                            rate_share_error:null
                          });
                        }} 
                      mode="decimal" showButtons min={0} max={100} 
                      style={editUserForm.rate_share_error != null ? { border: '1px solid red', borderRadius: '0.375rem' } : {}}
                      disabled={permission[PERMISSION_TYPE.STAFF_OPS] ? true : false}
/>
                      
                    </div>
                    <p className="text-red-500 text-sm">
                      {editUserForm.rate_share_error}
                    </p>
                  </div>}
                  <div className="space-y-1">
                    <label
                      htmlFor="email"
                      className={`input-label ${
                        editUserForm.email ? "" : "text-red-500"
                      }`}
                    >
                      Email
                    </label>
                    <div id="email">
                      <input
                        type="text"
                        disabled
                        name="email"
                        value={editUserForm.email}
                        onChange={(e: any) => {
                          setEditUserForm({
                            ...editUserForm,
                            email: e.target.value,
                          });
                        }}
                        className="w-full text-sm"
                      />
                    </div>
                  </div>

                  <div className="space-y-1">
                    <label
                      htmlFor="created_at"
                      className={`input-label ${
                        editUserForm.created_at ? "" : "text-red-500"
                      }`}
                    >
                      Created At
                    </label>
                    <div id="created_at">
                      <input
                        type="text"
                        disabled
                        name="created_at"
                        value={editUserForm.created_at}
                        onChange={(e: any) => {
                          setEditUserForm({
                            ...editUserForm,
                            created_at: e.target.value,
                          });
                        }}
                        className="w-full text-sm"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="tw-modal-footer">
                <button
                  className="tw-modal-footer-button bg-secondary text-white"
                  onClick={updateUserData}
                >
                  Save
                </button>
                <button
                  className="tw-modal-footer-button bg-secondary bg-opacity-10 text-secondary"
                  onClick={() => {setEditUserPopUp(false)
                    setEditUserForm(editData)
                    }
                  }
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>

          <div
            className={`tw-modal ${addWalletLedger ? "flex" : "hidden"} !items-start`}
          >
            <div className="tw-modal-content">
              <div className="tw-modal-title">
                <div>Add Wallet Ledger</div>
                <button onClick={() => {setAddWalletLedger(false)
                setWalletledgerForm(wdata)
                setAddCase(false)

                }}>
                  <img src="assets/close-outline.svg" alt="" />
                </button>
              </div>

              <div className="tw-modal-body">
                
                <div className="space-y-1">
                    <label
                      htmlFor="amount"
                      className={`input-label ${
                        walletledgerForm.amount_error ? "border-red-500" : ""
                      }`}
                    >
                      Amount<span style={{color:'red'}}>*</span>
                    </label>
                    <div
                      id="firstName"
                      className={`input-wrap ${
                        walletledgerForm.amount_error ? "border-red-500" : ""
                      }`}
                    >
                      <input
                        type="text"
                        name="first_name"
                        value={walletledgerForm.amount}
                        onChange={(e: any) => {
                          setWalletledgerForm({
                            ...walletledgerForm,
                            amount: e.target.value,
                            amount_error: null,
                          });
                        }}
                        className="w-full text-sm"
                        placeholder="Enter Amount"                       />
                    </div>
                    <p className="text-red-500 text-sm">
                      {walletledgerForm.amount_error }
                    </p>
                  </div>
                  <div className="space-y-1">
                    <label
                      htmlFor="reason"
                      className={`input-label ${
                        walletledgerForm.reason_error  ? "border-red-500" : ""
                      }`}
                    >
                      Reason<span style={{color:'red'}}>*</span>
                    </label>
                    <div
                      id="firstName"
                      className={`input-wrap ${
                        walletledgerForm.reason_error ? "border-red-500" : ""
                      }`}
                    >
                      <input
                        type="text"
                        name="first_name"
                        value={walletledgerForm.reason}
                        onInput={handleNameInput}
                        onChange={(e: any) => {
                          setWalletledgerForm({
                            ...walletledgerForm,
                            reason: e.target.value,
                            reason_error: null,
                          });
                        }}
                        className="w-full text-sm"
                        placeholder="Enter Reason"                       />
                    </div>
                    <p className="text-red-500 text-sm">
                      {walletledgerForm.reason_error}
                    </p>
                  </div>
                  <div className="space-y-1">
                    <br className="hidden sm:block" />
                    <label className="input-label">
                      Want to adjust amount for particular case ?
                    </label>
                    <div className="flex space-x-4">
                      <div className="flex items-center space-x-1">
                        
                        <RadioButton id="yesConnector" value="yes" onChange={() => {
                            setAddCase(true)
                            } }  checked={addCase}/>
                        <label htmlFor="yesConnector" >Yes</label>
                      </div>
                      <div className="flex items-center space-x-1">
                      <RadioButton inputId="ingredient1"  value="no" onChange={() => {
                                                    setAddCase(false)
                                                    setWalletledgerForm({
                                                      ...walletledgerForm,
                                                      dsadisbursement_id:null,
                                                      dsadisbursement_error:null
                                                    })
                      }} checked={!addCase}/>
                        <label htmlFor="noConnector" >No</label>
                      </div>
                    </div>
                  </div>
                 {<div className={`space-y-1 ${addCase ? "" : "hidden"}`}>
                  <label
                    htmlFor="relationship_manager"
                    className={`input-label ${
                      walletledgerForm.dsadisbursement_error ? "text-red-500" : ""
                    }`}
                  >
                    Dsa Disbursement Case<span style={{color:'red'}}>*</span>
                  </label>
                  <div
                    id="relationship_manager"
                    className={`input-wrap ${
                      walletledgerForm.dsadisbursement_error
                        ? "border-red-500"
                        : ""
                    }`}
                  >
                    <img src="assets/bank.svg" alt="" />
                    <select
                      name="relationship_manager"
                      value={walletledgerForm.dsadisbursement_id}
                      onChange={(e) =>
                        setWalletledgerForm({
                          ...walletledgerForm,
                          dsadisbursement_id: e.target.value,
                          dsadisbursement_error:null
                        })
                      }
                      className="w-full text-sm text-[#808080]"
                    >
                      <option value="" selected>
                        Select Disbursement<span style={{color:'red'}}>*</span>
                      </option>
                      {dsaDisbursementList?.map((data: any, index: number) => (
                        <option key={index} value={data?.dsadisbursementid}>
                          {data?.borrower_name + " / " + data?.bank_name+ " - " + data?.product+ " / " + data?.month+ " - " + data?.year }
                        </option>
                      ))}
                    </select>
                  </div>
                  <p className="text-red-500 text-sm">
                    {walletledgerForm.dsadisbursement_error}
                  </p>
                </div>}
              </div>

              <div className="tw-modal-footer">
                <button
                  className="tw-modal-footer-button bg-secondary text-white"
                  onClick={submitWalletLedger}
                >
                  Save
                </button>
                <button
                  className="tw-modal-footer-button bg-secondary bg-opacity-10 text-secondary"
                  onClick={() => {setAddWalletLedger(false)
                    setWalletledgerForm(wdata)
                    setAddCase(false)
                  }}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </>
      )}
      {/* </main> */}
    </div>
  );
}

export default UserDatabase;
