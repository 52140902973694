import {
    Checkbox,
    TableCell,
    TableRow,
    Tooltip,
    TextField,
    Stepper,
  } from "@mui/material";
  import React, { useRef, useState } from "react";
  import TableComponent from "../../components/Tables";
  import { useEffect } from "react";
  import DatePicker from "react-datepicker";
  import { ApiGet, ApiPost, ApiPut } from "../../helper/API/ApiData";
  import { STATE } from "../../constants";
  import axios from "axios";
  import _ from "lodash";
  import { isEmpty, numberFormat } from "../../helper/util";
  import { getSlabValue } from "../../helper/util";
  import STORAGEKEY from "../../config/APP/app.config";
  import { useDispatch, useSelector } from "react-redux";
  import { useNavigate } from "react-router";
  import { Logout_Auth } from "../../redux/reducers/authReducer";
  import { PERMISSION_TYPE, userPermission } from "../../helper/permission";
  import { RootState } from "../../redux/store";
  import { toast } from "react-toastify";
  import CsvDownload from "react-json-to-csv";
  import { FilterMatchMode } from "primereact/api";
  import { Column } from "primereact/column";
  import { DataTable } from "primereact/datatable";
  import { InputText } from "primereact/inputtext";
  import { Button } from "primereact/button";
  import { TieredMenu } from "primereact/tieredmenu";
  import { MultiSelect } from "primereact/multiselect";
  import { Dropdown } from "primereact/dropdown";
  import { Padding } from "@mui/icons-material";
  import { ConfirmDialog } from "primereact/confirmdialog";
  import { isDisabled } from "@testing-library/user-event/dist/utils";
  import 'jspdf-autotable';
  import jsPDF from 'jspdf';
  import autoTable from 'jspdf-autotable';
  import { RadioButton } from "primereact/radiobutton";
  import moment from 'moment'
  import Select from 'react-select';
  import { OverlayPanel } from "primereact/overlaypanel";
  import { StepperPanel } from 'primereact/stepperpanel';
  import { toWords } from 'number-to-words';
  import {ToWords} from 'to-words';
  
  const InvoiceAdminData = () => {
    
    
    const [loader, setLoader] = useState(true);
  
      const [permission, setPermission] = useState<any>({});
    const userData = useSelector(
      (state: RootState) => state?.authUser?.userData?.user
    );
    const [searchValue, setSearchValue] = useState("");
    const [totalRecords, setTotalRecords] = useState(0);
  
    const [lazyState, setlazyState] = useState({
      first: 0,
      rows: 10,
      page: 0,
      pagination:'',
      search: "",
      filters: {
        dsainvoicenumber: { value: null, matchMode: FilterMatchMode.IN }
      },
    });
    const [invoiceDetail, setInvoiceDetail] = useState([]);    
    
      const [viewInvoice,setViewInvoice]=useState(false)
      const [invoicePath,setInvoicePath]=useState('')
      const [veiwCases, setViewCases] =
      useState<boolean>(false);
          const [invoiceCases, setInvoiceCases] = useState([]);
      
    let date=new Date().toDateString();
      useEffect(() => {
      
      const tempPermission = {
        [PERMISSION_TYPE.ADMIN]: userPermission(userData, PERMISSION_TYPE.ADMIN),
        [PERMISSION_TYPE.USER]: userPermission(userData, PERMISSION_TYPE.USER),
        [PERMISSION_TYPE.STAFF]: userPermission(userData, PERMISSION_TYPE.STAFF),
        [PERMISSION_TYPE.CONNECTOR]: userPermission(userData, PERMISSION_TYPE.CONNECTOR),
        [PERMISSION_TYPE.STAFF_OPS]: userPermission(userData, PERMISSION_TYPE.STAFF_OPS),
        [PERMISSION_TYPE.BOOKING_PARTNER]: userPermission(userData, PERMISSION_TYPE.BOOKING_PARTNER),
  
      };
      setPermission(tempPermission);
    }, [userData]);
  
    
  
        const onPage = (event) => {
      let filter = { ...lazyState };
      filter = event;
      filter["search"] = lazyState.search;
      setlazyState(filter);
    };
    const onFilter = (event) => {
      event['first'] = 0;
      event['page'] = 0;
      setlazyState(event);
  };
  
    
  
    const renderHeader = () => {
      return (
        <div className="flex justify-end justify-space-between">
            <span>
              <InputText
                className="mr-2 ml-3"
                value={searchValue}
                onChange={handleInputChange}
                onKeyDown={handleKeyDown}
                placeholder="Keyword Search"
              />
              <Button icon="pi pi-search" onClick={handleSearchClick} />
            </span>
        </div>
      );
    };
  
    
    const handleKeyDown = (event) => {
      if (event.key === "Enter") {
        onGlobalFilterChange(searchValue);
      }
    };
  
    const handleInputChange = (event) => {
      setSearchValue(event.target.value);
    };
    const handleSearchClick = () => {
      onGlobalFilterChange(searchValue);
    };
    const onGlobalFilterChange = (e) => {
      const value = e;
      let filter = { ...lazyState };
  
      filter["search"] = value;
  
      setlazyState(filter);
    };
  
    const header = renderHeader();
  
    const menuRef = useRef(null);
    const selectedRowData = useRef(null);
    
    const [approveInvoiceVisible, setApproveInvoiceVisible] =
      useState<boolean>(false);
    const approveInvoice = () => {
      try {
        ApiPost(`invoiceDetails/approve-invoice?id=${selectedRowData.current.id}`).then(
          (res: any) => {
            getInvoicedata()
            toast.success(res.data, {
              position: "top-right",
              theme: "colored",
            });
          }
        );
      } catch (error) {
        // toast.error(error.error, {
        //   position: "top-right",
        //   theme: "colored"
        // })
      }
      getInvoicedata();  };
  
    const rejectInvoice = () => {
      setApproveInvoiceVisible(false);
    };
  
   
    const getInvoiceImageData = async (path: any) => {
        if (path != undefined) {
          await ApiPost(`disbursement/get-proof-path`, {
            path,
          }).then((res: any) => {
            setInvoicePath(res.data.imageData);
          });
        } else {
          toast.error("Doc not uploaded", {
            position: "top-right",
            theme: "colored",
          });
        }
      };
    const handleMenuItemClick = (itemLabel) => {
      
  
       if(itemLabel== 'View Invoice'){
        setViewInvoice(true)
        getInvoiceImageData(selectedRowData.current?.invoice_path)
       }
       if(itemLabel== 'Approve Invoice'){
      setApproveInvoiceVisible(true)
       }
       if(itemLabel== 'Cases List'){
        getInvoiceCasesData(selectedRowData.current.id)
         }
    };
  
    const handleEllipsisClick = (event, rowData) => {
      event.stopPropagation(); // Prevent event from bubbling up
      selectedRowData.current = rowData;
      if (menuRef.current) {
        menuRef.current.toggle(event);
      }
    };
    const actionBodyTemplate = (rowData) => {
      return (
        <div>
          <img
            src="../assets/menu-cirlce.svg"
            alt="menu-icon"
            style={{ cursor: "pointer" }}
            onClick={(e) => handleEllipsisClick(e, rowData)}
          />
          <TieredMenu model={generateMenuItems()} popup ref={menuRef} />
        </div>
      );
    };
  
    const generateMenuItems = () => {
      const menuItems = [];
      // if (permission[PERMISSION_TYPE.ADMIN]) {
        menuItems.push({
          label: "View Invoice",
          command: () => handleMenuItemClick("View Invoice"),
        });
        if (permission[PERMISSION_TYPE.ADMIN] || permission[PERMISSION_TYPE.STAFF_OPS]) {
        menuItems.push({
          label: "Approve Invoice",
          command: () => handleMenuItemClick("Approve Invoice"),
        });}
        menuItems.push({
          label: "Cases List",
          command: () => handleMenuItemClick("Cases List"),
        });
      
      return menuItems;
    };
  
   
    useEffect(() => {
      Promise.all([
     getInvoicedata()
    ])
    .then(() => {
      setTimeout(() => {
        setLoader(false);
      }, 250);
    })
    .catch((error) => {
      setLoader(false);
    });
  }, [lazyState]);
    
  
     const getInvoiceCasesData = async (id: any) => {
    
                             ApiGet(`invoiceDetails/get-invoice-cases-data?id=${id}`)
                                .then((res: any) => {
              let finalData = res.data.result.map((item: any, index: number) => {
                return {
                  SRNO: (lazyState.first) + index + 1,
                  id: item._id,              
                  BorrowerName: item.borrower_name ?? "-",
                  Loanamount: item.loan_amount ?? "-",
                  Financer: item.bank_name ?? "-",
                  Product: item?.product ?? "-",
                  dismonth:new Date(item?.disbursement_date).toISOString ?? "-",    
                  ...item          
                };
              });
              setInvoiceCases(finalData);
    
    
                            setViewCases(true)
                                   
                                }).catch((error: any) => {
                                    // toast.error(error?.error, {
                                    //     position: "top-right",
                                    //     theme: "colored"
                                    // })
                                })
        
                        
                    
                }
    
  const getInvoicedata = async () => {
          setLoader(true);
          const modifiedLazyState = { ...lazyState, limit: lazyState.rows };
          delete modifiedLazyState.rows;
          delete modifiedLazyState['multiSortMeta'];
          delete modifiedLazyState['sortField'];
          delete modifiedLazyState['sortOrder'];
  
          // Function to replace empty arrays in the "value" field with null
          const replaceEmptyValueArrays = (obj) => {
              for (const key in obj) {
                  if (key === 'value' && Array.isArray(obj[key]) && obj[key].length === 0) {
                      obj[key] = null;
                  } else if (typeof obj[key] === 'object') {
                      replaceEmptyValueArrays(obj[key]);
                  }
              }
              return obj;
          };
  
          // Call the function to replace empty arrays in the "value" field with null
          const finalModifiedLazyState = replaceEmptyValueArrays(modifiedLazyState);
          finalModifiedLazyState.page++;
          await ApiPost(`invoiceDetails/get-invoice-list?id=${userData._id}`, finalModifiedLazyState)
              .then((res: any) => {
                setTotalRecords(Number(res.data.count[0].count));
                  let temp = res.data.result.map((item: any, index: number) => {
                      return {
                          id: item._id,
                          SRNO: (lazyState.first) + index + 1,
                          created_at:new Date(item?.created_at).toISOString ?? "-",
                          ...item
                      }
                  })
                  setInvoiceDetail(temp)
                  
              }).catch((error) => {
                  setInvoiceDetail([])
              })
              setLoader(false)
      }
  
  
    return (
      <div className="overflow-x-hidden">
        {/* <Sidebar />
  <Header /> */}
        {loader ? (
          <>
            {/* START::OVERLAY */}
            <div className="fixed inset-0 w-full h-screen bg-white z-[9999] flex items-center justify-center">
              <img src="assets/logo.svg" />
            </div>
            {/* END::OVERLAY */}
          </>
        ) : (
          <>
            {/* <main className="lg:pl-[280px] pt-[92px]"> */}
            <div className="p-4 sm:p-9 space-y-5">
              {/* START::DASHBOARD HEADER */}
              <div className="flex flex-wrap gap-5 items-center justify-between space-y-5">
              <div className="md:flex w-full lg:w-[unset] items-center lg:justify-between md:space-x-9 space-y-5 md:space-y-0">
              <h1 className="text-[22px] text-light-gray font-semibold">Invoice Data</h1>
              </div>
               
                </div>

              <DataTable
                value={invoiceDetail}
                lazy
                dataKey="id"
                showGridlines
                paginator
                first={lazyState.first}
                filters={lazyState.filters}
                onFilter={onFilter}
                rows={lazyState.rows}
                rowsPerPageOptions={[10, 20, 30, 40, 50]}
                totalRecords={totalRecords}
                onPage={onPage}
                loading={loader}
                size={"small"}
                scrollable
                scrollHeight="600px"
                className="custom-datatable"
                header={header}
                emptyMessage="No records found."
                
              >
                <Column field="SRNO" header="SR.NO." />   
                <Column field="user_name" header="User Name" />
                <Column field="amount" header="Amount" />
                <Column field="status" header="Invoice status" />

                <Column field="userinvoicenumber" header="Dsa Invoice No." />
                <Column field="created_at" header="Generation Date" />
                <Column
                  field="Action"
                  header="Action"
                  body={actionBodyTemplate}
                />
              </DataTable> 
  
            </div>
            {/* </main> */}
  
            {/* Start::Add Disbursement Data Modal */}
           
         
         <div
                     className={`tw-modal ${veiwCases ? "flex" : "hidden"} `}
                     style={{
                         overflowX: 'auto'
                       }}
                   >
                     <div className="tw-modal-content"
                     style={{
                      maxWidth:'1865px',
                      minWidth:'900px',
                      height: "900px",
                         overflowX: 'auto'
                       }}
                      >
                       <div className="tw-modal-title "
                       style={{height:'50px',
                        maxWidth:'1865px',
                        minWidth:'900px',
                                               }}>
                         <div> Disbursement List</div>
                         <button
                           onClick={() => {
                             setViewCases(false);
                             setInvoiceCases([])
                           }}
                         >
                           <img src="assets/close-outline.svg" alt="" />
                         </button>
                       </div>
                       <div  style={{ display: 'flex', justifyContent: 'start', alignItems: 'center' ,height:'750px' }}>
                       
                      <div className="tw-modal-body w-full h-full p-10">
                         <div className=""
                          style={{
                            maxWidth:'1865px',
                            minWidth:'900px',
                           height:"700px"
                         }}>

                       <div className="gap-5 space-y-5 w-full h-full mt-5">
                       <DataTable
                      //  ref={dt}
                       value={invoiceCases}
                       lazy
                       dataKey="id"
                       loading={loader}
                       size={"small"}
                       scrollable
                       scrollHeight="600px"
                       className="custom-datatable"
                       emptyMessage="No records found."
                       height={'600px'}
                     >
                       <Column field="SRNO" header="SR.NO." />
                       
                       <Column field="BorrowerName" header="Borrower Name" />
                       <Column field="Loanamount" header="Loan Amount" />
                       <Column
                         field="Financer"
                         header="Financer"
                         
                       />
                       <Column
                         field="Product"
                         header="Product"
                         
                       />
                       
                       {/* ) : null} */}
                     </DataTable>
                       </div>
                       </div>
                     
        
           
                                  </div>
                   </div>
                   </div></div>
            <div
              className={`tw-modal ${viewInvoice ? "flex" : "hidden"} !items-start`}
            >
              <div className="tw-modal-content">
                <div className="tw-modal-title">
                  <div>Invoice</div>
                  <button
                    onClick={() => {
                      setViewInvoice(false)
                      setInvoicePath('')
                    }}
                  >
                    <img src="assets/close-outline.svg" alt="" />
                  </button>
                </div>
  
                <div className="tw-modal-body">                
                 
                         
                          <iframe
                            src={`data:application/pdf;base64,${invoicePath}`}
                            title="Preview PDF"
                            width="100%"
                            height="1000px"
                          />
                         
                </div>
  
               </div>
            </div>
  
            <ConfirmDialog
              // group="declarative"
              visible={approveInvoiceVisible}
              onHide={() => setApproveInvoiceVisible(false)}
              message="Are you sure you want to Approve Invoice?"
              header="Confirmation"
              icon="pi pi-exclamation-triangle"
              accept={approveInvoice}
              reject={rejectInvoice}
              style={{ width: "50vw" }}
              breakpoints={{ "1100px": "75vw", "960px": "100vw" }}
            />
            </>
            
  
  
        )}
</div>
    );
  };
  
  export default InvoiceAdminData;
  