import { Card } from "primereact/card";
import { TabPanel, TabView } from "primereact/tabview";
import { useEffect, useRef, useState } from "react";
import './DisbursementDetailsModal.css'
import { useLocation, useParams } from "react-router-dom";
import DatePicker from "react-datepicker";
import axios from "axios";
import { ApiGet, ApiPost, ApiPut } from "../../helper/API/ApiData";
import _ from "lodash";
import { Fieldset } from "primereact/fieldset";
import { InputSwitch } from "primereact/inputswitch";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { PERMISSION_TYPE, userPermission } from "../../helper/permission";
import { ConfirmDialog } from "primereact/confirmdialog";
import { Button } from "primereact/button";
import { Menu } from "primereact/menu";
const DisbursementDetailsModal = () => {
  const [loader, setLoader] = useState(false);
  const location = useLocation();
  const { data } = location.state || {}; // Access the data object
  const disbursementData = {

    dsa_name_error: null,
    BorrowerName_error: null,
    lan_no_error: null,
    Loanamount_error: null,
    pincode_error: null,
    Financer_error: null,
    product_error: null,
    // pan_no_error: null,
    contact_person_name_error: null,
    month_of_disbursement_error: null,
    mobile_error: null,
    connector_id: null,
    is_connector: false,
    Userpayout: 0,
    connector_rate: null,
    connector_error: null,
    adjustment_amount: 0,
    reason_error: null,
    state_error: null,
    city_error: null,

  }; const [formData, setFormData] = useState({ ...data, ...disbursementData, dismonth: new Date(data.dismonth) });
  const [BankMisData, setBankMisData] = useState(null);
  const [connectorList, setConnectorList] = useState([]);
  const [userList, setUserList] = useState([]);
  const [bankData, setBankData] = useState<any>({});
  const [isEdit, setIsEdit] = useState(false)
  const [isconnector, setConnector] = useState(false);
  const [permission, setPermission] = useState<any>({});
  const userData = useSelector(
    (state: RootState) => state?.authUser?.userData?.user
  );
    const [open, setOpen] = useState(false);
   const [item, setItem] = useState<any>({
      gross_payout: data.advance_gross,
      product: data.product,
      bankname:data.bank_name,
      payoutpercentage: data.rate,
    });
      const [approvePayment,setApprovePayment]=useState(false)
      const [viewProof,setViewProof]=useState(false)
      const [proofPath,setProofPath]=useState('')
      const [paymentForm,setPaymentForm]=useState<any>(data);
    const [sendMailDialog, setSendMailDialog] = useState(false);
      let email_data={
        sm_manager_email:'',
        asm_manager_email:'',
        sm_manager_email_error:null,
        asm_manager_email_error:null,
      }
      const [emailData,setEmailData]=useState<any>(email_data)
  const [isCollapsed, setIsCollapsed] = useState({
    personal_info: true,
    dsa_personal_info: true,
    connector_personal_info: true,
    dsa_calculation: true,
    connector_calculation: true,
    calculation: true,
    bank_details: true,
    orenda_details: true,
    gen_info: true
  }); // Initially closed
   useEffect(() => {
     
      const tempPermission = {
        [PERMISSION_TYPE.ADMIN]: userPermission(userData, PERMISSION_TYPE.ADMIN),
        [PERMISSION_TYPE.USER]: userPermission(userData, PERMISSION_TYPE.USER),
        [PERMISSION_TYPE.STAFF]: userPermission(userData, PERMISSION_TYPE.STAFF),
        [PERMISSION_TYPE.CONNECTOR]: userPermission(userData, PERMISSION_TYPE.CONNECTOR),
        [PERMISSION_TYPE.STAFF_OPS]: userPermission(userData, PERMISSION_TYPE.STAFF_OPS),
        [PERMISSION_TYPE.BOOKING_PARTNER]: userPermission(userData, PERMISSION_TYPE.BOOKING_PARTNER),
  
      };
      setPermission(tempPermission);
    }, [userData]);
  
  const menuLeft = useRef(null);  
   const generateMenuItems = () => {
      const menuItems = [];
     
      menuItems.push({
        label: "Advance Approval",
        command: () => handleMenuItemClick("Advance Approval"),
      });
      if (permission[PERMISSION_TYPE.ADMIN] || permission[PERMISSION_TYPE.STAFF_OPS]) {
        menuItems.push({
          label: "Delete",
          command: () => handleMenuItemClick("Delete"),
        });
      }
      if (permission[PERMISSION_TYPE.ADMIN] ) {
  
      menuItems.push({
        label: "Add Utr Details",
        command: () => handleMenuItemClick("Add Utr Details"),
      });}
  
      if (permission[PERMISSION_TYPE.ADMIN] || permission[PERMISSION_TYPE.STAFF] ||permission[PERMISSION_TYPE.STAFF_OPS] ) {
  
        menuItems.push({
          label: "Advance Proof",
          items: [
            {
              label: "Download Proof",
              command: () => handleMenuItemClick("Download Proof"),
            },
            {
              label: "View Proof",
              command: () => handleMenuItemClick("View Proof"),
            },
          ],
          command: () => handleMenuItemClick("Advance Proof"),
        });}
  
        if (permission[PERMISSION_TYPE.ADMIN] || permission[PERMISSION_TYPE.STAFF] || permission[PERMISSION_TYPE.STAFF_OPS] || permission[PERMISSION_TYPE.USER] ) {
  
          menuItems.push({
            
                label: "Send Disbursal Confirmation",
                command: () => handleMenuItemClick("Send Disbursal Confirmation"),                 
            
          });}
    
      return menuItems;
    };
        const handleMenuItemClick = (itemLabel) => {
          if (itemLabel === "Edit") {
            // handleEditClick(data);
            if (data.Paymentstatus === "Billing Received") {
              // setDisableField(true);
            }
            if (data.Paymentstatus === "Completed"){}
              // setDisableEdit(true);
          }
          if (itemLabel === "Advance Approval") {
            // setSelectedId(data.id);
            // setItem({
            //   ...item,
            //   product: selectedRowData?.current.Product,
            //   gross_payout: selectedRowData?.current.Advancegross,
            //   bankname: selectedRowData?.current.Financer,
            //   payoutpercentage: selectedRowData?.current.Userpayout,
            // });
            setOpen(true);
          }
          if (itemLabel === "Delete") {
            if (
              data.Paymentstatus === "Payment Pending" ||
              data.Paymentstatus === "Mismatch Case"
            ) {
              setDeleteConfirmVisible(true);
            } else {
              toast.error("Disbursement cannot be deleted", {
                position: "top-right",
                theme: "colored",
              });
            }
          }
          if (itemLabel === "Add Utr Details") {
           setApprovePayment(true)
          }
          if (itemLabel === "View Proof") {
            if(data.advance_proof_path != ''){
              setViewProof(true)
              getImageData(data.advance_proof_path)
            }
            else{
              setViewProof(false)
              toast.error("Doc not uploaded", {
                position: "top-right",
                theme: "colored",
              });
            }
            
           }
           if (itemLabel === "Download Proof") {
            if(data.advance_proof_path != ''){
              getDowloadProofLink(data?.advance_proof_path)
            }
            else{
              setViewProof(false)
              toast.error("Doc not uploaded", {
                position: "top-right",
                theme: "colored",
              });
            }
            
           }
      
           if(itemLabel == 'Send Disbursal Confirmation'){
      
            setSendMailDialog(true)
            // setSelectedId(data.id)
      
           }
        };
const [deleteConfirmVisible, setDeleteConfirmVisible] =
    useState<boolean>(false);
  const accept = () => {
    // handleDelete(data);
  };

  const reject = () => {
    setDeleteConfirmVisible(false);
  };


  useEffect(() => {

    const tempPermission = {
      [PERMISSION_TYPE.ADMIN]: userPermission(userData, PERMISSION_TYPE.ADMIN),
      [PERMISSION_TYPE.USER]: userPermission(userData, PERMISSION_TYPE.USER),
      [PERMISSION_TYPE.STAFF]: userPermission(userData, PERMISSION_TYPE.STAFF),
      [PERMISSION_TYPE.CONNECTOR]: userPermission(userData, PERMISSION_TYPE.CONNECTOR),
      [PERMISSION_TYPE.STAFF_OPS]: userPermission(userData, PERMISSION_TYPE.STAFF_OPS),
      [PERMISSION_TYPE.BOOKING_PARTNER]: userPermission(userData, PERMISSION_TYPE.BOOKING_PARTNER),

    };
    setPermission(tempPermission);
  }, [userData]);
  console.log(data)
  const handleNameInput = (e) => {
    e.target.value = e.target.value.replace(/[^A-Za-z\s]/g, "");
  };
  const handleBorrowerNameInput = (e) => {
    e.target.value = e.target.value.replace(/[^A-Za-z\s.]/g, '');
  };
  const handleNumericInput = (e) => {
    e.target.value = e.target.value.replace(/[^0-9]/g, "");
  };
  const handleAdjustmentAmountInput = (e) => {
    e.target.value = e.target.value.replace(/[^0-9+\-\.]/g, "");
  };
  // useEffect(() => {
  //     getUser(), getLoginCodeList(),getBankMisData()
   

  // });
  const convertToObjectArray = (obj) => {
    return Object.keys(obj).map((key) => ({
      label: obj[key]?.toString(),
      value: key,
    }));
  };

  const getLoginCodeList = async () => {
    await ApiGet(`loginCodeList/option-codelist`)
      .then((res: any) => {
        let temp = res.data.result.map((item: any, index: number) => {
          return {
            bank: item.bank_name,
            product: item.product,
            id: item._id,
          };
        });
        const bankData = _.groupBy(temp, function (b) {
          return b.bank;
        });
        setBankData(bankData);
      })
      .catch((error) => {
        setBankData({});
      });
  };

  const getBankMisData = () => {
    setLoader(true)
    ApiGet(`disbursement/get-BankMis?id=${data.id}`)
      .then((res: any) => {
        let finalData = res.data.result.map((item: any, index: number) => {
          return {
            borrower_name: item?.borrower_name,
            bank_name: item?.bank_name,
            product: item?.product,
            year: item?.year,
            month: item?.month,
            user_id: item?.user_id,
            dsadisbursementid: item?.dsadisbursementid,
          };
        });
        setBankMisData(finalData);
        setLoader(false)

      })
      .catch((error) => {
        setBankMisData([]);
        setLoader(false)

      });
  }

  const getConnector = (id) => {
    ApiGet(`connector/option-connector?id=${id}`)
      .then((res: any) => {
        let temp = res.data.result.map((item: any, index: number) => {
          return {
            id: item._id,
            label: `${item.first_name} ${item.middle_name || ''} ${item.last_name} - ${item.email}`,
          };
        });
        setConnectorList(temp);
      })
      .catch((error) => {
        setConnectorList([]);
      });
  };
  const getUser = () => {
    ApiGet(`user/option-users`)
      .then((res: any) => {
        const users = convertToObjectArray(res.data.users);

        setUserList(users);
      })
      .catch((error) => {
        setUserList([]);
      });
  };
  const editDisbursement = () => {
    let updatedForm = { ...formData };
    for (let key in updatedForm) {
      if (typeof updatedForm[key] == "string") {
        updatedForm[key] = updatedForm[key].trim();
      }
    }
    let namePattern = /^[A-Za-z\s]+$/;
    let mobilePattern = /^\+?[0-9]{10,}$/;
    if (formData.dsa_id == "") {
      updatedForm = {
        ...updatedForm,
        dsa_name_error: "Please select DSA name",
      };
    }
    if ((!permission[PERMISSION_TYPE.STAFF]) && (!permission[PERMISSION_TYPE.ADMIN]) && (!permission[PERMISSION_TYPE.STAFF_OPS])) {
      updatedForm = {
        ...updatedForm,
        dsa_name_error: null,
      };
    }
    else {
      if (formData.dsa_id == "") {
        updatedForm = {
          ...updatedForm,
          dsa_name_error: 'Please select a DSA',
        };
      }
    }
    if (formData.BorrowerName == "") {
      updatedForm = {
        ...updatedForm,
        BorrowerName_error: "Please enter a borrower name",
      };
    }
    if (formData.Loanamount == "") {
      updatedForm = {
        ...updatedForm,
        Loanamount_error: "Please enter a loan amount",
      };
    }
    // if (formData.borrower_pincode == "") {
    //   updatedForm = { ...updatedForm, pincode_error: "Please enter a pincode" };
    // }
    if (formData.borrower_pincode == "") {
      updatedForm = {
        ...updatedForm,
        pincode_error: "Please enter a Pincode",
      };
    }
    let pincodePattern = /^(\d{6})$/;
    if (
      formData.borrower_pincode != "" &&
      !pincodePattern.test(formData.borrower_pincode)
    ) {
      updatedForm = {
        ...updatedForm,
        pincode_error: "Please enter a valid pincode",
      };
    }
    console.log(formData)
    if ((formData.adjustment_amount === 0.00 || formData.adjustment_amount == "") && formData.reason == "") {
      updatedForm = { ...updatedForm, reason_error: null };
    }
    if ((formData.adjustment_amount != '' && formData.adjustment_amount != 0.00) && formData.reason == "") {
      updatedForm = { ...updatedForm, reason_error: "Please enter a reason" };
    }
    if (formData.adjustment_amount != '' && formData.reason != "") {
      updatedForm = { ...updatedForm, reason_error: null };
    }

    if (formData.Financer == "") {
      updatedForm = {
        ...updatedForm,
        Financer_error: "Please enter a financer name",
      };
    }
    if (formData.Financer == "" && formData.logincodelist_id == "") {
      updatedForm = { ...updatedForm, product_error: null };
    }
    if (formData.Financer != "" && formData.logincodelist_id == "") {
      updatedForm = { ...updatedForm, product_error: "Please select Product" };
    }
    if (formData.dismonth == null) {
      updatedForm = {
        ...updatedForm,
        month_of_disbursement_error: "Please enter a Month",
      };
    }
    if (formData.connector_id == null && isconnector) {
      updatedForm = {
        ...updatedForm,
        connector_error: "Please enter a connector",
      };
    }
    // if (formData.borrower_contact_person_number == "") {
    //   updatedForm = { ...updatedForm, mobile_error: "Please enter a mobile no." };
    // }
    if (
      formData.borrower_contact_person_number != "" &&
      !mobilePattern.test(formData.borrower_contact_person_number)
    ) {
      updatedForm = {
        ...updatedForm,
        mobile_error: "Please enter a valid mobile no.",
      };
    }
    setFormData(updatedForm);
    updatedForm = {
      ...updatedForm,
      adjustment_amount:
        updatedForm.adjustment_amount === ""
          ? "0.00"
          : updatedForm.adjustment_amount,
    };
    if (
      updatedForm.BorrowerName_error == null &&
      updatedForm.Loanamount_error == null &&
      updatedForm.pincode_error == null &&
      updatedForm.Financer_error == null &&
      updatedForm.product_error == null &&
      updatedForm.month_of_disbursement_error == null &&
      updatedForm.connector_error == null &&
      updatedForm.mobile_error == null &&
      updatedForm.reason_error == null
    ) {
      const {
        borrower_name_error,
        lan_no_error,
        loan_amount_error,
        pincode_error,
        financer_name_error,
        product_error,
        month_of_disbursement_error,
        connector_error,
        contact_person_name_error,
        mobile_error,
        reason_error,
        ...payload
      } = updatedForm;
      let finalData = payload;
      if (updatedForm.connector_id == "") {
        let { connector, ...payload } = updatedForm;
        finalData = payload;
      }
      // ApiPut(`disbursement/edit-disbursement`, finalData)
      //   .then((res: any) => {
      //     setFormData(disbursementData);
      //     if (res?.status == 200) {
      //       toast.success("Data updated successfully!!", {
      //         position: "top-right",
      //         theme: "colored",
      //       });
      //     }
      //   })
      //   .catch((error) => {});
    }

  };
  const postget = async (e: any) => {
    const value = e.target.value;
    setFormData({
      ...formData,
      borrower_pincode: e.target.value,
      pincode_error: null,
      state_error: null,
      city_error: null,
    });
    if (value.length >= 6) {
      const options = {
        method: "GET",
        url: `https://india-pincode-with-latitude-and-longitude.p.rapidapi.com/api/v1/pincode/${value}`,
        headers: {
          "X-RapidAPI-Key":
            "b2572becadmshbaf647a375178edp179823jsneae8fa2a8334",
          "X-RapidAPI-Host":
            "india-pincode-with-latitude-and-longitude.p.rapidapi.com",
        },
      };
      await axios.request(options).then((res: any) => {
        setFormData({
          ...formData,
          state: res?.data[0].state,
          city: res?.data[0].district,
          borrower_pincode: value,
        });
      });
    }
  };
  const getStatusColor = (status) => {
    switch (status) {
      case "Payment Pending":
        return "text-blue-500"; // Valid Tailwind class
      case "Mismatch Case":
        return "text-red-500"; // Valid Tailwind class
      case "Advance Completed":
        return "text-red-500"; // Valid Tailwind class
      case "Billing Received":
        return "text-blue-500"; // Updated to a valid Tailwind class
      case "Completed":
        return "text-[#4caf50]";
    }
  };

  const actionYes = async () => {
      await ApiPut(`disbursement/edit-disbursement?id=${data.id}`, {
        advance_request: true,
        is_visible_icon: true,
        cheker_type: "maker",
      })
        .then((res: any) => {
          setOpen(false);
          if(res){
            toast.success("Request sent successfully", {
            position: "top-right",
            theme: "colored"
          })
          }       
        })
        .catch((error: any) => {
          // toast.error("Something went wrong!", {
          //   position: "top-right",
          //   theme: "colored"
          // })
        });
    };

     const getImageData = async (path: any) => {
        if (path != undefined) {
          await ApiPost(`disbursement/get-proof-path`, {
            path,
          }).then((res: any) => {
              setProofPath(res.data.imageData);
          });
        } else {
          toast.error("Doc not uploaded", {
            position: "top-right",
            theme: "colored",
          });
        }
      };
      
      const sendMail= ()=>{
              let updatedform={...emailData}
              let emailPattern = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,20})+$/;
        
              if(updatedform.sm_manager_email == ''){
                updatedform = { ...updatedform, sm_manager_email_error: "Please enter a Email id" };
              }
              if (updatedform.sm_manager_email != '' && !emailPattern.test(updatedform.sm_manager_email)) {
                updatedform = { ...updatedform, sm_manager_email_error: "Please enter a valid email" };
            }
              // if(updatedform.asm_manager_email == ''){
              //   updatedform = { ...updatedform, asm_manager_email_error: "Please select Email id " };
              // }
              // if(updatedform.asm_manager_email != '' && !emailPattern.test(updatedform.asm_manager_email)){
              //   updatedform = { ...updatedform, asm_manager_email_error:"Please enter a valid email" };
              // }
              setEmailData(updatedform)
              let payload={
                sm_email:updatedform.sm_manager_email,
                asm_email:updatedform.asm_manager_email,
                case_id:data.id,
              }
              console.log(payload)
              if(updatedform.sm_manager_email_error == null ){
                ApiPost(`disbursement/send-mail`,payload).then((res: any) => {
                 if(res.status == 200){
                  toast.success(
                    res.data.message,
                    {
                      position: "top-right",
                      theme: "colored",
                    }
                  );
                 }
                 setSendMailDialog(false)
                  setEmailData(email_data)
                  
                }).catch((error)=>{
                  //  console.log(error)
                });
              }
              
            }
      const getDowloadProofLink = async (path: any) => {
          if (path != undefined) {
            await ApiPost(`disbursement/download-proof`, {
              path,
            }).then((res: any) => {
              const linkElement = document.createElement("a");
              linkElement.href = res.data.url;
              linkElement.click();
            }).catch((error)=>{
              //  console.log(error)
            });
          } else {
            toast.error("Doc not uploaded", {
              position: "top-right",
              theme: "colored",
            });
          }
        };

  return (
    <div className="overflow-x-hidden ">
      {loader ? (
        <>
          {/* START::OVERLAY */}
          <div className="fixed inset-0 w-full h-screen bg-white z-[9999] flex items-center justify-center">
            <img src="assets/logo.svg" />
          </div>
          {/* END::OVERLAY */}
        </>
      ) : (
        <>


          <div className="p-6 space-y-6 bg-gray-100 overflow-y-auto max-h-[950px]">
            <div className="border rounded-lg shadow-md bg-white w-full max-w-full">
              <div className="flex justify-end items-center gap-6 pt-5 pr-5">
                <div className="flex items-center gap-3">
                  <span className="text-lg font-medium">Edit</span>
                  <InputSwitch
                    className=""
                    checked={isEdit}
                    onChange={(e) => setIsEdit(e.value)
                      
                    }
                    disabled={data.Paymentstatus === 'Completed' || 'Advance Completed' || 'Billing Received' ? true : false}
                  />
                </div>
                <div>
                  <button className="bg-blue-500 hover:bg-blue-600 text-white py-2 px-6 rounded-lg shadow-md transition duration-200"
                    onClick={() => editDisbursement()}
                  >
                    Save
                  </button>
                </div>
                <Menu model={generateMenuItems()} popup ref={menuLeft} id="popup_menu_left" popupAlignment="right"/>
<Button label="Actions" icon="pi pi-align-left" className="bg-blue-500 hover:bg-blue-600 text-white py-2 px-6 rounded-lg shadow-md transition duration-200 mr-5" onClick={(event) => menuLeft.current.toggle(event)} aria-controls="popup_menu_left" aria-haspopup />

              </div>

              <div className="p-10 border-b border-gray-200">
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-2 p-10 bg-gray-50 border border-gray-300 rounded-lg shadow-md">
                  {/** DSA Selection */}

                  <div className="flex flex-col sm:flex-row items-center space-x-4">
                    <label
                      htmlFor="connector"
                      className={`text-lg font-semibold ${formData.dsa_name_error ? "text-red-500" : "text-gray-800"} w-1/3`}
                    >
                      DSA<span className="text-red-500">*</span>
                    </label>
                    <select
                      id="connector"
                      name="connector"
                      value={formData.dsa_id}
                      onChange={(e) => {
                        getConnector(e.target.value);
                        setFormData({ ...formData, dsa_id: e.target.value, dsa_name_error: null });
                      }}
                      className={`border rounded-2xl px-3 py-2 text-lg bg-white shadow-sm focus:outline-none focus:ring-2 sm:w-1/3 ${formData.dsa_name_error ? "border-red-500 focus:ring-red-500" : "border-gray-300 focus:ring-blue-500"
                        }`}
                      disabled={!isEdit}
                    >
                      <option value="">Select</option>
                      {userList.map((y: any) => (
                        <option key={y.value} value={y.value}>
                          {y.label}
                        </option>
                      ))}
                    </select>
                    {formData.dsa_name_error && (
                      <p className="text-red-500 text-sm ">{formData.dsa_name_error}</p>
                    )}
                  </div>

                  {/** Payment Status */}
                  <div className="flex flex-col sm:flex-row items-center space-x-7">
                    <h3 className={`text-lg font-semibold w-1/3 `}>Payment Status:</h3>
                    <span className={` text-lg font-medium w-full sm:w-2/3 font-bold  ${getStatusColor(data.Paymentstatus)}`}>{data.Paymentstatus}{data.co_status == 1 ? ' - Mismatch' : ''}</span>
                  </div>

                  {/** Borrower Name */}
                  <div className="flex flex-col sm:flex-row items-center space-x-4">
                    <label
                      htmlFor="borrower_name"
                      className={`text-lg font-semibold ${formData.BorrowerName_error ? "text-red-500" : "text-gray-800"} w-1/3`}
                    >
                      Borrower Name<span className="text-red-500">*</span>
                    </label>
                    <input
                      type="text"
                      id="borrower_name"
                      value={formData.BorrowerName}
                      onChange={(e) =>
                        setFormData({ ...formData, BorrowerName: e.target.value, BorrowerName_error: null })
                      }
                      placeholder="Enter Borrower Name"
                      className={`border rounded-2xl px-3 py-2 text-lg bg-white shadow-sm focus:outline-none focus:ring-2 sm:w-1/3 ${formData.BorrowerName_error ? "border-red-500 focus:ring-red-500" : "border-gray-300 focus:ring-blue-500"
                        }`}
                      disabled={!isEdit}
                    />
                    {formData.BorrowerName_error && (
                      <p className="text-red-500 text-sm ">{formData.BorrowerName_error}</p>
                    )}
                  </div>

                  {/** Month of Disbursement */}
                  <div className="flex flex-col sm:flex-row items-center space-x-4">
                    <label
                      htmlFor="month_of_disbursement"
                      className={`text-lg font-semibold ${formData.month_of_disbursement_error ? "text-red-500" : "text-gray-800"} w-1/3`}
                    >
                      Disbursement<span className="text-red-500">*</span>
                    </label>
                    <DatePicker
                      id="month_of_disbursement"
                      selected={formData.dismonth}
                      onChange={(date: any) =>
                        setFormData({ ...formData, dismonth: date, month_of_disbursement_error: null })
                      }
                      dateFormat="MM/yyyy"
                      showMonthYearPicker
                      className={`border rounded-2xl px-3 py-2 text-lg bg-white shadow-sm focus:outline-none focus:ring-2 sm:w-2/3 ${formData.month_of_disbursement_error ? "border-red-500 focus:ring-red-500" : "border-gray-300 focus:ring-blue-500"
                        }`}
                      disabled={!isEdit}
                    />
                    {formData.month_of_disbursement_error && (
                      <p className="text-red-500 text-sm ">{formData.month_of_disbursement_error}</p>
                    )}
                  </div>

                  {/** Financer */}
                  <div className="flex flex-col sm:flex-row items-center space-x-4">
                    <label
                      htmlFor="Financer"
                      className={`text-lg font-semibold ${formData.Financer_error ? "text-red-500" : "text-gray-800"} w-1/3`}
                    >
                      Financer<span className="text-red-500">*</span>
                    </label>
                    <select
                      id="Financer"
                      value={formData.Financer}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          Financer: e.target.value,
                          Financer_error: null,
                          product: "",
                        })
                      }
                      className={`border rounded-2xl px-3 py-2 text-lg bg-white shadow-sm focus:outline-none focus:ring-2 w-full sm:w-1/3 ${formData.Financer_error ? "border-red-500 focus:ring-red-500" : "border-gray-300 focus:ring-blue-500"
                        }`}
                      disabled={!isEdit}
                    >
                      <option value="">Select Bank/NBFC</option>
                      {Object.keys(bankData).map((bank, id) => (
                        <option key={id} value={bank}>
                          {bank}
                        </option>
                      ))}
                    </select>
                    {formData.Financer_error && (
                      <p className="text-red-500 text-sm ">{formData.Financer_error}</p>
                    )}
                  </div>

                  {/** Product */}
                  <div className="flex flex-col sm:flex-row items-center space-x-4">
                    <label
                      htmlFor="product"
                      className={`text-lg font-semibold ${formData.product_error ? "text-red-500" : "text-gray-800"} w-1/3`}
                    >
                      Product<span className="text-red-500">*</span>
                    </label>
                    <select
                      id="product"
                      value={formData.logincodelist_id}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          logincodelist_id: e.target.value,
                          product_error: null,
                        })
                      }
                      className={`border rounded-2xl px-3 py-2 text-lg bg-white shadow-sm focus:outline-none focus:ring-2 w-full sm:w-1/3 ${formData.product_error ? "border-red-500 focus:ring-red-500" : "border-gray-300 focus:ring-blue-500"
                        }`}
                      disabled={!isEdit}
                    >
                      <option value="">Select Product</option>
                      {bankData?.[formData.Financer]?.map((item: any) => (
                        <option key={item.id} value={item.id}>
                          {item.product}
                        </option>
                      ))}
                    </select>
                    {formData.product_error && (
                      <p className="text-red-500 text-sm ">{formData.product_error}</p>
                    )}
                  </div>

                  {/** Loan Amount */}
                  <div className="flex flex-col sm:flex-row items-center space-x-4">
                    <label
                      htmlFor="Loanamount"
                      className={`text-lg font-semibold ${formData.Loanamount_error ? "text-red-500" : "text-gray-800"} w-1/3`}
                    >
                      Loan Amount<span className="text-red-500">*</span>
                    </label>
                    <input
                      type="text"
                      id="Loanamount"
                      value={formData.Loanamount}
                      onChange={(e) =>
                        setFormData({ ...formData, Loanamount: e.target.value, Loanamount_error: null })
                      }
                      placeholder="Enter Loan Amount"
                      className={`border rounded-2xl px-3 py-2 text-lg bg-white shadow-sm focus:outline-none focus:ring-2 w-full sm:w-1/3 ${formData.Loanamount_error ? "border-red-500 focus:ring-red-500" : "border-gray-300 focus:ring-blue-500"
                        }`}
                      disabled={!isEdit}
                    />
                    {formData.Loanamount_error && (
                      <p className="text-red-500 text-sm ">{formData.Loanamount_error}</p>
                    )}
                  </div>


                </div>

                {/* <div className="flex justify-end">
        <button className="bg-blue-500 text-white py-2 px-4 rounded-lg shadow-md">
          save 
        </button>
      </div> */}
              </div>

              <div className="p-6">
                <TabView>
                  <TabPanel
                    header="Details"
                    rightIcon="pi pi-list ml-2"
                    headerClassName="border-b border-gray-200 text-lg"
                  >
                    <Fieldset legend=" General Info" toggleable collapsed={isCollapsed.gen_info}
                      onToggle={(e) => setIsCollapsed({ ...isCollapsed, gen_info: e.value })} className="bg-gray-50 border border-gray-300 rounded-lg p-10 shadow-md mb-5">

                      <div className="grid grid-cols-1 gap-6">
                        <div className="grid grid-cols-1 sm:grid-cols-2 gap-2 mb-3">
                          <div
                            className="flex items-center text-lg text-gray-700 px-1 py-1 bg-white border border-gray-200 rounded-sm"
                          >
                            <strong className="text-gray-900 mr-1">Advance Status:</strong>
                            <span className="text-gray-600">{data.Advancestatus}</span>
                          </div>
                          <div
                            className="flex items-center text-lg text-gray-700 px-1 py-1 bg-white border border-gray-200 rounded-sm"
                          >
                            <strong className="text-gray-900 mr-1">Advance Request:</strong>
                            <span className="text-gray-600">{data.Advancerequest}</span>
                          </div>
                        </div></div>
                    </Fieldset>
                    <Fieldset legend="Personal Info" toggleable collapsed={isCollapsed.personal_info}
                      onToggle={(e) => setIsCollapsed({ ...isCollapsed, personal_info: e.value })} className="bg-gray-50 border border-gray-300 rounded-lg p-10 shadow-md mb-5">

                      <Fieldset legend="Personal Info" toggleable collapsed={isCollapsed.dsa_personal_info}
                        onToggle={(e) => setIsCollapsed({ ...isCollapsed, dsa_personal_info: e.value })} className="bg-gray-50 border border-gray-300 rounded-lg p-10 shadow-md mb-5">
                        <div className="grid grid-cols-2 gap-6">


                          <div className="space-y-2">
                            <label
                              htmlFor="lan_no"
                              className="text-lg font-medium text-gray-900"
                            >
                              Lan No.
                            </label>
                            <div className="flex items-center px-3 py-2 border rounded-md border-gray-300">
                              <img src="assets/firm.svg" alt="Firm Icon" className="h-5 w-5 mr-3" />
                              <input
                                type="text"
                                name="lan_no"
                                value={formData.lan_no}
                                onChange={(e: any) => {
                                  setFormData({ ...formData, lan_no: e.target.value });
                                }}
                                className="w-full text-lg text-gray-800 bg-transparent focus:outline-none"
                                placeholder="Enter Lan No."
                                disabled={!isEdit}
                              />
                            </div>
                          </div>

                          {/* Contact Person Name */}
                          <div className="space-y-2">
                            <label
                              htmlFor="contact_person_name"
                              className="text-lg font-medium text-gray-900"
                            >
                              Contact Person Name
                            </label>
                            <div className="flex items-center px-3 py-2 border rounded-md border-gray-300">
                              <img src="assets/firm.svg" alt="Firm Icon" className="h-5 w-5 mr-3" />
                              <input
                                type="text"
                                name="contact_person_name"
                                onInput={handleNameInput}
                                value={formData.Contact_Person_Name}
                                onChange={(e: any) => {
                                  setFormData({
                                    ...formData,
                                    Contact_Person_Name: e.target.value,
                                  });
                                }}
                                className="w-full text-lg text-gray-800 bg-transparent focus:outline-none"
                                placeholder="Enter Contact Person Name"
                                disabled={!isEdit}
                              />
                            </div>
                          </div>
                          <div className="space-y-2">
                            <label
                              htmlFor="contact_person_name"
                              className={`text-lg font-medium ${formData.mobile_error ? "text-red-500" : "text-gray-900"
                                } `}
                            >
                              Mobile No.
                            </label>
                            <div className="flex items-center px-3 py-2 border rounded-md border-gray-300">
                              <img src="assets/firm.svg" alt="Firm Icon" className="h-5 w-5 mr-3" />
                              <input
                                type="text"
                                name="contact_person_name"
                                onInput={handleNameInput}
                                value={formData.Contact_Person_Name}
                                onChange={(e: any) => {
                                  setFormData({
                                    ...formData,
                                    borrower_contact_person_number: e.target.value,
                                    mobile_error: null,
                                  });
                                }}
                                className={`w-full text-lg  bg-transparent focus:outline-none${formData.mobile_error ? "text-red-500" : "text-gray-800"
                                  }`}
                                placeholder="Enter Mobile No."
                                disabled={!isEdit}
                              />
                            </div>
                            <p className="text-red-500 text-sm ">{formData.mobile_error}</p>

                          </div>


                        </div>
                        <div className="pt-4">
                          <div className="flex space-x-4">
                            <div className="flex-1">
                              <label
                                htmlFor="borrower_pincode"
                                className={` text-lg font-medium ${formData.pincode_error ? "text-red-500" : "text-gray-900"
                                  }`}
                              >
                                Borrower’s City Pincode<span className="text-red-500">*</span>
                              </label>
                              <div
                                id="borrower_pincode"
                                className={`flex items-center px-3 py-2 border rounded-md ${formData.pincode_error ? "border-red-500" : "border-gray-300"
                                  }`}
                              >
                                <img src="assets/location.svg" alt="location" className="w-4 h-4 mr-2" />
                                <input
                                  type="text"
                                  name="borrower_pincode"
                                  onInput={handleNumericInput}
                                  maxLength={6}
                                  value={formData.borrower_pincode}
                                  onChange={(e: any) => {
                                    postget(e);
                                  }}
                                  className="flex-1 text-lg outline-none text-gray-800"
                                  placeholder="Enter Borrower’s City Pincode"
                                  disabled={!isEdit}
                                />
                              </div>
                              {formData.pincode_error && (
                                <p className=" text-sm text-red-500">{formData.pincode_error}</p>
                              )}
                            </div>

                            <div className="flex-1">
                              <label
                                htmlFor="city"
                                className="block text-lg font-medium text-gray-900"
                              >
                                City
                              </label>
                              <div
                                id="city"
                                className="flex items-center px-3 py-2 border rounded-md border-gray-300"
                              >
                                <img src="assets/location.svg" alt="location" className="w-4 h-4 mr-2" />
                                <input
                                  type="text"
                                  name="city"
                                  value={formData.city}
                                  onChange={(e: any) => {
                                    setFormData({ ...formData, city: e.target.value });
                                  }}
                                  className="flex-1 text-lg outline-none"
                                  placeholder="Enter City"
                                  disabled
                                />
                              </div>
                            </div>

                            <div className="flex-1">
                              <label
                                htmlFor="state"
                                className="block text-lg font-medium text-gray-900"
                              >
                                State
                              </label>
                              <div
                                id="state"
                                className="flex items-center px-3 py-2 border rounded-md border-gray-300"
                              >
                                <img src="assets/location.svg" alt="location" className="w-4 h-4 mr-2" />
                                <input
                                  type="text"
                                  name="state"
                                  value={formData.state}
                                  onChange={(e: any) => {
                                    setFormData({ ...formData, state: e.target.value });
                                  }}
                                  className="flex-1 text-lg outline-none"
                                  placeholder="Enter State"
                                  disabled
                                />
                              </div>
                            </div>
                          </div>



                        </div>
                      </Fieldset>
                      <Fieldset legend="Connector Personal Info" toggleable collapsed={isCollapsed.connector_personal_info}
                        onToggle={(e) => {
                          setIsCollapsed({ ...isCollapsed, connector_personal_info: e.value })
                          getConnector(formData.dsa_id)
                        }} className="bg-gray-50 border border-gray-300 rounded-lg p-10 shadow-md mb-5">
                        <div className="grid grid-cols-2 gap-6">


                          <div className="space-y-2">
                            <label
                              htmlFor="lan_no"
                              className="text-lg font-medium text-gray-900"
                            >
                              Connector
                            </label>
                            <div className="flex items-center px-3 py-2 border rounded-md border-gray-300">
                              <img src="assets/firm.svg" alt="Firm Icon" className="h-5 w-5 mr-3" />
                              <select
                                name="connector"
                                value={formData.connector_id}
                                onChange={(e) =>
                                  setFormData({
                                    ...formData,
                                    connector_id: e.target.value,
                                    connector_error: null,
                                  })
                                }
                                disabled={!isEdit}
                                className="w-full text-lg h-7 text-gray-800 bg-transparent focus:outline-none"
                              >
                                <option value="">Select</option>
                                {connectorList.map((y: any) => (
                                  <option value={y.id}>{y.label}</option>
                                ))}
                              </select>
                            </div>
                          </div>

                          {/* Contact Person Name */}
                          <div className="space-y-2">
                            <label
                              htmlFor="contact_person_name"
                              className="text-lg font-medium text-gray-900"
                            >
                              Connector Rate
                            </label>
                            <div className="flex items-center px-3 py-2 border rounded-md border-gray-300">
                              <img src="assets/firm.svg" alt="Firm Icon" className="h-5 w-5 mr-3" />
                              <input
                                type="text"
                                name="state"
                                value={formData.connector_rate}
                                onChange={(e) =>
                                  setFormData({
                                    ...formData,
                                    connector_rate: e.target.value,
                                    connector_error: null,
                                  })
                                }
                                // max={formData.rate}
                                disabled={!isEdit}
                                className="w-full text-lg text-gray-800 bg-transparent focus:outline-none"
                              />
                            </div>
                          </div>

                        </div>
                      </Fieldset>
                    </Fieldset>
                    <Fieldset
                      legend="Calculations"
                      toggleable collapsed={isCollapsed.calculation}
                      onToggle={(e) => setIsCollapsed({ ...isCollapsed, calculation: e.value })}
                      className="bg-gray-50 border border-gray-300 rounded-lg p-10 shadow-md mb-3"
                    >
                      <Fieldset legend="Dsa" toggleable collapsed={isCollapsed.dsa_calculation}
                        onToggle={(e) => setIsCollapsed({ ...isCollapsed, dsa_calculation: e.value })} className="bg-gray-50 border border-gray-300 rounded-lg p-10 shadow-md mb-5">

                        <div className="grid grid-cols-1 gap-6">
                          <div className="grid grid-cols-1 sm:grid-cols-2 gap-2 mb-3">
                            {[
                              { label: "Net payout", value: data.Netpayout },
                              { label: "Gross payout", value: data.Grosspayout },
                              { label: "Advance Gross payout", value: data.Advancegross },
                              { label: "Tds payout", value: data.Tdspayout },
                              { label: "Grid Payout %", value: data.Userpayout },
                              { label: "Gst payout", value: data.Gstpayout },
                              { label: "Adjusted Payout %", value: data.adjusted_payout_per },
                              { label: "Subvention %", value: data.subvention_per },
                              { label: "System Adjustment", value: data.system_djustment },
                            ].map((item, index) => (
                              <div
                                key={index}
                                className="flex items-center text-lg text-gray-700 px-1 py-1 bg-white border border-gray-200 rounded-sm"
                              >
                                <strong className="text-gray-900 mr-1">{item.label}:</strong>
                                <span className="text-gray-600">{item.value}</span>
                              </div>
                            ))}


                          </div>


                        </div>
                        <div className="grid grid-cols-2 gap-6">
                          <div className="space-y-2">
                            <label
                              htmlFor="lan_no"
                              className="text-lg font-medium text-gray-900"
                            >
                              Adjustement Amount
                            </label>
                            <div className="flex items-center px-3 py-2 border rounded-md border-gray-300">
                              <img src="assets/firm.svg" alt="Firm Icon" className="h-5 w-5 mr-3" />
                              <input
                                type="text"
                                name="lan_no"
                                value={formData.adjustment_amount}
                                onChange={(e: any) => {
                                  setFormData({ ...formData, adjustment_amount: e.target.value });
                                }}
                                className="w-full text-lg text-gray-800 bg-transparent focus:outline-none"
                                placeholder="Enter Lan No."
                                disabled={!isEdit}
                              />
                            </div>
                          </div>
                          <div className="space-y-2">
                            <label
                              htmlFor="lan_no"
                              className={`text-lg font-medium ${formData.reason_error ? 'text-red-500' : 'text-gray-900 '}`}>
                              Reason
                            </label>
                            <div className={`flex items-center px-3 py-2 border rounded-md 
      ${formData.reason_error ? 'border-red-500' : 'border-gray-300'}`}>
                              <img src="assets/firm.svg" alt="Firm Icon" className="h-5 w-5 mr-3" />
                              <input
                                type="text"
                                name="lan_no"
                                value={formData.reason}
                                onChange={(e: any) => {
                                  setFormData({ ...formData, reason: e.target.value });
                                }}
                                className={`w-full text-lg  bg-transparent focus:outline-none ${formData.reason_error ? 'text-red-500' : 'text-gray-800'}`}
                                placeholder="Enter Reason"
                                disabled={!isEdit}
                              />
                            </div>
                            {formData.reason_error && (
                              <p className="text-red-500 text-sm ">{formData.reason_error}</p>
                            )}
                          </div>
                          <div className="space-y-2">
                            <label
                              htmlFor="lan_no"
                              className="text-lg font-medium text-gray-900"
                            >
                              Subvention Amount
                            </label>
                            <div className="flex items-center px-3 py-2 border rounded-md border-gray-300">
                              <img src="assets/firm.svg" alt="Firm Icon" className="h-5 w-5 mr-3" />
                              <input
                                type="text"
                                name="lan_no"
                                value={formData.subvention}
                                onChange={(e: any) => {
                                  setFormData({ ...formData, subvention: e.target.value });
                                }}
                                className="w-full text-lg text-gray-800 bg-transparent focus:outline-none"
                                placeholder="Enter Lan No."
                                disabled={!isEdit}
                              />
                            </div>
                          </div>
                        </div>
                      </Fieldset>

                      <Fieldset legend="Connector" toggleable collapsed={isCollapsed.connector_calculation}
                        onToggle={(e) => setIsCollapsed({ ...isCollapsed, connector_calculation: e.value })} className="bg-gray-50 border border-gray-300 rounded-lg p-10 shadow-md mb-5">

                        <div className="grid grid-cols-1 gap-6">
                          <div className="grid grid-cols-2  gap-2">
                            {[
                              { label: "Net payout", value: data.connector_net_amount_payable },
                              { label: "Gross payout", value: data.connector_gross_payout },
                              { label: "Tds payout", value: data.connector_tds_amount },
                              { label: "Payout %", value: data.connector_rate },
                              { label: "Gst payout", value: data.connector_gst_amount },
                            ].map((item, index) => (
                              <div
                                key={index}
                                className="flex items-center text-lg text-gray-700 px-1 py-1 bg-white border border-gray-200 rounded-sm"
                              >
                                <strong className="text-gray-900 mr-1">{item.label}:</strong>
                                <span className="text-gray-600">{item.value}</span>
                              </div>
                            ))}
                          </div>
                        </div></Fieldset>

                    </Fieldset>
                  </TabPanel>

                  <TabPanel
                    header="Bank Details"
                    rightIcon="pi pi-building-columns ml-2"
                    headerClassName="border-b border-gray-200 text-lg"

                  >
                    <Fieldset legend="Details" toggleable collapsed={isCollapsed.bank_details}
                      onToggle={(e) => {
                        setIsCollapsed({ ...isCollapsed, bank_details: e.value })
                      }} className="bg-gray-50 border border-gray-300 rounded-lg p-10 shadow-md mb-5">

                      <div className="bg-gray-50 border border-gray-300 rounded-lg p-10 shadow-md mb-5">
                        <div className="grid grid-cols-1 gap-6">
                          <div className="grid grid-cols-2 gap-1 mb-3">
                            {[
                              { label: "Net payout", value: data.Netpayout },
                              { label: "Gross payout", value: data.Grosspayout },
                              { label: "Advance Gross payout", value: data.Advancegross },
                              { label: "Tds payout", value: data.Tdspayout },
                              { label: "Grid Payout %", value: data.Userpayout },
                              { label: "Gst payout", value: data.Gstpayout },
                              { label: "Adjusted Payout %", value: data.adjusted_payout_per },
                              { label: "Subvention %", value: data.subvention_per },
                              { label: "System Adjustment", value: data.system_djustment },
                            ].map((item, index) => (
                              <div
                                key={index}
                                className="flex items-center text-lg text-gray-700 px-1 py-1 bg-white border border-gray-200 rounded-sm"
                              >
                                <strong className="text-gray-900 mr-1">{item.label}:</strong>
                                <span className="text-gray-600">{item.value}</span>
                              </div>
                            ))}


                          </div>


                        </div>
                      </div>
                    </Fieldset>
                  </TabPanel>

                  <TabPanel
                    header="Orenda Dis. Details"
                    rightIcon="pi pi-home ml-2"
                    headerClassName="border-b border-gray-200 text-lg"
                  >
                    <Fieldset legend="Details" toggleable collapsed={isCollapsed.orenda_details}
                      onToggle={(e) => {
                        setIsCollapsed({ ...isCollapsed, orenda_details: e.value })
                      }} className="bg-gray-50 border border-gray-300 rounded-lg p-10 shadow-md mb-5">
                      <div className="bg-gray-50 border border-gray-300 rounded-lg p-10 shadow-md mb-5">
                        <div className="grid grid-cols-1 gap-6">
                          <div className="grid grid-cols-2 gap-1 mb-3">
                            {[
                              { label: "Net payout", value: data.Netpayout },
                              { label: "Gross payout", value: data.Grosspayout },
                              { label: "Advance Gross payout", value: data.Advancegross },
                              { label: "Tds payout", value: data.Tdspayout },
                              { label: "Grid Payout %", value: data.Userpayout },
                              { label: "Gst payout", value: data.Gstpayout },
                              { label: "Adjusted Payout %", value: data.adjusted_payout_per },
                              { label: "Subvention %", value: data.subvention_per },
                              { label: "System Adjustment", value: data.system_djustment },
                            ].map((item, index) => (
                              <div
                                key={index}
                                className="flex items-center text-lg text-gray-700 px-1 py-1 bg-white border border-gray-200 rounded-sm"
                              >
                                <strong className="text-gray-900 mr-1">{item.label}:</strong>
                                <span className="text-gray-600">{item.value}</span>
                              </div>
                            ))}


                          </div>


                        </div>
                      </div>
                    </Fieldset>
                  </TabPanel>
                </TabView>
              </div>
            </div>

          </div>

          <div className={`tw-modal ${open ? "flex" : "hidden"} !items-center`}>
            <div className="tw-modal-content !max-w-[500px]">
              <div className="tw-modal-title py-6 justify-end">
                <button onClick={() => setOpen(false)}>
                  <img src="assets/close-outline.svg" alt="" />
                </button>
              </div>
              <div className="tw-modal-body leading-[24px] py-8">
                <span className="font-medium text-black">Please Note : </span>
                Advance payout grid for {item.bankname} & {item.product} is{" "}
                {item.payoutpercentage - 0.25}% .Your Gross Payout will be Rs.{" "}
                {item.gross_payout}. You cannot opt for NORMAL GRID after
                requesting advance. Please confirm?
              </div>

              <div className="tw-modal-footer">
                <button
                  className="tw-modal-footer-button bg-secondary bg-opacity-10 text-secondary"
                  onClick={() => setOpen(false)}
                >
                  No
                </button>
                <button
                  className="tw-modal-footer-button bg-secondary text-white"
                  onClick={(e) => {
                    actionYes();
                  }}
                >
                  Yes
                </button>
              </div>
            </div>
          </div>
          <div
                      className={`tw-modal ${approvePayment ? "flex" : "hidden"} !items-start`}
                    >
                      <div className="tw-modal-content">
                        <div className="tw-modal-title">
                          <div>Add Payment Data</div>
                          <button
                            onClick={() => {
                              setApprovePayment(false);
                              setPaymentForm(data)
                            }}
                          >
                            <img src="assets/close-outline.svg" alt="" />
                          </button>
                        </div>
          
                        <div className="tw-modal-body">
                          <div className="grid grid-cols-1 sm:grid-cols-2 gap-[22px]">
                          
                              <div className="space-y-1">
                                <label htmlFor="file_sequence" className={`input-label ${
                                  paymentForm.file_sequence_num_error ? "text-red-500" : ""
                                }`}>
                                  File Sequence No.<span style={{color:'red'}}>*</span>
                                </label>
                                <div
                                id="file_sequence"
                                className={`input-wrap ${
                                  paymentForm.file_sequence_num_error ? "border-red-500" : ""
                                }`}
                              >
                                <img src="assets/user.svg" alt="" />
                                <input
                                  type="text"
                                  name="file_sequence"
                                  value={paymentForm.file_sequence_num}
                                  onChange={(e: any) => {
                                    setPaymentForm({
                                      ...paymentForm,
                                      file_sequence_num: e.target.value,
                                      file_sequence_num_error: null,
                                    });
                                  }}
                                  className="w-full text-sm"
                                  placeholder="Enter File sequence no."
                                />
                              </div>
                                <p className="text-red-500 text-sm">
                                {paymentForm.file_sequence_num_error}
                              </p>
                              </div>
                              
                              <div className="space-y-1">
                                <label htmlFor="amount" className={`input-label ${
                                  paymentForm.amount_error ? "text-red-500" : ""
                                }`}>
                                  Amount<span style={{color:'red'}}>*</span>
                                </label>
                                <div
                                id="amount"
                                className={`input-wrap ${
                                  paymentForm.amount_error ? "border-red-500" : ""
                                }`}
                              >
                                <img src="assets/user.svg" alt="" />
                                <input
                                  type="text"
                                  name="amount"
                                  value={paymentForm.amount}
                                  onChange={(e: any) => {
                                    setPaymentForm({
                                      ...paymentForm,
                                      amount: e.target.value,
                                      amount_error: null,
                                    });
                                  }}
                                  className="w-full text-sm"
                                  placeholder="Enter Amount"
                                />
                              </div>
                                <p className="text-red-500 text-sm">
                                {paymentForm.amount_error}
                              </p>
                              </div>
                              
                              <div className="space-y-1">
                                <label htmlFor="pymt_date" className={`input-label ${
                                  paymentForm.pymt_date_error ? "text-red-500" : ""
                                }`}>
                                  Payment Date<span style={{color:'red'}}>*</span>
                                </label>
                                <div
                                id="pymt_date"
                                className={`input-wrap ${
                                  paymentForm.pymt_date_error ? "border-red-500" : ""
                                }`}
                              ><img src="assets/date.svg" alt="" />
                              <DatePicker
                                selected={paymentForm.pymt_date}
                                onChange={(e: any) => {
                                  setPaymentForm({
                                    ...paymentForm,
                                    pymt_date: e,
                                    pymt_date_error: null,
                                  });
                                }}
                                dateFormat="dd/MM/yyyy"                
                                
                              />                      
                              </div>
                                <p className="text-red-500 text-sm">
                                {paymentForm.pymt_date_error}
                              </p>
                              </div>
                              
                              <div className="space-y-1">
                                <label htmlFor="acct_debit_date" className={`input-label ${
                                  paymentForm.acct_debit_date_error ? "text-red-500" : ""
                                }`}>
                                  Account Debit Date<span style={{color:'red'}}>*</span>
                                </label>
                                <div
                                id="acct_debit_date"
                                className={`input-wrap ${
                                  paymentForm.acct_debit_date_error ? "border-red-500" : ""
                                }`}
                              >
                                <img src="assets/date.svg" alt="" />
                                <DatePicker
                                  selected={paymentForm.acct_debit_date}
                                  onChange={(e: any) => {
                                    setPaymentForm({
                                      ...paymentForm,
                                      acct_debit_date: e,
                                      acct_debit_date_error: null,
                                    });
                                  }}
                                  dateFormat="dd/MM/yyyy"
                                  
                                />
                              </div>
                                <p className="text-red-500 text-sm">
                                {paymentForm.acct_debit_date_error}
                              </p>
                              </div>
                              <div className="space-y-1">
                                <label htmlFor="customer_ref_no" className={`input-label ${
                                  paymentForm.customer_ref_no_error ? "text-red-500" : ""
                                }`}>
                                  Customer Reference No.<span style={{color:'red'}}>*</span>
                                </label>
                                <div
                                id="customer_ref_no"
                                className={`input-wrap ${
                                  paymentForm.customer_ref_no_error ? "border-red-500" : ""
                                }`}
                              >
                                <img src="assets/user.svg" alt="" />
                                <input
                                  type="text"
                                  name="customer_ref_no"
                                  value={paymentForm.customer_ref_no}
                                  onChange={(e: any) => {
                                    setPaymentForm({
                                      ...paymentForm,
                                      customer_ref_no: e.target.value,
                                      customer_ref_no_error: null,
                                    });
                                  }}
                                  className="w-full text-sm"
                                  placeholder="Enter Customer Ref. No."
                                />
                              </div>
                                <p className="text-red-500 text-sm">
                                {paymentForm.remark_error}
                              </p>
                              </div>
                              <div className="space-y-1">
                                <label htmlFor="utr_no" className={`input-label ${
                                  paymentForm.utr_no_error ? "text-red-500" : ""
                                }`}>
                                  UTR No.<span style={{color:'red'}}>*</span>
                                </label>
                                <div
                                id="utr_no"
                                className={`input-wrap ${
                                  paymentForm.utr_no_error ? "border-red-500" : ""
                                }`}
                              >
                                <img src="assets/user.svg" alt="" />
                                <input
                                  type="text"
                                  name="utr_no"
                                  value={paymentForm.utr_no}
                                  onChange={(e: any) => {
                                    setPaymentForm({
                                      ...paymentForm,
                                      utr_no: e.target.value,
                                      utr_no_error: null,
                                    });
                                  }}
                                  className="w-full text-sm"
                                  placeholder="Enter UTR No."
                                />
                              </div>
                                <p className="text-red-500 text-sm">
                                {paymentForm.utr_no_error}
                              </p>
                              </div>
                          </div>
                        </div>
          
                        <div className="tw-modal-footer">
                          <button
                            className="tw-modal-footer-button bg-secondary text-white"
                            onClick={()=>{
                              // addPaymentData();
                                setApprovePayment(false);
                                setPaymentForm(data)
                              
                            }}
                          >
                            Submit
                          </button>
                          <button
                            className="tw-modal-footer-button bg-secondary bg-opacity-10 text-secondary"
                            onClick={() => {
                              setApprovePayment(false);
                              setPaymentForm(data)
                            }}
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    </div>
                    <div
                      className={`tw-modal ${viewProof ? "flex" : "hidden"} !items-start`}
                    >
                      <div className="tw-modal-content">
                        <div className="tw-modal-title">
                          <div>Advance Request Proof</div>
                          <button
                            onClick={() => {
                              setViewProof(false)
                              setProofPath('')
                            }}
                          >
                            <img src="assets/close-outline.svg" alt="" />
                          </button>
                        </div>
          
                        <div className="tw-modal-body">
                          
                         {data?.advance_proof_path?.split(".").pop() == "pdf" ?
                                 
                                  <iframe
                                    src={`data:application/pdf;base64,${proofPath}`}
                                    title="Preview PDF"
                                    width="100%"
                                    height="1000px"
                                  />
                                 : 
                                  <img
                                    style={{ height: "auto", width: "auto" }}
                                    src={`data:image/jpeg;base64,${proofPath}`}
                                    className="ml-5 object-center object-cover"
                                  />          
          
                         }
                        </div>
          
                       </div>
                    </div>
          
                    <div className={`tw-modal ${sendMailDialog ? "flex" : "hidden"}`}>
                        <div className="tw-modal-content">
                          <div className="tw-modal-title">
                            <div>Send Mail for Disbursal confirmation</div>
                            <button onClick={() => {setSendMailDialog(false)
                              setEmailData(email_data)
                            }}>
                              <img src="assets/close-outline.svg" alt="" />
                            </button>
                          </div>
          
                          <div className="tw-modal-body py-6">
                            <div className="d-flex">
                              <div className="space-y-1">
                                <label
                                  htmlFor="normal_payout"
                                  className={`input-label ${emailData.sm_manager_email_error ? "text-red-500" : ""}`}
                                >SM Email ID<span style={{color:'red'}}>*</span></label>
                                <div
                                  id="normal_payout"
                                  className={`input-wrap ${emailData.sm_manager_email_error ? "border-red-500" : ""}`}
                                >
                                  <input
                                    type="text"
                                    name="normal_payout"
                                    value={emailData.sm_manager_email}
                                    className="w-full text-sm"
                                    placeholder="Enter Mail"
                                    onChange={(e:any)=>{
                                     setEmailData({...emailData,sm_manager_email:e.target.value,sm_manager_email_error:null})
                                    }}
                                  />
                                </div>
                                <p className="text-red-500 text-sm">
                                  {emailData.sm_manager_email_error}
                                </p>
                              </div>
                              <div className="space-y-1">
                                <label
                                  htmlFor="normal_payout"
                                  className={`input-label ${emailData.asm_manager_email_error ? "text-red-500" : ""}`}
                                >ASM / Reporting Manager Email ID(optional)</label>
                                <div
                                  id="normal_payout"
                                  className={`input-wrap ${emailData.asm_manager_email_error ? "border-red-500" : ""}`}
                                >
                                  <input
                                    type="text"
                                    name="normal_payout"
                                    value={emailData.asm_manager_email}
                                    className="w-full text-sm"
                                    placeholder="Enter Mail"
                                    onChange={(e:any) => {
                                      setEmailData({...emailData,asm_manager_email:e.target.value,asm_manager_email_error:null})}
                                    }
                                  />
                                </div>
                                <p className="text-red-500 text-sm">
                                  {emailData.asm_manager_email_error}
                                </p>
                              </div>
                              
                            </div>
                          </div>
                         
                          <div className="tw-modal-footer">
                          <Button
                            icon="pi pi-check"
                            raised
                            severity="info"
                            onClick={() => {
                              sendMail()
                            }}
                          >
                            <span style={{ marginLeft: "8px" }}> Submit</span>
                          </Button>
                          <Button
                            icon="pi pi-times"
                            raised
                            severity="danger"
                            onClick={() => {
                              setSendMailDialog(false)
                              setEmailData(email_data)
                            }}
                          >
                            {" "}
                            <span style={{ marginLeft: "8px" }}> Cancel</span>
                          </Button>
                        </div>
                          
                        </div>
                      </div>
                    <ConfirmDialog
                      // group="declarative"
                      visible={deleteConfirmVisible}
                      onHide={() => setDeleteConfirmVisible(false)}
                      message="Are you sure you want to delete?"
                      header="Confirmation"
                      icon="pi pi-exclamation-triangle"
                      accept={accept}
                      reject={reject}
                      style={{ width: "50vw" }}
                      breakpoints={{ "1100px": "75vw", "960px": "100vw" }}
                    />
        </>
      )}</div>)
}

export default DisbursementDetailsModal
